import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Filter, Volume2, Languages, Brain, Send, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../lib/auth';
import { useTranslation } from 'react-i18next';
import { translateText, detectLanguage } from '../lib/translate';

interface Translation {
  id: string;
  source_language: string;
  target_language: string;
  phrase: string;
  translation: string;
  context?: string;
  category: string;
  difficulty: 'beginner' | 'intermediate' | 'advanced';
  pronunciation?: {
    phonetic_spelling: string;
    audio_url?: string;
  };
}

interface Language {
  code: string;
  name: string;
  native_name: string;
  direction: 'ltr' | 'rtl';
}

export function LanguageLearning() {
  const [sourceLanguage, setSourceLanguage] = useState('auto');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loading, setLoading] = useState(true);
  const [translating, setTranslating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const [detectedLanguage, setDetectedLanguage] = useState<string | null>(null);
  
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    loadLanguages();
    if (user) {
      loadRecentSearches();
      loadFavorites();
    }
  }, [user]);

  async function loadLanguages() {
    try {
      const { data, error } = await supabase
        .from('languages')
        .select('*')
        .eq('is_active', true)
        .order('name');

      if (error) throw error;
      setLanguages(data || []);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleTranslate() {
    if (!inputText.trim() || !targetLanguage) return;

    try {
      setTranslating(true);
      setError(null);

      // First detect the source language if set to auto
      if (sourceLanguage === 'auto') {
        const detected = await detectLanguage(inputText);
        setDetectedLanguage(detected);
      }

      // Get translation
      const result = await translateText(
        inputText, 
        targetLanguage,
        sourceLanguage === 'auto' ? undefined : sourceLanguage
      );
      
      setTranslatedText(result.text);

      // Save to recent searches
      if (user) {
        const newSearches = [inputText, ...recentSearches.filter(s => s !== inputText)].slice(0, 10);
        setRecentSearches(newSearches);
        localStorage.setItem(`recent_searches_${user.id}`, JSON.stringify(newSearches));
      }

      // Load common phrases in the same languages
      await loadCommonPhrases(
        sourceLanguage === 'auto' ? result.detectedSourceLanguage || 'en' : sourceLanguage,
        targetLanguage
      );

    } catch (err: any) {
      setError(err.message);
    } finally {
      setTranslating(false);
    }
  }

  async function loadCommonPhrases(sourceLang: string, targetLang: string) {
    try {
      const { data, error } = await supabase
        .from('translations')
        .select(`
          *,
          pronunciation_guides (
            phonetic_spelling,
            audio_url
          )
        `)
        .eq('source_language', sourceLang)
        .eq('target_language', targetLang)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) throw error;
      setTranslations(data || []);
    } catch (err) {
      console.error('Error loading common phrases:', err);
    }
  }

  async function loadRecentSearches() {
    const saved = localStorage.getItem(`recent_searches_${user?.id}`);
    if (saved) {
      setRecentSearches(JSON.parse(saved));
    }
  }

  async function loadFavorites() {
    try {
      const { data, error } = await supabase
        .from('user_favorites')
        .select('translation_id')
        .eq('user_id', user?.id);

      if (error) throw error;
      setFavorites(new Set(data?.map(f => f.translation_id)));
    } catch (err) {
      console.error('Error loading favorites:', err);
    }
  }

  const playAudio = (url: string) => {
    const audio = new Audio(url);
    audio.play().catch(console.error);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleTranslate();
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center mb-6">
          <Languages className="w-8 h-8 text-blue-600 dark:text-blue-400 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Language Learning</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              From
            </label>
            <select
              value={sourceLanguage}
              onChange={(e) => setSourceLanguage(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="auto">Detect Language</option>
              {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name} ({lang.native_name})
                </option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              To
            </label>
            <select
              value={targetLanguage}
              onChange={(e) => setTargetLanguage(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="">Select language</option>
              {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name} ({lang.native_name})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Text to Translate
            </label>
            <textarea
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={handleKeyPress}
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter text to translate..."
            />
          </div>

          <button
            onClick={handleTranslate}
            disabled={!inputText.trim() || !targetLanguage || translating}
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
          >
            {translating ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                Translating...
              </>
            ) : (
              <>
                <Languages className="w-5 h-5 mr-2" />
                Translate
              </>
            )}
          </button>

          {error && (
            <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 text-red-600 dark:text-red-400 px-4 py-3 rounded-md">
              {error}
            </div>
          )}

          {detectedLanguage && sourceLanguage === 'auto' && (
            <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 text-blue-600 dark:text-blue-400 px-4 py-3 rounded-md">
              Detected language: {languages.find(l => l.code === detectedLanguage)?.name || detectedLanguage}
            </div>
          )}

          {translatedText && (
            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Translation</h3>
              <p className="text-gray-800 dark:text-gray-200">{translatedText}</p>
            </div>
          )}
        </div>

        {recentSearches.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4 flex items-center">
              <Search className="w-5 h-5 mr-2" />
              Recent Searches
            </h3>
            <div className="flex flex-wrap gap-2">
              {recentSearches.map((term) => (
                <button
                  key={term}
                  onClick={() => setInputText(term)}
                  className="px-3 py-1 text-sm bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-full hover:bg-gray-200 dark:hover:bg-gray-600"
                >
                  {term}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4 flex items-center">
          <Brain className="w-6 h-6 mr-2" />
          Common Phrases
        </h2>

        <div className="space-y-6">
          {translations.map((translation) => (
            <div
              key={translation.id}
              className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4"
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    {translation.category.charAt(0).toUpperCase() + translation.category.slice(1)}
                  </span>
                  <span className={`ml-2 px-2 py-1 text-xs font-medium rounded-full ${
                    translation.difficulty === 'beginner'
                      ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                      : translation.difficulty === 'intermediate'
                      ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400'
                      : 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400'
                  }`}>
                    {translation.difficulty.charAt(0).toUpperCase() + translation.difficulty.slice(1)}
                  </span>
                </div>
              </div>

              <div className="space-y-2">
                <p className="text-lg font-medium text-gray-900 dark:text-white">
                  {translation.phrase}
                </p>
                <p className="text-xl font-bold text-blue-600 dark:text-blue-400">
                  {translation.translation}
                </p>
              </div>

              {translation.pronunciation && (
                <div className="mt-2 flex items-center space-x-4">
                  <div className="text-sm text-gray-600 dark:text-gray-300">
                    {translation.pronunciation.phonetic_spelling}
                  </div>
                  {translation.pronunciation.audio_url && (
                    <button
                      onClick={() => playAudio(translation.pronunciation.audio_url!)}
                      className="p-2 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                      <Volume2 className="w-5 h-5" />
                    </button>
                  )}
                </div>
              )}

              {translation.context && (
                <div className="mt-2 text-sm text-gray-600 dark:text-gray-400 bg-gray-100 dark:bg-gray-600/50 rounded-md p-3">
                  {translation.context}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}