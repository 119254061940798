import { supabase } from './supabase';
import { isAdmin } from './admin';

export interface Recipe {
  id: string;
  title: string;
  description: string;
  ingredients: string[];
  instructions: string[];
  country: string;
  cuisine_type?: string;
  prep_time?: number;
  cook_time?: number;
  servings?: number;
  difficulty?: 'easy' | 'medium' | 'hard';
  media_urls?: string[];
  user_id: string;
  status: 'draft' | 'published' | 'archived';
  created_at: string;
  updated_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
}

// Initialize sample recipes
export async function initializeSampleRecipes() {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    // Check if user is admin
    const isAdminUser = await isAdmin(user.id);
    if (!isAdminUser) return;

    const { data: existingRecipes } = await supabase
      .from('recipes')
      .select('id')
      .limit(1);

    // Only initialize if no recipes exist
    if (!existingRecipes || existingRecipes.length === 0) {
      const sampleRecipes = [
        {
          title: 'Pizza Napoletana',
          description: 'A classic Neapolitan pizza with a thin, crispy crust, fresh tomato sauce, mozzarella, and basil.',
          ingredients: [
            '2 pizza dough balls (store-bought or homemade)',
            '1 cup crushed San Marzano tomatoes',
            '8 oz fresh mozzarella, sliced or torn',
            'Fresh basil leaves',
            '2 tbsp olive oil',
            'Salt to taste'
          ],
          instructions: [
            'Preheat oven to its highest setting (typically 500°F/260°C) with a pizza stone inside',
            'Stretch or roll out each dough ball to a thin round',
            'Spread tomato sauce evenly, sprinkle with salt, then distribute mozzarella slices',
            'Drizzle with olive oil and top with basil leaves',
            'Bake for 10–12 minutes until the crust is crisp and the cheese is bubbling'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 20,
          cook_time: 12,
          servings: 2,
          difficulty: 'medium',
          media_urls: ['https://www.grandecheese.com/wp-content/uploads/2021/01/Margherita-Pizza-deck-oven.jpg'],
          user_id: user.id,
          status: 'published'
        },
        {
          title: 'Spaghetti al Pomodoro',
          description: 'A simple yet flavorful pasta dish with a fresh tomato and basil sauce.',
          ingredients: [
            '12 oz spaghetti or your favorite pasta',
            '2 cups ripe tomatoes (blended or finely chopped)',
            '2 cloves garlic, minced',
            '¼ cup olive oil',
            'A handful of fresh basil, torn',
            'Salt and pepper to taste',
            'Optional: grated Parmigiano-Reggiano'
          ],
          instructions: [
            'Cook pasta according to package instructions until al dente',
            'Meanwhile, heat olive oil in a pan, add garlic and sauté until fragrant',
            'Stir in tomatoes, season with salt and pepper, and simmer for 10 minutes',
            'Toss in the cooked pasta and fresh basil. Serve with a sprinkle of cheese if desired'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 10,
          cook_time: 20,
          servings: 4,
          difficulty: 'easy',
          media_urls: ['https://www.cucchiaio.it/content/cucchiaio/it/ricette/2019/12/spaghetti-al-pomodoro/jcr:content/header-par/image-single.img10.jpg/1576681061599.jpg'],
          user_id: user.id,
          status: 'published'
        },
        {
          title: 'Risotto alla Milanese',
          description: 'A luxurious saffron-infused risotto with a rich, creamy consistency.',
          ingredients: [
            '1½ cups Arborio rice',
            '1 small onion, finely chopped',
            '1/2 cup dry white wine',
            '4 cups chicken or vegetable broth (kept warm)',
            'A generous pinch of saffron threads',
            '3 tbsp butter',
            '1/3 cup grated Parmigiano-Reggiano',
            'Salt to taste'
          ],
          instructions: [
            'In a large pan, melt 2 tablespoons of butter and sauté the onion until translucent',
            'Add the rice and toast for 2 minutes',
            'Pour in the wine and let it evaporate',
            'Add warm broth one ladle at a time, stirring frequently, until the rice is creamy and cooked (about 18–20 minutes)',
            'Stir in saffron dissolved in a little broth and the remaining butter',
            'Finish with Parmigiano-Reggiano and season with salt'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 10,
          cook_time: 25,
          servings: 4,
          difficulty: 'medium',
          media_urls: ['https://images.services.kitchenstories.io/2C_zyFcVkyTamAu2_9SzLbMC0YI=/3840x0/filters:quality(80)/images.kitchenstories.io/communityImages/4ef74f5d5bcffb4f8769e9dbe543570a_21814fd1-1893-45dd-9777-f0a0c30b15ef.jpg'],
          user_id: user.id,
          status: 'published'
        },
        {
          title: 'Classic Lasagna Bolognese',
          description: 'A hearty layered pasta dish with rich meat ragù, creamy béchamel, and melted cheese.',
          ingredients: [
            '1 lb ground beef or a mix of beef and pork',
            '1 onion, chopped',
            '2 carrots, finely diced',
            '2 celery stalks, diced',
            '1 cup tomato passata',
            '½ cup red wine (optional)',
            'Olive oil, salt, and pepper',
            '4 tbsp butter',
            '4 tbsp flour',
            '4 cups milk',
            'Nutmeg, salt, and pepper',
            'Lasagna noodles',
            'Grated Parmigiano-Reggiano'
          ],
          instructions: [
            'Prepare the ragù: Sauté onion, carrots, and celery in olive oil; add meat and brown. Stir in tomato passata and red wine; simmer for 45 minutes',
            'Make béchamel: Melt butter, whisk in flour; gradually add milk while stirring until thick and smooth. Season with nutmeg, salt, and pepper',
            'In a baking dish, layer ragù, noodles, béchamel, and Parmigiano-Reggiano. Repeat layers, finishing with béchamel and cheese on top',
            'Bake at 375°F (190°C) for 40–45 minutes until bubbly and golden'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 60,
          cook_time: 45,
          servings: 8,
          difficulty: 'hard',
          media_urls: ['https://www.foodnetwork.com/content/dam/images/food/fullset/2021/11/24/0/YK501_rocco-dispirito-ten-layer-lasagna-bolognese_s4x3.jpg'],
          user_id: user.id,
          status: 'published'
        },
        {
          title: 'Classic Tiramisu',
          description: 'A delicate, no-bake dessert layering coffee-soaked ladyfingers with mascarpone cream and dusted with cocoa.',
          ingredients: [
            '6 egg yolks',
            '3/4 cup sugar',
            '1 cup mascarpone cheese',
            '1 1/2 cups strong espresso (cooled)',
            '2–3 tbsp coffee liqueur (optional)',
            '24 ladyfinger cookies',
            'Unsweetened cocoa powder for dusting'
          ],
          instructions: [
            'Whisk egg yolks and sugar until thick and pale; fold in mascarpone until smooth',
            'Mix espresso with liqueur in a shallow dish',
            'Quickly dip each ladyfinger into the coffee mixture (do not soak) and layer them in a dish',
            'Spread half of the mascarpone mixture over the ladyfingers; repeat with another layer',
            'Cover and chill for at least 4 hours. Dust with cocoa powder before serving'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 30,
          cook_time: 0,
          servings: 8,
          difficulty: 'medium',
          media_urls: ['https://zhangcatherine.com/wp-content/uploads/2023/05/12001200-2.jpg'],
          user_id: user.id,
          status: 'published'
        },
        {
          title: 'Italian Gelato',
          description: 'A creamy, dense Italian ice cream with intense flavor and a smooth texture that sets it apart from regular ice cream.',
          ingredients: [
            '2 cups whole milk',
            '1 cup heavy cream',
            '¾ cup sugar',
            '4 egg yolks',
            '1 teaspoon vanilla extract'
          ],
          instructions: [
            'In a saucepan, heat the milk, cream, and half the sugar over medium heat until just below boiling',
            'In a separate bowl, whisk the egg yolks with the remaining sugar until pale and smooth',
            'Slowly pour a small amount of the hot milk mixture into the yolks while whisking to temper them, then gradually add the rest',
            'Return the mixture to the saucepan and cook over low heat, stirring constantly, until it thickens enough to coat the back of a spoon (do not boil)',
            'Remove from heat, stir in the vanilla extract, then strain and cool the mixture completely',
            'Churn in an ice cream maker according to manufacturer\'s instructions, then freeze until firm'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 30,
          cook_time: 20,
          servings: 6,
          difficulty: 'medium',
          media_urls: ['https://italianmart.ca/cdn/shop/files/gelato-gelato-sicilian-ice-cream-vanilla-165lt-905488_1024x1024@2x.png?v=1736060429'],
          user_id: user.id,
          status: 'published'
        },
        {
          title: 'Classic Italian Focaccia',
          description: 'A light, airy flatbread with a golden crust, generously drizzled with olive oil and often topped with rosemary and coarse salt.',
          ingredients: [
            '3 ½ cups all-purpose flour',
            '1 packet (2 ¼ tsp) active dry yeast',
            '1 teaspoon salt',
            '1 ¼ cups warm water',
            '¼ cup olive oil (plus extra for drizzling)',
            'Fresh rosemary sprigs',
            'Coarse sea salt'
          ],
          instructions: [
            'In a bowl, mix flour, yeast, and salt. Add warm water and olive oil; stir until a sticky dough forms',
            'Knead on a floured surface for about 5–7 minutes until smooth and elastic',
            'Place the dough in an oiled bowl, cover, and let it rise in a warm place for 1–1½ hours until doubled in size',
            'Press the dough into a greased baking pan, creating dimples with your fingers. Drizzle with olive oil, sprinkle rosemary and coarse salt on top',
            'Let rest for 20 minutes; then bake in a preheated 425°F (220°C) oven for 20–25 minutes until golden brown'
          ],
          country: 'Italy',
          cuisine_type: 'Italian',
          prep_time: 120,
          cook_time: 25,
          servings: 8,
          difficulty: 'medium',
          media_urls: ['https://img.taste.com.au/QJMyaW8z/taste/2020/10/november20_stuffed-focaccia-with-tomato-and-olive-taste-166164-1.jpg'],
          user_id: user.id,
          status: 'published'
        }
      ];

      const { error } = await supabase
        .from('recipes')
        .insert(sampleRecipes);

      if (error) {
        console.error('Error creating sample recipes:', error);
      }
    }
  } catch (error) {
    console.error('Error initializing recipes:', error);
  }
}

export async function getRecipes() {
  const { data: { user } } = await supabase.auth.getUser();
  
  let query = supabase
    .from('recipes')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      )
    `)
    .order('created_at', { ascending: false });

  // If user is not admin, only show published recipes
  if (!user || !(await isAdmin(user.id))) {
    query = query.eq('status', 'published');
  }

  const { data, error } = await query;

  if (error) throw error;
  return data || [];
}

export async function getRecipe(id: string) {
  const { data: { user } } = await supabase.auth.getUser();
  
  const { data, error } = await supabase
    .from('recipes')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      ),
      favorites:recipe_favorites(user_id)
    `)
    .eq('id', id)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      throw new Error('Recipe not found');
    }
    throw error;
  }

  return {
    ...data,
    has_favorited: data.favorites?.some((f: any) => f.user_id === user?.id) || false
  };
}

export async function createRecipe(recipe: Omit<Recipe, 'id' | 'user_id' | 'created_at' | 'updated_at' | 'status'>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Validate required fields
  if (!recipe.title?.trim()) throw new Error('Recipe title is required');
  if (!recipe.description?.trim()) throw new Error('Recipe description is required');
  if (!recipe.ingredients?.length) throw new Error('At least one ingredient is required');
  if (!recipe.instructions?.length) throw new Error('At least one instruction is required');
  if (!recipe.country?.trim()) throw new Error('Country is required');

  const { data, error } = await supabase
    .from('recipes')
    .insert([{
      ...recipe,
      user_id: user.id,
      ingredients: recipe.ingredients,
      instructions: recipe.instructions,
      media_urls: recipe.media_urls || [],
      status: 'published' // Set default status
    }])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function updateRecipe(id: string, updates: Partial<Recipe>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Check if user owns the recipe
  const { data: recipe } = await supabase
    .from('recipes')
    .select('user_id')
    .eq('id', id)
    .single();

  if (!recipe) throw new Error('Recipe not found');
  if (recipe.user_id !== user.id) throw new Error('Not authorized to update this recipe');

  const { data, error } = await supabase
    .from('recipes')
    .update(updates)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function deleteRecipe(id: string) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Check if user owns the recipe
  const { data: recipe } = await supabase
    .from('recipes')
    .select('user_id')
    .eq('id', id)
    .single();

  if (!recipe) throw new Error('Recipe not found');
  if (recipe.user_id !== user.id) throw new Error('Not authorized to delete this recipe');

  const { error } = await supabase
    .from('recipes')
    .delete()
    .eq('id', id);

  if (error) throw error;
}

export async function toggleFavorite(recipeId: string) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  const { data: existing } = await supabase
    .from('recipe_favorites')
    .select()
    .eq('recipe_id', recipeId)
    .eq('user_id', user.id)
    .single();

  if (existing) {
    const { error } = await supabase
      .from('recipe_favorites')
      .delete()
      .eq('recipe_id', recipeId)
      .eq('user_id', user.id);

    if (error) throw error;
    return false;
  } else {
    const { error } = await supabase
      .from('recipe_favorites')
      .insert([{ recipe_id: recipeId, user_id: user.id }]);

    if (error) throw error;
    return true;
  }
}

export async function getFavoriteRecipes() {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  const { data, error } = await supabase
    .from('recipes')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      )
    `)
    .in('id', (
      supabase
        .from('recipe_favorites')
        .select('recipe_id')
        .eq('user_id', user.id)
    ))
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

// Share recipe function
export async function shareRecipe(recipe: {
  title: string;
  description: string;
  ingredients: string[];
  instructions: string[];
  country: string;
  cuisine_type?: string;
  prep_time?: number;
  cook_time?: number;
  servings?: number;
  difficulty?: 'easy' | 'medium' | 'hard';
  media_urls?: string[];
}) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Validate required fields
  if (!recipe.title?.trim()) throw new Error('Recipe title is required');
  if (!recipe.description?.trim()) throw new Error('Recipe description is required');
  if (!recipe.ingredients?.length) throw new Error('At least one ingredient is required');
  if (!recipe.instructions?.length) throw new Error('At least one instruction is required');
  if (!recipe.country?.trim()) throw new Error('Country is required');

  // Create recipe
  const { data: newRecipe, error: recipeError } = await supabase
    .from('recipes')
    .insert([{
      ...recipe,
      user_id: user.id,
      status: 'published'
    }])
    .select()
    .single();

  if (recipeError) throw recipeError;

  // Create social post about the recipe
  const { error: postError } = await supabase
    .from('user_posts')
    .insert([{
      user_id: user.id,
      content: `I just shared a new recipe: ${recipe.title}`,
      type: 'recipe',
      reference_id: newRecipe.id,
      media_urls: recipe.media_urls
    }]);

  if (postError) throw postError;

  return newRecipe;
}