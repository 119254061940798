import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Video as Video360, AlertCircle } from 'lucide-react';
import { createVirtualTour, updateVirtualTour, getVirtualTour, type VirtualTour } from '../lib/virtualTours';
import { useAuth } from '../lib/auth';
import { MediaUpload } from '../components/MediaUpload';

const initialFormData: Omit<VirtualTour, 'id' | 'user_id' | 'created_at' | 'updated_at' | 'view_count'> = {
  title: '',
  description: '',
  location: '',
  country: '',
  type: 'landmark',
  preview_image_url: '',
  tour_url: '',
  is_360: false,
  is_guided: false,
  duration: 30,
  language: 'en',
  accessibility_features: [],
  status: 'draft',
  metadata: {
    tour_stops: []
  }
};

export function VirtualTourForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/signin');
      return;
    }

    if (id) {
      setIsEdit(true);
      loadTour();
    }
  }, [id, user]);

  async function loadTour() {
    try {
      const data = await getVirtualTour(id!);
      setFormData(data);
    } catch (err: any) {
      setError(err.message);
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      setError(null);

      if (isEdit) {
        await updateVirtualTour(id!, formData);
      } else {
        await createVirtualTour(formData);
      }

      navigate('/virtual-tours');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const addTourStop = () => {
    setFormData(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        tour_stops: [
          ...(prev.metadata.tour_stops || []),
          {
            title: '',
            description: '',
            position: { x: 0, y: 0, z: 0 }
          }
        ]
      }
    }));
  };

  const updateTourStop = (index: number, updates: Partial<typeof formData.metadata.tour_stops[0]>) => {
    setFormData(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        tour_stops: prev.metadata.tour_stops?.map((stop, i) =>
          i === index ? { ...stop, ...updates } : stop
        )
      }
    }));
  };

  const removeTourStop = (index: number) => {
    setFormData(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        tour_stops: prev.metadata.tour_stops?.filter((_, i) => i !== index)
      }
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-6">
          <Video360 className="w-8 h-8 text-blue-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900">
            {isEdit ? 'Edit Virtual Tour' : 'Create Virtual Tour'}
          </h1>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4 flex items-start">
            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 mr-3 flex-shrink-0" />
            <p className="text-red-600">{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              required
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              required
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Location
              </label>
              <input
                type="text"
                required
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <input
                type="text"
                required
                value={formData.country}
                onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type
              </label>
              <select
                required
                value={formData.type}
                onChange={(e) => setFormData({ ...formData, type: e.target.value as VirtualTour['type'] })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="landmark">Landmark</option>
                <option value="museum">Museum</option>
                <option value="cultural_site">Cultural Site</option>
                <option value="historical_place">Historical Place</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Language
              </label>
              <select
                required
                value={formData.language}
                onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="it">Italian</option>
                <option value="ja">Japanese</option>
                <option value="zh">Chinese</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Duration (minutes)
              </label>
              <input
                type="number"
                required
                min="1"
                value={formData.duration}
                onChange={(e) => setFormData({ ...formData, duration: parseInt(e.target.value) })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tour URL
              </label>
              <input
                type="url"
                required
                value={formData.tour_url}
                onChange={(e) => setFormData({ ...formData, tour_url: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          <div className="flex items-center space-x-6">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.is_360}
                onChange={(e) => setFormData({ ...formData, is_360: e.target.checked })}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-gray-700">360° Tour</span>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.is_guided}
                onChange={(e) => setFormData({ ...formData, is_guided: e.target.checked })}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-gray-700">Guided Tour</span>
            </label>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Preview Image
            </label>
            <MediaUpload
              mediaUrls={formData.preview_image_url ? [formData.preview_image_url] : []}
              onMediaChange={(urls) => setFormData({ ...formData, preview_image_url: urls[0] })}
              maxFiles={1}
              allowedTypes={['image']}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Accessibility Features
            </label>
            <div className="space-y-2">
              {['Audio descriptions', 'Closed captions', 'Screen reader support', 'Keyboard navigation'].map(feature => (
                <label key={feature} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.accessibility_features.includes(feature)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFormData({
                          ...formData,
                          accessibility_features: [...formData.accessibility_features, feature]
                        });
                      } else {
                        setFormData({
                          ...formData,
                          accessibility_features: formData.accessibility_features.filter(f => f !== feature)
                        });
                      }
                    }}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-gray-700">{feature}</span>
                </label>
              ))}
            </div>
          </div>

          {formData.is_guided && (
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Tour Stops
                </label>
                <button
                  type="button"
                  onClick={addTourStop}
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  Add Stop
                </button>
              </div>
              <div className="space-y-4">
                {formData.metadata.tour_stops?.map((stop, index) => (
                  <div key={index} className="border border-gray-200 rounded-md p-4">
                    <div className="flex justify-between mb-2">
                      <h4 className="text-sm font-medium text-gray-700">Stop {index + 1}</h4>
                      <button
                        type="button"
                        onClick={() => removeTourStop(index)}
                        className="text-sm text-red-600 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                    <div className="space-y-2">
                      <input
                        type="text"
                        placeholder="Stop Title"
                        value={stop.title}
                        onChange={(e) => updateTourStop(index, { title: e.target.value })}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                      <textarea
                        placeholder="Stop Description"
                        value={stop.description}
                        onChange={(e) => updateTourStop(index, { description: e.target.value })}
                        rows={2}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                      <div className="grid grid-cols-3 gap-2">
                        <input
                          type="number"
                          placeholder="X Position"
                          value={stop.position.x}
                          onChange={(e) => updateTourStop(index, { 
                            position: { ...stop.position, x: parseFloat(e.target.value) }
                          })}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                        <input
                          type="number"
                          placeholder="Y Position"
                          value={stop.position.y}
                          onChange={(e) => updateTourStop(index, { 
                            position: { ...stop.position, y: parseFloat(e.target.value) }
                          })}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                        <input
                          type="number"
                          placeholder="Z Position"
                          value={stop.position.z}
                          onChange={(e) => updateTourStop(index, { 
                            position: { ...stop.position, z: parseFloat(e.target.value) }
                          })}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value as VirtualTour['status'] })}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="draft">Draft</option>
              <option value="published">Published</option>
              <option value="archived">Archived</option>
            </select>
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={() => navigate('/virtual-tours')}
              className="px-6 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Saving...' : (isEdit ? 'Update Tour' : 'Create Tour')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}