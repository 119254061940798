import React, { useState, useRef } from 'react';
import { Upload, X, Image as ImageIcon, Video, AlertCircle } from 'lucide-react';
import { uploadVirtualTourMedia } from '../lib/cloudinary';

interface MediaUploadProps {
  mediaUrls: string[];
  onMediaChange: (urls: string[]) => void;
  maxFiles?: number;
  allowedTypes?: ('image' | 'video')[];
}

export function MediaUpload({ 
  mediaUrls = [], 
  onMediaChange,
  maxFiles = 10,
  allowedTypes = ['image', 'video']
}: MediaUploadProps) {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length === 0) return;

    // Check if adding these files would exceed the limit
    if (mediaUrls.length + files.length > maxFiles) {
      setError(`You can only upload up to ${maxFiles} files`);
      return;
    }

    setUploading(true);
    setError(null);

    try {
      const uploadedUrls = await Promise.all(
        files.map(async (file) => {
          // Validate file type
          const isImage = file.type.startsWith('image/');
          const isVideo = file.type.startsWith('video/');
          
          if (!isImage && !isVideo) {
            throw new Error('Invalid file type. Only images and videos are allowed.');
          }

          if (isImage && !allowedTypes.includes('image')) {
            throw new Error('Images are not allowed for this upload.');
          }

          if (isVideo && !allowedTypes.includes('video')) {
            throw new Error('Videos are not allowed for this upload.');
          }

          // Validate file size (10MB limit)
          if (file.size > 10 * 1024 * 1024) {
            throw new Error('File size must be less than 10MB');
          }

          return await uploadVirtualTourMedia(file);
        })
      );

      onMediaChange([...mediaUrls, ...uploadedUrls]);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const removeMedia = (index: number) => {
    const newUrls = [...mediaUrls];
    newUrls.splice(index, 1);
    onMediaChange(newUrls);
  };

  const getMediaType = (url: string) => {
    return url.match(/\.(mp4|webm|ogg)$/i) ? 'video' : 'image';
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="block text-sm font-medium text-gray-700">
          Media ({mediaUrls.length}/{maxFiles})
        </label>
        <button
          type="button"
          onClick={() => fileInputRef.current?.click()}
          disabled={uploading || mediaUrls.length >= maxFiles}
          className="flex items-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Upload className="w-4 h-4 mr-2" />
          Upload Media
        </button>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        multiple
        accept={[
          ...(allowedTypes.includes('image') ? ['.jpg', '.jpeg', '.png', '.gif', '.webp'] : []),
          ...(allowedTypes.includes('video') ? ['.mp4', '.webm', '.ogg'] : [])
        ].join(',')}
        onChange={handleFileSelect}
      />

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-md p-4 flex items-start">
          <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 mr-3 flex-shrink-0" />
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {uploading && (
        <div className="flex items-center justify-center p-4 bg-gray-50 rounded-md">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600 mr-2" />
          <span className="text-gray-600">Uploading...</span>
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {mediaUrls.map((url, index) => (
          <div key={index} className="relative group">
            {getMediaType(url) === 'video' ? (
              <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden">
                <video
                  src={url}
                  className="w-full h-full object-cover"
                  controls
                />
                <Video className="absolute top-2 left-2 w-5 h-5 text-white drop-shadow-lg" />
              </div>
            ) : (
              <div className="relative aspect-square bg-gray-100 rounded-lg overflow-hidden">
                <img
                  src={url}
                  alt={`Media ${index + 1}`}
                  className="w-full h-full object-cover"
                />
                <ImageIcon className="absolute top-2 left-2 w-5 h-5 text-white drop-shadow-lg" />
              </div>
            )}
            <button
              type="button"
              onClick={() => removeMedia(index)}
              className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}