import { useEffect } from 'react';
import { AppRouter } from './lib/routes';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Suspense } from 'react';
import { Loading } from './components/Loading';
import { useAuth } from './lib/hooks/useAuth';
import { initializeSampleRecipes } from './lib/recipes';
import { initializeSampleProducts } from './lib/marketplace';
import { initializeSampleEntries } from './lib/explore';

function App() {
  const { refreshUser } = useAuth();

  // Refresh user session and initialize data on app load
  useEffect(() => {
    const initializeApp = async () => {
      await refreshUser();
      await Promise.all([
        initializeSampleRecipes(),
        initializeSampleProducts(),
        initializeSampleEntries()
      ]);
    };

    initializeApp().catch(console.error);
  }, [refreshUser]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading fullScreen />}>
        <AppRouter />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;