import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { ShoppingBag, CreditCard, AlertCircle } from 'lucide-react';
import { useCart } from '../lib/store/cart';
import { supabase } from '../lib/supabase';
import { useAuth } from '../lib/auth';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export function Checkout() {
  const { items, total, clearCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  if (!user) {
    navigate('/signin', { state: { returnTo: '/checkout' } });
    return null;
  }

  if (items.length === 0) {
    navigate('/marketplace');
    return null;
  }

  const handleCheckout = async () => {
    try {
      setLoading(true);
      setError(null);

      // Create order in database
      const { data: order, error: orderError } = await supabase
        .from('orders')
        .insert([
          {
            buyer_id: user.id,
            seller_id: items[0].seller_id, // For simplicity, using first item's seller
            status: 'pending',
            total_amount: total,
            metadata: {
              items: items.map(item => ({
                id: item.id,
                quantity: item.quantity,
                price: item.price
              }))
            }
          }
        ])
        .select()
        .single();

      if (orderError) throw orderError;

      // Create Stripe checkout session
      const { data: session, error: sessionError } = await supabase
        .functions.invoke('create-checkout-session', {
          body: {
            orderId: order.id,
            items: items.map(item => ({
              price_data: {
                currency: 'usd',
                product_data: {
                  name: item.title,
                  images: [item.image]
                },
                unit_amount: Math.round(item.price * 100)
              },
              quantity: item.quantity
            }))
          }
        });

      if (sessionError) throw sessionError;

      // Redirect to Stripe checkout
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to load');

      const result = await stripe.redirectToCheckout({
        sessionId: session.id
      });

      if (result.error) throw result.error;

      // Clear cart after successful redirect
      clearCart();

    } catch (err: any) {
      console.error('Checkout error:', err);
      setError(err.message || 'An error occurred during checkout');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-6">
          <ShoppingBag className="w-8 h-8 text-blue-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900">Checkout</h1>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4 flex items-start">
            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 mr-3 flex-shrink-0" />
            <p className="text-red-600">{error}</p>
          </div>
        )}

        <div className="space-y-4 mb-6">
          {items.map((item) => (
            <div key={item.id} className="flex items-center py-4 border-b">
              <img
                src={item.image}
                alt={item.title}
                className="w-16 h-16 object-cover rounded"
              />
              <div className="ml-4 flex-1">
                <h3 className="font-medium text-gray-900">{item.title}</h3>
                <p className="text-gray-600">
                  ${item.price.toFixed(2)} x {item.quantity}
                </p>
              </div>
              <div className="text-right">
                <p className="font-medium text-gray-900">
                  ${(item.price * item.quantity).toFixed(2)}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="border-t pt-4">
          <div className="flex justify-between text-lg font-semibold mb-6">
            <span>Total</span>
            <span>${total.toFixed(2)}</span>
          </div>

          <button
            onClick={handleCheckout}
            disabled={loading}
            className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                Processing...
              </>
            ) : (
              <>
                <CreditCard className="w-5 h-5 mr-2" />
                Pay with Stripe
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}