import { supabase } from './supabase';
import { uploadVirtualTourMedia } from './cloudinary';

export interface VirtualTour {
  id: string;
  title: string;
  description: string;
  location: string;
  country: string;
  type: 'landmark' | 'museum' | 'cultural_site' | 'historical_place';
  preview_image_url: string;
  tour_url: string;
  is_360: boolean;
  is_guided: boolean;
  duration: number;
  language: string;
  accessibility_features: string[];
  user_id: string;
  status: 'draft' | 'published' | 'archived';
  view_count: number;
  metadata: {
    camera_type?: string;
    tour_stops?: {
      title: string;
      description: string;
      position: { x: number; y: number; z: number };
    }[];
    recommended_devices?: string[];
    min_bandwidth?: number;
  };
  created_at: string;
  updated_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
}

export async function getVirtualTours() {
  const { data: { user } } = await supabase.auth.getUser();
  
  let query = supabase
    .from('virtual_tours')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      )
    `)
    .order('created_at', { ascending: false });

  // If user is not admin, only show published tours
  if (!user || !(await isAdmin(user.id))) {
    query = query.eq('status', 'published');
  }

  const { data, error } = await query;

  if (error) throw error;
  return data || [];
}

export async function getVirtualTour(id: string) {
  const { data: { user } } = await supabase.auth.getUser();
  
  const { data, error } = await supabase
    .from('virtual_tours')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      )
    `)
    .eq('id', id)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      throw new Error('Virtual tour not found');
    }
    throw error;
  }

  // Increment view count
  if (data.status === 'published') {
    await supabase
      .from('virtual_tours')
      .update({ view_count: (data.view_count || 0) + 1 })
      .eq('id', id);
  }

  // Check if user has permission to view
  if (data.status !== 'published' && (!user || !(await isAdmin(user.id)) && user.id !== data.user_id)) {
    throw new Error('You do not have permission to view this virtual tour');
  }

  return data;
}

export async function createVirtualTour(tour: Omit<VirtualTour, 'id' | 'user_id' | 'created_at' | 'updated_at' | 'view_count'>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Upload preview image to Cloudinary if it's a File
  let preview_image_url = tour.preview_image_url;
  if (tour.preview_image_url instanceof File) {
    preview_image_url = await uploadVirtualTourMedia(tour.preview_image_url);
  }

  const isAdminUser = await isAdmin(user.id);
  const status = isAdminUser ? 'published' : 'draft';

  const { data, error } = await supabase
    .from('virtual_tours')
    .insert([{
      ...tour,
      preview_image_url,
      user_id: user.id,
      status,
      view_count: 0
    }])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function updateVirtualTour(id: string, updates: Partial<VirtualTour>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Check if user is admin or tour owner
  const { data: tour } = await supabase
    .from('virtual_tours')
    .select('user_id')
    .eq('id', id)
    .single();

  if (!tour) throw new Error('Virtual tour not found');

  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser && tour.user_id !== user.id) {
    throw new Error('Not authorized to update this virtual tour');
  }

  // Upload new preview image if provided
  let preview_image_url = updates.preview_image_url;
  if (updates.preview_image_url instanceof File) {
    preview_image_url = await uploadVirtualTourMedia(updates.preview_image_url);
  }

  const { data, error } = await supabase
    .from('virtual_tours')
    .update({ ...updates, preview_image_url })
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function deleteVirtualTour(id: string) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Check if user is admin or tour owner
  const { data: tour } = await supabase
    .from('virtual_tours')
    .select('user_id')
    .eq('id', id)
    .single();

  if (!tour) throw new Error('Virtual tour not found');

  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser && tour.user_id !== user.id) {
    throw new Error('Not authorized to delete this virtual tour');
  }

  const { error } = await supabase
    .from('virtual_tours')
    .delete()
    .eq('id', id);

  if (error) throw error;
}

// Helper function to check if user is admin
async function isAdmin(userId: string): Promise<boolean> {
  const { data: profile } = await supabase
    .from('profiles')
    .select('role')
    .eq('id', userId)
    .single();

  return profile?.role === 'admin';
}