import { supabase } from './supabase';
import { captureError } from './monitoring';
import { trackEvent } from './analytics';

// Check if user is admin
export async function isAdmin(userId: string): Promise<boolean> {
  try {
    const { data: profile } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', userId)
      .single();

    return profile?.role === 'admin';
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

// Admin-only function to update user roles
export async function updateUserRole(userId: string, newRole: 'admin' | 'moderator' | 'member') {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Verify current user is admin
  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser) {
    throw new Error('Only administrators can update user roles');
  }

  const { error } = await supabase
    .from('profiles')
    .update({ role: newRole })
    .eq('id', userId);

  if (error) throw error;

  trackEvent('user_role_updated', {
    admin_id: user.id,
    target_user_id: userId,
    new_role: newRole
  });
}

// Admin-only function to verify users
export async function verifyUser(userId: string) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Verify current user is admin
  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser) {
    throw new Error('Only administrators can verify users');
  }

  const { error } = await supabase
    .from('profiles')
    .update({ 
      is_verified: true,
      updated_at: new Date().toISOString()
    })
    .eq('id', userId);

  if (error) throw error;

  // Award verification badge
  try {
    await supabase
      .from('user_badges')
      .insert({
        user_id: userId,
        badge_id: (
          await supabase
            .from('badges')
            .select('id')
            .eq('name', 'Verified User')
            .single()
        ).data?.id,
        metadata: {
          verified_by: user.id,
          verified_at: new Date().toISOString()
        }
      });
  } catch (error) {
    console.error('Error awarding verification badge:', error);
    captureError(error as Error);
  }

  trackEvent('user_verified', {
    admin_id: user.id,
    user_id: userId
  });
}

// Admin-only function to manage content
export async function manageContent(
  contentType: 'recipe' | 'cultural_point' | 'virtual_tour' | 'post',
  contentId: string,
  action: 'approve' | 'reject' | 'feature' | 'archive',
  reason?: string
) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Verify current user is admin
  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser) {
    throw new Error('Only administrators can manage content');
  }

  // Get table name based on content type
  const tableName = {
    recipe: 'recipes',
    cultural_point: 'cultural_points',
    virtual_tour: 'virtual_tours',
    post: 'user_posts'
  }[contentType];

  // Prepare update data based on action
  const updateData: Record<string, any> = {
    updated_at: new Date().toISOString()
  };

  switch (action) {
    case 'approve':
      updateData.status = 'published';
      updateData.approved_at = new Date().toISOString();
      updateData.approved_by = user.id;
      break;
    case 'reject':
      updateData.status = 'rejected';
      updateData.rejected_at = new Date().toISOString();
      updateData.rejected_by = user.id;
      updateData.rejection_reason = reason;
      break;
    case 'feature':
      updateData.is_featured = true;
      updateData.featured_at = new Date().toISOString();
      updateData.featured_by = user.id;
      break;
    case 'archive':
      updateData.status = 'archived';
      updateData.archived_at = new Date().toISOString();
      updateData.archived_by = user.id;
      updateData.archive_reason = reason;
      break;
  }

  const { error } = await supabase
    .from(tableName)
    .update(updateData)
    .eq('id', contentId);

  if (error) throw error;

  trackEvent('content_moderated', {
    admin_id: user.id,
    content_type: contentType,
    content_id: contentId,
    action,
    reason
  });
}

// Admin-only function to get system stats
export async function getSystemStats() {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Verify current user is admin
  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser) {
    throw new Error('Only administrators can view system stats');
  }

  // Get various stats in parallel
  const [
    userStats,
    contentStats,
    engagementStats
  ] = await Promise.all([
    // User statistics
    supabase.rpc('get_user_stats'),
    // Content statistics
    supabase.rpc('get_content_stats'),
    // Engagement statistics
    supabase.rpc('get_engagement_stats')
  ]);

  return {
    users: userStats.data,
    content: contentStats.data,
    engagement: engagementStats.data
  };
}

// Admin-only function to manage reported content
export async function handleReport(
  reportId: string,
  action: 'dismiss' | 'remove' | 'warn' | 'ban',
  reason: string
) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Verify current user is admin
  const isAdminUser = await isAdmin(user.id);
  if (!isAdminUser) {
    throw new Error('Only administrators can handle reports');
  }

  // Get report details
  const { data: report } = await supabase
    .from('content_reports')
    .select('*')
    .eq('id', reportId)
    .single();

  if (!report) throw new Error('Report not found');

  // Handle the report based on action
  switch (action) {
    case 'dismiss':
      await supabase
        .from('content_reports')
        .update({
          status: 'dismissed',
          handled_by: user.id,
          handled_at: new Date().toISOString(),
          resolution_notes: reason
        })
        .eq('id', reportId);
      break;

    case 'remove':
      // Remove the reported content
      await supabase
        .from(report.content_type)
        .delete()
        .eq('id', report.content_id);

      // Update report status
      await supabase
        .from('content_reports')
        .update({
          status: 'resolved',
          handled_by: user.id,
          handled_at: new Date().toISOString(),
          resolution_notes: reason
        })
        .eq('id', reportId);
      break;

    case 'warn':
      // Send warning to user
      await supabase
        .from('user_warnings')
        .insert({
          user_id: report.reported_user_id,
          warning_type: 'content',
          content_id: report.content_id,
          content_type: report.content_type,
          reason: reason,
          issued_by: user.id
        });

      // Update report status
      await supabase
        .from('content_reports')
        .update({
          status: 'resolved',
          handled_by: user.id,
          handled_at: new Date().toISOString(),
          resolution_notes: reason
        })
        .eq('id', reportId);
      break;

    case 'ban':
      // Ban the user
      await supabase
        .from('profiles')
        .update({
          is_banned: true,
          banned_at: new Date().toISOString(),
          banned_by: user.id,
          ban_reason: reason
        })
        .eq('id', report.reported_user_id);

      // Update report status
      await supabase
        .from('content_reports')
        .update({
          status: 'resolved',
          handled_by: user.id,
          handled_at: new Date().toISOString(),
          resolution_notes: reason
        })
        .eq('id', reportId);
      break;
  }

  trackEvent('report_handled', {
    admin_id: user.id,
    report_id: reportId,
    action,
    reason
  });
}