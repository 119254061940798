import { Globe2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'ja', name: '日本語' },
  { code: 'zh', name: '中文' },
  { code: 'ar', name: 'العربية' },
  { code: 'ko', name: '한국어' },
  { code: 'ru', name: 'Русский' },
  { code: 'pt', name: 'Português' },
  { code: 'hi', name: 'हिन्दी' }
];

export function LanguageSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div className="relative group">
      <button className="flex items-center space-x-1 px-3 py-2 text-gray-700 hover:text-blue-600">
        <Globe2 className="w-5 h-5" />
        <span className="text-sm font-medium">{
          languages.find(lang => lang.code === i18n.language)?.name || 'English'
        }</span>
      </button>
      
      <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 hidden group-hover:block">
        {languages.map(({ code, name }) => (
          <button
            key={code}
            onClick={() => i18n.changeLanguage(code)}
            className={`w-full text-left px-4 py-2 text-sm ${
              i18n.language === code
                ? 'text-blue-600 bg-blue-50'
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
}