import { supabase } from './supabase';

export interface Product {
  id: string;
  seller_id: string;
  title: string;
  description: string;
  category: 'recipe_book' | 'artwork' | 'crafts' | 'clothing' | 'accessories' | 'food' | 'other';
  price: number;
  images: string[];
  stock_quantity: number;
  is_digital: boolean;
  digital_file_url?: string;
  digital_file_type?: 'pdf' | 'image';
  status: 'draft' | 'published' | 'archived';
  metadata: Record<string, any>;
  created_at: string;
  updated_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
}

// Initialize sample products
export async function initializeSampleProducts() {
  try {
    const { data: existingProducts } = await supabase
      .from('products')
      .select('id')
      .limit(1);

    // Only initialize if no products exist
    if (!existingProducts || existingProducts.length === 0) {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const sampleProducts = [
        {
          seller_id: user.id,
          title: 'Traditional Japanese Cookbook',
          description: 'A comprehensive guide to authentic Japanese cooking with detailed recipes and techniques.',
          category: 'recipe_book' as const,
          price: 29.99,
          images: ['https://source.unsplash.com/1600x900/?cookbook,japanese'],
          stock_quantity: 50,
          is_digital: true,
          digital_file_type: 'pdf' as const,
          status: 'published' as const,
          metadata: { pages: 200, format: 'PDF' }
        },
        {
          seller_id: user.id,
          title: 'Hand-woven Traditional Basket',
          description: 'Beautiful handcrafted basket made using traditional techniques.',
          category: 'crafts' as const,
          price: 79.99,
          images: ['https://source.unsplash.com/1600x900/?basket,handcraft'],
          stock_quantity: 10,
          is_digital: false,
          status: 'published' as const,
          metadata: { material: 'Natural fibers', size: 'Medium' }
        }
      ];

      const { error } = await supabase
        .from('products')
        .insert(sampleProducts);

      if (error) {
        console.error('Error creating sample products:', error);
      }
    }
  } catch (error) {
    console.error('Error initializing sample products:', error);
  }
}

export async function getProducts() {
  try {
    const { data, error } = await supabase
      .from('products')
      .select(`
        *,
        profiles:seller_id (
          username,
          avatar_url
        )
      `)
      .eq('status', 'published')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
}

export async function getSellerProducts() {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return [];

    const { data, error } = await supabase
      .from('products')
      .select(`
        *,
        profiles:seller_id (
          username,
          avatar_url
        )
      `)
      .eq('seller_id', user.id)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching seller products:', error);
    return [];
  }
}

export async function getProduct(id: string) {
  try {
    const { data, error } = await supabase
      .from('products')
      .select(`
        *,
        profiles:seller_id (
          username,
          avatar_url
        )
      `)
      .eq('id', id)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        throw new Error('Product not found');
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
}

export async function createProduct(product: Omit<Product, 'id' | 'seller_id' | 'created_at' | 'updated_at'>) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('products')
      .insert([{
        ...product,
        seller_id: user.id
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
}

export async function updateProduct(id: string, updates: Partial<Product>) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('products')
      .update(updates)
      .eq('id', id)
      .eq('seller_id', user.id)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
}

export async function deleteProduct(id: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { error } = await supabase
      .from('products')
      .delete()
      .eq('id', id)
      .eq('seller_id', user.id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting product:', error);
    throw error;
  }
}

export async function uploadFile(file: File, path: string): Promise<string> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase.storage
      .from('product-files')
      .upload(`${user.id}/${path}`, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) throw error;
    
    const { data: { publicUrl } } = supabase.storage
      .from('product-files')
      .getPublicUrl(data.path);

    return publicUrl;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}