import { useEffect, useCallback, useRef } from 'react';
import { useAuth as useAuthStore } from '../auth';
import { supabase } from '../supabase';
import type { User } from '@supabase/supabase-js';
import { captureError } from '../monitoring';
import { trackEvent } from '../analytics';

export interface AuthUser extends User {
  profile?: {
    id: string;
    username: string | null;
    full_name: string | null;
    avatar_url: string | null;
    role: string;
    points: number;
    star_rating: number;
    followers_count: number;
    following_count: number;
  } | null;
}

export function useAuth() {
  const {
    user,
    loading,
    error,
    signIn,
    signUp,
    signOut,
    signInWithFacebook,
    signInWithInstagram,
    resetPassword,
    clearError,
    refreshSession
  } = useAuthStore();

  // Use ref to prevent stale closure issues
  const refreshTimeoutRef = useRef<number>();
  const mountedRef = useRef(false);

  const refreshUser = useCallback(async () => {
    if (!mountedRef.current) return;
    
    try {
      await refreshSession();
      trackEvent('session_refreshed');
    } catch (error) {
      console.error('Error refreshing user:', error);
      captureError(error as Error);
    }
  }, [refreshSession]);

  // Schedule refresh with debounce
  const scheduleRefresh = useCallback(() => {
    if (refreshTimeoutRef.current) {
      window.clearTimeout(refreshTimeoutRef.current);
    }

    refreshTimeoutRef.current = window.setTimeout(async () => {
      if (mountedRef.current) {
        await refreshUser();
        scheduleRefresh(); // Schedule next refresh
      }
    }, 3600000); // 1 hour
  }, [refreshUser]);

  useEffect(() => {
    mountedRef.current = true;
    console.log('[useAuth] Mounted - Fetching user on init');
    refreshUser();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!mountedRef.current) return;
      
      console.log('[useAuth] Auth state changed:', event);
      
      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        await refreshUser();
        scheduleRefresh();
      } else if (event === 'SIGNED_OUT') {
        if (refreshTimeoutRef.current) {
          window.clearTimeout(refreshTimeoutRef.current);
        }
        await signOut();
      }
    });

    // Start refresh cycle
    if (user) {
      scheduleRefresh();
    }

    // Cleanup
    return () => {
      mountedRef.current = false;
      if (refreshTimeoutRef.current) {
        window.clearTimeout(refreshTimeoutRef.current);
      }
      subscription.unsubscribe();
    };
  }, [refreshUser, scheduleRefresh, signOut, user]);

  // Handle visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!mountedRef.current) return;

      if (document.visibilityState === 'visible' && user) {
        refreshUser();
        scheduleRefresh();
      } else if (refreshTimeoutRef.current) {
        window.clearTimeout(refreshTimeoutRef.current);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refreshUser, scheduleRefresh, user]);

  return {
    user: user as AuthUser | null,
    loading,
    error,
    signIn,
    signUp,
    signOut,
    signInWithFacebook,
    signInWithInstagram,
    resetPassword,
    clearError,
    refreshUser,
    isAuthenticated: !!user
  };
}