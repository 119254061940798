import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Layout } from '../components/Layout';
import { Home } from '../pages/Home';
import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { Explore } from '../pages/Explore';
import { ExploreDetail } from '../pages/ExploreDetail';
import { Profile } from '../pages/Profile';
import { UserProfile } from '../pages/UserProfile';
import { Post } from '../pages/Post';
import { Marketplace } from '../pages/Marketplace';
import { ProductDetail } from '../pages/ProductDetail';
import { SellerDashboard } from '../pages/SellerDashboard';
import { ProductForm } from '../pages/ProductForm';
import { Recipes } from '../pages/Recipes';
import { RecipeDetail } from '../pages/RecipeDetail';
import { RecipeForm } from '../pages/RecipeForm';
import { Admin } from '../pages/Admin';
import { CulturalPointForm } from '../pages/CulturalPointForm';
import { Settings } from '../pages/Settings';
import { Loading } from '../components/Loading';
import { useAuth } from './hooks/useAuth';
import { Feed } from '../pages/Feed';
import { Cart } from '../components/Cart';
import { Checkout } from '../pages/Checkout';
import { Topics } from '../pages/Topics';
import { TopicDetail } from '../pages/TopicDetail';
import { VirtualTours } from '../pages/VirtualTours';
import { VirtualTourDetail } from '../pages/VirtualTourDetail';
import { VirtualTourForm } from '../pages/VirtualTourForm';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { LanguageLearning } from '../pages/LanguageLearning';
import { CulturalQuiz } from '../pages/CulturalQuiz';

// Lazy load components that aren't immediately needed
const ConfirmEmail = lazy(() => import('../pages/ConfirmEmail').then(m => ({ default: m.ConfirmEmail })));

// Protected route wrapper
function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  return <>{children}</>;
}

// Auth route wrapper
function AuthRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (user) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}

// Admin route wrapper
function AdminRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (!user || user.profile?.role !== 'admin') {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}

// Define routes
const routes = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/signin', element: <AuthRoute><SignIn /></AuthRoute> },
      { path: '/signup', element: <AuthRoute><SignUp /></AuthRoute> },
      { 
        path: '/confirm-email', 
        element: (
          <Suspense fallback={<Loading fullScreen />}>
            <ConfirmEmail />
          </Suspense>
        )
      },
      { path: '/explore', element: <Explore /> },
      { path: '/explore/:id', element: <ExploreDetail /> },
      { path: '/feed', element: <ProtectedRoute><Feed /></ProtectedRoute> },
      { path: '/post/:id', element: <Post /> },
      { path: '/profile', element: <ProtectedRoute><Profile /></ProtectedRoute> },
      { path: '/profile/:id', element: <UserProfile /> },
      { path: '/settings', element: <ProtectedRoute><Settings /></ProtectedRoute> },
      { path: '/marketplace', element: <Marketplace /> },
      { path: '/marketplace/products/:id', element: <ProductDetail /> },
      { path: '/marketplace/seller', element: <ProtectedRoute><SellerDashboard /></ProtectedRoute> },
      { path: '/marketplace/products/new', element: <ProtectedRoute><ProductForm /></ProtectedRoute> },
      { path: '/marketplace/products/:id/edit', element: <ProtectedRoute><ProductForm /></ProtectedRoute> },
      { path: '/cart', element: <ProtectedRoute><Cart /></ProtectedRoute> },
      { path: '/checkout', element: <ProtectedRoute><Checkout /></ProtectedRoute> },
      { path: '/recipes', element: <Recipes /> },
      { path: '/recipes/:id', element: <RecipeDetail /> },
      { path: '/recipes/new', element: <ProtectedRoute><RecipeForm /></ProtectedRoute> },
      { path: '/recipes/:id/edit', element: <ProtectedRoute><RecipeForm /></ProtectedRoute> },
      { path: '/topics', element: <Topics /> },
      { path: '/topics/:id', element: <TopicDetail /> },
      { path: '/virtual-tours', element: <VirtualTours /> },
      { path: '/virtual-tours/:id', element: <VirtualTourDetail /> },
      { path: '/virtual-tours/new', element: <ProtectedRoute><VirtualTourForm /></ProtectedRoute> },
      { path: '/virtual-tours/:id/edit', element: <ProtectedRoute><VirtualTourForm /></ProtectedRoute> },
      { path: '/cultural-points/new', element: <ProtectedRoute><CulturalPointForm /></ProtectedRoute> },
      { path: '/cultural-points/:id/edit', element: <ProtectedRoute><CulturalPointForm /></ProtectedRoute> },
      { path: '/admin', element: <AdminRoute><Admin /></AdminRoute> },
      { path: '/language-learning', element: <LanguageLearning /> },
      { path: '/cultural-quiz', element: <CulturalQuiz /> },
      { path: '*', element: <Navigate to="/" replace /> }
    ]
  }
];

// Create router instance
const router = createBrowserRouter(routes);

// Router provider component
export function AppRouter() {
  return (
    <RouterProvider 
      router={router} 
      fallbackElement={<Loading fullScreen />} 
    />
  );
}