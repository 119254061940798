import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video as Video360, Plus, Search, Filter, Globe2, Clock, User, Eye, Languages } from 'lucide-react';
import { getVirtualTours, type VirtualTour } from '../lib/virtualTours';
import { useAuth } from '../lib/auth';
import { useTranslation } from 'react-i18next';

export function VirtualTours() {
  const [tours, setTours] = useState<VirtualTour[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<VirtualTour['type'] | 'all'>('all');
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    loadTours();
  }, []);

  async function loadTours() {
    try {
      const data = await getVirtualTours();
      setTours(data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const filteredTours = tours.filter(tour => {
    const matchesSearch = 
      tour.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tour.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tour.location.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = selectedType === 'all' || tour.type === selectedType;
    return matchesSearch && matchesType;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-center mb-8 gap-4">
        <div className="flex items-center">
          <Video360 className="w-8 h-8 text-blue-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900">Virtual Tours</h1>
        </div>
        
        {user && (
          <button
            onClick={() => navigate('/virtual-tours/new')}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            <Plus className="w-5 h-5 mr-2" />
            Create Tour
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 mb-6">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search virtual tours..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as VirtualTour['type'] | 'all')}
            className="border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="all">All Types</option>
            <option value="landmark">Landmarks</option>
            <option value="museum">Museums</option>
            <option value="cultural_site">Cultural Sites</option>
            <option value="historical_place">Historical Places</option>
          </select>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md mb-6">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredTours.map((tour) => (
          <div
            key={tour.id}
            onClick={() => navigate(`/virtual-tours/${tour.id}`)}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
          >
            <div className="relative h-48">
              <img
                src={tour.preview_image_url}
                alt={tour.title}
                className="w-full h-full object-cover"
              />
              {tour.is_360 && (
                <div className="absolute top-2 right-2 bg-blue-600 text-white px-2 py-1 rounded text-sm font-medium">
                  360°
                </div>
              )}
            </div>
            
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">{tour.title}</h3>
              <p className="text-gray-600 mb-4 line-clamp-2">{tour.description}</p>

              <div className="flex flex-wrap items-center gap-4 text-sm text-gray-500">
                <div className="flex items-center">
                  <Globe2 className="w-4 h-4 mr-1" />
                  {tour.location}
                </div>
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-1" />
                  {tour.duration} min
                </div>
                <div className="flex items-center">
                  <Languages className="w-4 h-4 mr-1" />
                  {tour.language}
                </div>
                <div className="flex items-center">
                  <Eye className="w-4 h-4 mr-1" />
                  {tour.view_count || 0}
                </div>
              </div>

              <div className="mt-4 flex items-center justify-between">
                <div className="flex items-center text-sm">
                  <img
                    src={tour.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${tour.profiles?.username || 'User'}`}
                    alt="Creator"
                    className="w-6 h-6 rounded-full mr-2"
                  />
                  {tour.profiles?.username || 'Anonymous'}
                </div>
                {tour.is_guided && (
                  <span className="text-sm text-blue-600 font-medium">
                    Guided Tour
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {filteredTours.length === 0 && (
        <div className="text-center py-12">
          <Video360 className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-xl font-semibold text-gray-900 mb-2">No virtual tours found</h3>
          <p className="text-gray-600 mb-4">
            {searchTerm
              ? "We couldn't find any virtual tours matching your search"
              : "There are no virtual tours yet. Be the first to create one!"}
          </p>
          {user && (
            <button
              onClick={() => navigate('/virtual-tours/new')}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Create Virtual Tour
            </button>
          )}
        </div>
      )}
    </div>
  );
}