import React from 'react';
import { CountryData } from '../types';
import { Globe2, Utensils, Landmark, Music, X } from 'lucide-react';

interface CulturalDetailsProps {
  data: CountryData;
  onClose: () => void;
}

export function CulturalDetails({ data, onClose }: CulturalDetailsProps) {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50" onClick={onClose}>
      <div 
        className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden max-w-2xl w-full max-h-[90vh] overflow-y-auto"
        onClick={e => e.stopPropagation()}
      >
        <div className="relative">
          <img
            src={data.imageUrl}
            alt={data.name}
            className="w-full h-48 object-cover"
          />
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/70"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 flex items-center">
              <span className="text-3xl mr-2">{data.flag}</span>
              {data.name}
            </h2>
            <p className="text-gray-600 dark:text-gray-300">{data.description}</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Section
              icon={Globe2}
              title="Traditions"
              items={data.details.traditions}
            />

            <Section
              icon={Utensils}
              title="Cuisines"
              items={data.details.cuisines.map(cuisine => 
                typeof cuisine === 'string' ? cuisine : cuisine.name
              )}
              details={data.details.cuisines}
            />

            <Section
              icon={Landmark}
              title="Landmarks"
              items={data.details.landmarks.map(landmark => 
                typeof landmark === 'string' ? landmark : landmark.name
              )}
              details={data.details.landmarks}
            />

            <Section
              icon={Music}
              title="Festivals"
              items={data.details.festivals}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface SectionProps {
  icon: React.FC<any>;
  title: string;
  items: string[];
  details?: Array<string | {
    name: string;
    description: string;
    location: string;
    imageUrl: string;
  }>;
}

function Section({ icon: Icon, title, items, details }: SectionProps) {
  if (!items || items.length === 0) return null;

  return (
    <div>
      <div className="flex items-center space-x-2 mb-3">
        <Icon className="w-5 h-5 text-blue-600 dark:text-blue-400" />
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
      </div>
      <ul className="space-y-2">
        {items.map((item, index) => {
          const detail = details?.find(d => 
            typeof d === 'string' ? d === item : d.name === item
          );
          
          return (
            <li key={index} className="flex flex-col">
              <div className="flex items-start">
                <span className="w-2 h-2 mt-2 bg-blue-600 dark:bg-blue-400 rounded-full mr-2 flex-shrink-0" />
                <span className="text-gray-700 dark:text-gray-300">{item}</span>
              </div>
              {detail && typeof detail !== 'string' && (
                <div className="ml-4 mt-2">
                  <img 
                    src={detail.imageUrl} 
                    alt={detail.name}
                    className="w-full h-32 object-cover rounded-md mb-2"
                  />
                  <p className="text-sm text-gray-600 dark:text-gray-400">{detail.description}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-500 mt-1">📍 {detail.location}</p>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}