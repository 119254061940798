import React, { useState } from 'react';
import { Search, Filter } from 'lucide-react';
import { countries, findCountry } from '../data/countries';
import { useTranslation } from 'react-i18next';
import { InteractiveMap } from '../components/InteractiveMap';
import { ExploreFilters } from '../components/ExploreFilters';
import { CulturalDetails } from '../components/CulturalDetails';
import type { CulturalPoint } from '../types';

export function Explore() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedType, setSelectedType] = useState<'all' | CulturalPoint['type']>('all');
  const { t } = useTranslation();

  const handleSearch = () => {
    if (!searchTerm.trim()) return;
    const country = findCountry(searchTerm);
    if (country) {
      setSelectedCountry(country);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Column - Search and Filters */}
        <div className="lg:col-span-1 space-y-6">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              {t('explore.title')}
            </h2>
            
            <div className="space-y-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  placeholder={t('explore.searchPlaceholder')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                />
              </div>
              
              <div className="flex items-center space-x-2">
                <Filter className="w-5 h-5 text-gray-400" />
                <select
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value as 'all' | CulturalPoint['type'])}
                  className="flex-1 border border-gray-300 dark:border-gray-600 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                >
                  <option value="all">All Categories</option>
                  <option value="landmark">Landmarks</option>
                  <option value="cuisine">Cuisines</option>
                  <option value="tradition">Traditions</option>
                  <option value="festival">Festivals</option>
                </select>
              </div>
            </div>
          </div>

          {/* Country List */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 space-y-4 max-h-[600px] overflow-y-auto">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Countries
            </h3>
            <div className="space-y-2">
              {countries.map((country) => (
                <button
                  key={country.name}
                  onClick={() => setSelectedCountry(country)}
                  className={`w-full text-left p-3 rounded-lg transition-colors ${
                    selectedCountry?.name === country.name
                      ? 'bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800'
                      : 'hover:bg-gray-50 dark:hover:bg-gray-700'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-2xl">{country.flag}</span>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white">
                        {country.name}
                      </h4>
                      <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-1">
                        {country.description}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Right Column - Map and Details */}
        <div className="lg:col-span-2 space-y-6">
          {/* Interactive Map */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
            <InteractiveMap 
              selectedType={selectedType}
              onPointClick={(point) => console.log('Clicked point:', point)}
              initialCoordinates={selectedCountry ? [selectedCountry.longitude, selectedCountry.latitude] : null}
            />
          </div>

          {/* Cultural Details */}
          {selectedCountry && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
              <CulturalDetails 
                data={selectedCountry} 
                onClose={() => setSelectedCountry(null)} 
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}