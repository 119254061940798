import { supabase } from './supabase';

export interface Topic {
  id: string;
  title: string;
  description: string;
  category: string;
  created_by: string;
  is_featured: boolean;
  is_approved: boolean;
  media_url: string | null;
  created_at: string;
  updated_at: string;
  followers_count?: number;
  posts_count?: number;
  is_following?: boolean;
}

export interface TopicPost {
  id: string;
  topic_id: string;
  user_id: string;
  content: string;
  media_urls: string[];
  is_pinned: boolean;
  created_at: string;
  updated_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
}

export async function getTopics() {
  const { data: { user } } = await supabase.auth.getUser();

  const { data, error } = await supabase
    .from('topics')
    .select(`
      *,
      followers:topic_followers(count),
      posts:topic_posts(count),
      is_following:topic_followers!inner(user_id)
    `)
    .order('created_at', { ascending: false });

  if (error) throw error;

  return (data || []).map(topic => ({
    ...topic,
    followers_count: topic.followers?.[0]?.count || 0,
    posts_count: topic.posts?.[0]?.count || 0,
    is_following: user ? !!topic.is_following?.length : false
  }));
}

export async function getTopic(id: string) {
  const { data, error } = await supabase
    .from('topics')
    .select(`
      *,
      followers:topic_followers(count),
      posts:topic_posts(
        *,
        profiles:user_id(username, avatar_url)
      )
    `)
    .eq('id', id)
    .single();

  if (error) throw error;
  return data;
}

export async function createTopic(topic: Omit<Topic, 'id' | 'created_by' | 'created_at' | 'updated_at'>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Check if user is admin
  const { data: profile } = await supabase
    .from('profiles')
    .select('role')
    .eq('id', user.id)
    .single();

  if (!profile || profile.role !== 'admin') {
    throw new Error('Only admins can create topics');
  }

  const { data, error } = await supabase
    .from('topics')
    .insert([{
      ...topic,
      created_by: user.id
    }])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function createTopicPost(topicId: string, post: {
  content: string;
  media_urls?: string[];
}) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  const { data, error } = await supabase
    .from('topic_posts')
    .insert([{
      topic_id: topicId,
      user_id: user.id,
      content: post.content,
      media_urls: post.media_urls || []
    }])
    .select(`
      *,
      profiles:user_id(username, avatar_url)
    `)
    .single();

  if (error) throw error;
  return data;
}

export async function followTopic(topicId: string) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  const { data, error } = await supabase
    .from('topic_followers')
    .insert([{
      topic_id: topicId,
      user_id: user.id
    }])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function unfollowTopic(topicId: string) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  const { error } = await supabase
    .from('topic_followers')
    .delete()
    .match({
      topic_id: topicId,
      user_id: user.id
    });

  if (error) throw error;
}