import { useState } from 'react';
import { Camera, Upload, X } from 'lucide-react';
import { uploadMedia } from '../lib/media';
import { useAuth } from '../lib/auth';

interface ProfilePictureUploadProps {
  currentUrl?: string;
  onUpload: (url: string) => Promise<void>;
}

export function ProfilePictureUpload({ currentUrl, onUpload }: ProfilePictureUploadProps) {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const { user } = useAuth();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('File size must be less than 5MB');
      return;
    }

    try {
      setUploading(true);
      setError(null);

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      // Upload file
      const url = await uploadMedia(file, 'avatars');
      await onUpload(url);
    } catch (err: any) {
      setError(err.message);
      setPreview(null);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="relative">
      <div className="relative w-32 h-32 mx-auto">
        <img
          src={preview || currentUrl || `https://ui-avatars.com/api/?name=${user?.email || 'User'}&background=random`}
          alt="Profile"
          className="w-full h-full rounded-full object-cover"
        />
        <label
          htmlFor="profile-picture"
          className="absolute bottom-0 right-0 p-2 bg-blue-600 text-white rounded-full cursor-pointer hover:bg-blue-700 transition-colors"
        >
          <Camera className="w-5 h-5" />
          <input
            type="file"
            id="profile-picture"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            disabled={uploading}
          />
        </label>
      </div>

      {uploading && (
        <div className="absolute inset-0 bg-black/50 rounded-full flex items-center justify-center">
          <Upload className="w-8 h-8 text-white animate-bounce" />
        </div>
      )}

      {error && (
        <div className="mt-2 text-sm text-red-600 flex items-center justify-center">
          <X className="w-4 h-4 mr-1" />
          {error}
        </div>
      )}
    </div>
  );
}