import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import all locale files
const en = {
  "common": {
    "explore": "Explore",
    "recipes": "Recipes",
    "chat": "Chat",
    "favorites": "Favorites",
    "profile": "Profile",
    "signIn": "Sign In",
    "signUp": "Sign Up",
    "signOut": "Sign Out",
    "loading": "Loading...",
    "error": "Error",
    "save": "Save",
    "cancel": "Cancel",
    "submit": "Submit",
    "delete": "Delete",
    "edit": "Edit",
    "search": "Search",
    "marketplace": "Marketplace"
  }
};

const es = {
  "common": {
    "explore": "Explorar",
    "recipes": "Recetas",
    "chat": "Chat",
    "favorites": "Favoritos",
    "profile": "Perfil",
    "signIn": "Iniciar Sesión",
    "signUp": "Registrarse",
    "signOut": "Cerrar Sesión",
    "loading": "Cargando...",
    "error": "Error",
    "save": "Guardar",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "delete": "Eliminar",
    "edit": "Editar",
    "search": "Buscar",
    "marketplace": "Mercado"
  }
};

const fr = {
  "common": {
    "explore": "Explorer",
    "recipes": "Recettes",
    "chat": "Chat",
    "favorites": "Favoris",
    "profile": "Profil",
    "signIn": "Se Connecter",
    "signUp": "S'inscrire",
    "signOut": "Se Déconnecter",
    "loading": "Chargement...",
    "error": "Erreur",
    "save": "Enregistrer",
    "cancel": "Annuler",
    "submit": "Soumettre",
    "delete": "Supprimer",
    "edit": "Modifier",
    "search": "Rechercher",
    "marketplace": "Marché"
  }
};

const ja = {
  "common": {
    "explore": "探索",
    "recipes": "レシピ",
    "chat": "チャット",
    "favorites": "お気に入り",
    "profile": "プロフィール",
    "signIn": "ログイン",
    "signUp": "新規登録",
    "signOut": "ログアウト",
    "loading": "読み込み中...",
    "error": "エラー",
    "save": "保存",
    "cancel": "キャンセル",
    "submit": "送信",
    "delete": "削除",
    "edit": "編集",
    "search": "検索",
    "marketplace": "マーケット"
  }
};

const zh = {
  "common": {
    "explore": "探索",
    "recipes": "食谱",
    "chat": "聊天",
    "favorites": "收藏",
    "profile": "个人资料",
    "signIn": "登录",
    "signUp": "注册",
    "signOut": "退出",
    "loading": "加载中...",
    "error": "错误",
    "save": "保存",
    "cancel": "取消",
    "submit": "提交",
    "delete": "删除",
    "edit": "编辑",
    "search": "搜索",
    "marketplace": "市场"
  }
};

const ar = {
  "common": {
    "explore": "استكشاف",
    "recipes": "وصفات",
    "chat": "دردشة",
    "favorites": "المفضلة",
    "profile": "الملف الشخصي",
    "signIn": "تسجيل الدخول",
    "signUp": "إنشاء حساب",
    "signOut": "تسجيل الخروج",
    "loading": "جار التحميل...",
    "error": "خطأ",
    "save": "حفظ",
    "cancel": "إلغاء",
    "submit": "إرسال",
    "delete": "حذف",
    "edit": "تعديل",
    "search": "بحث",
    "marketplace": "السوق"
  }
};

const ko = {
  "common": {
    "explore": "탐색",
    "recipes": "레시피",
    "chat": "채팅",
    "favorites": "즐겨찾기",
    "profile": "프로필",
    "signIn": "로그인",
    "signUp": "회원가입",
    "signOut": "로그아웃",
    "loading": "로딩 중...",
    "error": "오류",
    "save": "저장",
    "cancel": "취소",
    "submit": "제출",
    "delete": "삭제",
    "edit": "편집",
    "search": "검색",
    "marketplace": "마켓플레이스"
  }
};

const ru = {
  "common": {
    "explore": "Исследовать",
    "recipes": "Рецепты",
    "chat": "Чат",
    "favorites": "Избранное",
    "profile": "Профиль",
    "signIn": "Войти",
    "signUp": "Регистрация",
    "signOut": "Выйти",
    "loading": "Загрузка...",
    "error": "Ошибка",
    "save": "Сохранить",
    "cancel": "Отмена",
    "submit": "Отправить",
    "delete": "Удалить",
    "edit": "Редактировать",
    "search": "Поиск",
    "marketplace": "Маркетплейс"
  }
};

const pt = {
  "common": {
    "explore": "Explorar",
    "recipes": "Receitas",
    "chat": "Chat",
    "favorites": "Favoritos",
    "profile": "Perfil",
    "signIn": "Entrar",
    "signUp": "Cadastrar",
    "signOut": "Sair",
    "loading": "Carregando...",
    "error": "Erro",
    "save": "Salvar",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "delete": "Excluir",
    "edit": "Editar",
    "search": "Buscar",
    "marketplace": "Mercado"
  }
};

const hi = {
  "common": {
    "explore": "खोजें",
    "recipes": "व्यंजन",
    "chat": "चैट",
    "favorites": "पसंदीदा",
    "profile": "प्रोफ़ाइल",
    "signIn": "साइन इन",
    "signUp": "साइन अप",
    "signOut": "साइन आउट",
    "loading": "लोड हो रहा है...",
    "error": "त्रुटि",
    "save": "सहेजें",
    "cancel": "रद्द करें",
    "submit": "जमा करें",
    "delete": "हटाएं",
    "edit": "संपादित करें",
    "search": "खोजें",
    "marketplace": "बाज़ार"
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
      fr: { translation: fr },
      ja: { translation: ja },
      zh: { translation: zh },
      ar: { translation: ar },
      ko: { translation: ko },
      ru: { translation: ru },
      pt: { translation: pt },
      hi: { translation: hi }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;