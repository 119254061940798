import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Video as Video360, ArrowLeft, Globe2, Clock, Languages, Eye, User, Settings, AlertCircle } from 'lucide-react';
import { getVirtualTour, type VirtualTour } from '../lib/virtualTours';
import { useAuth } from '../lib/auth';

export function VirtualTourDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [tour, setTour] = useState<VirtualTour | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;
    loadTour();
  }, [id]);

  async function loadTour() {
    try {
      const data = await getVirtualTour(id!);
      setTour(data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !tour) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
          {error || 'Virtual tour not found'}
        </div>
        <button
          onClick={() => navigate('/virtual-tours')}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <ArrowLeft className="w-4 h-4 mr-1" />
          Back to Virtual Tours
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <button
        onClick={() => navigate('/virtual-tours')}
        className="flex items-center text-gray-600 hover:text-gray-800 mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-1" />
        Back to Virtual Tours
      </button>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="relative h-96">
          <img
            src={tour.preview_image_url}
            alt={tour.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            <div className="flex items-center space-x-2 mb-2">
              {tour.is_360 && (
                <span className="px-2 py-1 text-xs font-medium bg-blue-600 rounded-full">
                  360° Tour
                </span>
              )}
              {tour.is_guided && (
                <span className="px-2 py-1 text-xs font-medium bg-green-600 rounded-full">
                  Guided Tour
                </span>
              )}
            </div>
            <h1 className="text-4xl font-bold mb-2">{tour.title}</h1>
            <div className="flex flex-wrap items-center gap-4 text-white/80">
              <div className="flex items-center">
                <Globe2 className="w-4 h-4 mr-1" />
                {tour.location}, {tour.country}
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                {tour.duration} minutes
              </div>
              <div className="flex items-center">
                <Languages className="w-4 h-4 mr-1" />
                {tour.language.toUpperCase()}
              </div>
              <div className="flex items-center">
                <Eye className="w-4 h-4 mr-1" />
                {tour.view_count} views
              </div>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <img
                src={tour.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${tour.profiles?.username || 'User'}`}
                alt={tour.profiles?.username}
                className="w-10 h-10 rounded-full"
              />
              <div>
                <div className="font-medium text-gray-900">
                  {tour.profiles?.username || 'Anonymous'}
                </div>
                <div className="text-sm text-gray-500">
                  Created on {new Date(tour.created_at).toLocaleDateString()}
                </div>
              </div>
            </div>

            {user?.id === tour.user_id && (
              <button
                onClick={() => navigate(`/virtual-tours/${tour.id}/edit`)}
                className="p-2 text-gray-600 hover:text-blue-600"
              >
                <Settings className="w-5 h-5" />
              </button>
            )}
          </div>

          <div className="prose max-w-none">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">About this tour</h2>
            <p className="text-gray-700">{tour.description}</p>
          </div>

          {tour.accessibility_features?.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Accessibility Features</h3>
              <div className="flex flex-wrap gap-2">
                {tour.accessibility_features.map((feature, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          )}

          {tour.is_guided && tour.metadata.tour_stops?.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Tour Stops</h3>
              <div className="space-y-4">
                {tour.metadata.tour_stops.map((stop, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <h4 className="font-medium text-gray-900 mb-2">
                      {index + 1}. {stop.title}
                    </h4>
                    <p className="text-gray-700">{stop.description}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mt-8">
            <a
              href={tour.tour_url}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full bg-blue-600 text-white text-center px-6 py-3 rounded-md hover:bg-blue-700 transition-colors"
            >
              Start Virtual Tour
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}