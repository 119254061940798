import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X, Book, MapPin, ChefHat, Video, Languages, Brain } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useDebounce } from '../lib/hooks/useDebounce';

interface SearchResult {
  id: string;
  title: string;
  type: 'recipe' | 'cultural_point' | 'virtual_tour' | 'translation' | 'quiz';
  description?: string;
  image_url?: string;
  category?: string;
  url: string;
}

export function GlobalSearch() {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (debouncedQuery) {
      searchContent();
    } else {
      setResults([]);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  async function searchContent() {
    if (!debouncedQuery.trim()) return;

    setLoading(true);
    try {
      // Search recipes
      const { data: recipes } = await supabase
        .from('recipes')
        .select('id, title, description, media_urls')
        .or(`title.ilike.%${debouncedQuery}%,description.ilike.%${debouncedQuery}%`)
        .limit(3);

      // Search cultural points
      const { data: culturalPoints } = await supabase
        .from('cultural_points')
        .select('id, title, description, media_urls, type')
        .or(`title.ilike.%${debouncedQuery}%,description.ilike.%${debouncedQuery}%`)
        .limit(3);

      // Search virtual tours
      const { data: virtualTours } = await supabase
        .from('virtual_tours')
        .select('id, title, description, preview_image_url')
        .or(`title.ilike.%${debouncedQuery}%,description.ilike.%${debouncedQuery}%`)
        .eq('status', 'published')
        .limit(3);

      // Search translations
      const { data: translations } = await supabase
        .from('translations')
        .select('id, phrase, translation, category')
        .or(`phrase.ilike.%${debouncedQuery}%,translation.ilike.%${debouncedQuery}%`)
        .limit(3);

      // Search quizzes
      const { data: quizzes } = await supabase
        .from('quizzes')
        .select('id, title, description, category')
        .or(`title.ilike.%${debouncedQuery}%,description.ilike.%${debouncedQuery}%`)
        .eq('is_active', true)
        .limit(3);

      const allResults: SearchResult[] = [
        ...(recipes?.map(recipe => ({
          id: recipe.id,
          title: recipe.title,
          type: 'recipe' as const,
          description: recipe.description,
          image_url: recipe.media_urls?.[0],
          url: `/recipes/${recipe.id}`
        })) || []),
        ...(culturalPoints?.map(point => ({
          id: point.id,
          title: point.title,
          type: 'cultural_point' as const,
          description: point.description,
          image_url: point.media_urls?.[0],
          category: point.type,
          url: `/cultural-points/${point.id}`
        })) || []),
        ...(virtualTours?.map(tour => ({
          id: tour.id,
          title: tour.title,
          type: 'virtual_tour' as const,
          description: tour.description,
          image_url: tour.preview_image_url,
          url: `/virtual-tours/${tour.id}`
        })) || []),
        ...(translations?.map(translation => ({
          id: translation.id,
          title: translation.phrase,
          type: 'translation' as const,
          description: translation.translation,
          category: translation.category,
          url: `/language-learning?phrase=${encodeURIComponent(translation.phrase)}`
        })) || []),
        ...(quizzes?.map(quiz => ({
          id: quiz.id,
          title: quiz.title,
          type: 'quiz' as const,
          description: quiz.description,
          category: quiz.category,
          url: `/cultural-quiz/${quiz.id}`
        })) || [])
      ];

      setResults(allResults);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setLoading(false);
    }
  }

  const getTypeIcon = (type: SearchResult['type']) => {
    switch (type) {
      case 'recipe':
        return <ChefHat className="w-5 h-5 text-green-600" />;
      case 'cultural_point':
        return <MapPin className="w-5 h-5 text-blue-600" />;
      case 'virtual_tour':
        return <Video className="w-5 h-5 text-purple-600" />;
      case 'translation':
        return <Languages className="w-5 h-5 text-orange-600" />;
      case 'quiz':
        return <Brain className="w-5 h-5 text-red-600" />;
      default:
        return <Book className="w-5 h-5 text-gray-600" />;
    }
  };

  return (
    <div ref={searchRef} className="relative">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
          placeholder="Search across all content..."
          className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
        />
        {query && (
          <button
            onClick={() => {
              setQuery('');
              setResults([]);
            }}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>
        )}
      </div>

      {isOpen && (query || results.length > 0) && (
        <div className="absolute z-50 top-full left-0 right-0 mt-1 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 max-h-96 overflow-y-auto">
          {loading ? (
            <div className="p-4 text-center">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600 mx-auto"></div>
            </div>
          ) : results.length > 0 ? (
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {results.map((result) => (
                <button
                  key={`${result.type}-${result.id}`}
                  onClick={() => {
                    navigate(result.url);
                    setIsOpen(false);
                    setQuery('');
                  }}
                  className="w-full text-left p-4 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-start space-x-4"
                >
                  <div className="flex-shrink-0">
                    {getTypeIcon(result.type)}
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                      {result.title}
                    </p>
                    {result.description && (
                      <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                        {result.description}
                      </p>
                    )}
                    {result.category && (
                      <p className="text-xs text-gray-400 dark:text-gray-500 mt-1">
                        {result.category}
                      </p>
                    )}
                  </div>
                  {result.image_url && (
                    <div className="flex-shrink-0 w-16 h-16">
                      <img
                        src={result.image_url}
                        alt={result.title}
                        className="w-full h-full object-cover rounded"
                      />
                    </div>
                  )}
                </button>
              ))}
            </div>
          ) : query ? (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              No results found
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}