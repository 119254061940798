import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Heart, 
  MessageSquare, 
  Share2,
  Globe2,
  AlertCircle,
  Video
} from 'lucide-react';
import { useAuth } from '../lib/hooks/useAuth';
import { useInfinitePosts } from '../lib/hooks/useInfinitePosts';
import { CreatePost } from '../components/CreatePost';

export function Feed() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { 
    posts, 
    loading, 
    error,
    hasMore,
    loadPosts, 
    handleCreatePost, 
    handleLike, 
    handleShare,
    lastPostRef
  } = useInfinitePosts();

  useEffect(() => {
    if (!user) {
      console.log('[Feed] No user, redirecting to /signin');
      navigate('/signin');
      return;
    }
    
    console.log('[Feed] Loading initial posts');
    loadPosts(true);
  }, [user, navigate, loadPosts]);

  const handlePostClick = (postId: string) => {
    console.log('[Feed] Navigating to post:', postId);
    navigate(`/post/${postId}`);
  };

  const getMediaType = React.useCallback((url: string) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    const extension = url.toLowerCase().split('.').pop();
    return extension && videoExtensions.includes('.' + extension) ? 'video' : 'image';
  }, []);

  if (loading && posts.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4 flex items-start">
          <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 mr-3 flex-shrink-0" />
          <p className="text-red-600">{error}</p>
        </div>
      )}

      <CreatePost onPost={handleCreatePost} />

      <div className="space-y-6">
        {posts && posts.length > 0 ? (
          posts.map((post, index) => (
            <div 
              key={post.id} 
              ref={index === posts.length - 1 ? lastPostRef : null}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <div className="p-4">
                <div className="flex items-center space-x-3 mb-4">
                  <img
                    src={post.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${post.profiles?.username || 'User'}&background=random`}
                    alt={post.profiles?.username || 'User avatar'}
                    className="w-10 h-10 rounded-full"
                    loading="lazy"
                  />
                  <div>
                    <div className="font-medium text-gray-900">
                      {post.profiles?.username || 'Anonymous'}
                    </div>
                    <div className="text-sm text-gray-500 flex items-center">
                      <Globe2 className="w-4 h-4 mr-1" />
                      {new Date(post.created_at).toLocaleString()}
                    </div>
                  </div>
                </div>

                <p className="text-gray-800 mb-4">{post.content}</p>

                {post.media_urls?.length > 0 && (
                  <div className="space-y-4 mb-4">
                    {post.media_urls.map((url, index) => (
                      <div key={index} className="rounded-lg overflow-hidden">
                        {getMediaType(url) === 'video' ? (
                          <div className="relative">
                            <video
                              src={url}
                              controls
                              className="w-full rounded-lg"
                              loading="lazy"
                            />
                            <Video className="absolute top-2 left-2 w-6 h-6 text-white drop-shadow-lg" />
                          </div>
                        ) : (
                          <img
                            src={url}
                            alt="Post content"
                            className="w-full rounded-lg"
                            loading="lazy"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}

                <div className="flex items-center space-x-4 text-gray-500">
                  <button
                    onClick={() => handleLike(post.id, post.has_liked || false)}
                    className={`flex items-center space-x-1 transition-colors ${
                      post.has_liked ? 'text-red-600' : 'hover:text-red-600'
                    }`}
                  >
                    <Heart className={`w-5 h-5 ${post.has_liked ? 'fill-current' : ''}`} />
                    <span>{post.likes_count || 0}</span>
                  </button>
                  <button
                    onClick={() => handlePostClick(post.id)}
                    className="flex items-center space-x-1 hover:text-blue-600 transition-colors"
                  >
                    <MessageSquare className="w-5 h-5" />
                    <span>{post.comments_count || 0}</span>
                  </button>
                  <button
                    onClick={() => handleShare(post)}
                    className="flex items-center space-x-1 hover:text-blue-600 transition-colors"
                  >
                    <Share2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-12 bg-white rounded-lg shadow-md">
            <Globe2 className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">No posts yet</h3>
            <p className="text-gray-600">
              Be the first to share something with the community!
            </p>
          </div>
        )}

        {loading && posts.length > 0 && (
          <div className="flex justify-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        )}

        {!loading && !hasMore && posts.length > 0 && (
          <div className="text-center py-4 text-gray-600">
            No more posts to load
          </div>
        )}
      </div>
    </div>
  );
}