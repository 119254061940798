// Maximum safe recursion depth
const MAX_RECURSION_DEPTH = 1000;

/**
 * Safely process data with recursion using a depth counter
 */
export function safeRecursiveFunction(n: number, depth = 0): void {
  // Base case with depth check
  if (n <= 0 || depth >= MAX_RECURSION_DEPTH) {
    return;
  }
  
  // Recursive call with depth tracking
  safeRecursiveFunction(n - 1, depth + 1);
}

/**
 * Process large arrays iteratively instead of recursively
 */
export function processLargeArray<T>(
  arr: T[],
  processor: (item: T) => void,
  chunkSize = 1000
): void {
  let index = 0;

  function processChunk() {
    const chunk = arr.slice(index, index + chunkSize);
    
    if (chunk.length === 0) {
      return;
    }

    // Process current chunk
    chunk.forEach(processor);
    
    // Move to next chunk
    index += chunkSize;
    
    // Schedule next chunk processing
    setTimeout(processChunk, 0);
  }

  processChunk();
}

/**
 * Mutual recursion with depth tracking
 */
export class SafeMutualRecursion {
  private maxDepth: number;
  private currentDepth: number;

  constructor(maxDepth = MAX_RECURSION_DEPTH) {
    this.maxDepth = maxDepth;
    this.currentDepth = 0;
  }

  public functionA(n: number): void {
    if (n <= 0 || this.currentDepth >= this.maxDepth) {
      return;
    }
    
    this.currentDepth++;
    this.functionB(n - 1);
    this.currentDepth--;
  }

  public functionB(n: number): void {
    if (n <= 0 || this.currentDepth >= this.maxDepth) {
      return;
    }
    
    this.currentDepth++;
    this.functionA(n - 1);
    this.currentDepth--;
  }
}

/**
 * Tail-recursive function that gets optimized by the engine
 */
export function tailRecursive(n: number, accumulator = 0): number {
  if (n <= 0) {
    return accumulator;
  }
  
  return tailRecursive(n - 1, accumulator + n);
}

/**
 * Convert recursive function to iterative
 */
export function iterativeVersion(n: number): number {
  let result = 0;
  while (n > 0) {
    result += n;
    n--;
  }
  return result;
}

/**
 * Trampoline function to handle recursion
 */
export function trampoline<T>(fn: Function) {
  return function(...args: any[]) {
    let result = fn(...args);
    
    while (typeof result === 'function') {
      result = result();
    }
    
    return result;
  };
}

// Example of using trampoline
export const sumRecursive = trampoline(function sum(n: number, acc = 0): any {
  if (n <= 0) {
    return acc;
  }
  return () => sum(n - 1, acc + n);
});