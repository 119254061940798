import * as Sentry from '@sentry/react';
import { trackError } from './analytics';

export function captureError(error: Error, context?: Record<string, any>) {
  // Send to Sentry
  Sentry.captureException(error, {
    extra: context
  });
  
  // Track in analytics
  trackError(error, context);
  
  // Log to console in development
  if (import.meta.env.DEV) {
    console.error('Error captured:', error, context);
  }
}

export function captureMessage(message: string, level: Sentry.SeverityLevel = 'info') {
  Sentry.captureMessage(message, {
    level
  });
}

export function startTransaction(name: string, op: string) {
  return Sentry.startTransaction({
    name,
    op
  });
}