import React, { useState, useRef } from 'react';
import { Send, X, Upload } from 'lucide-react';
import { useAuth } from '../lib/auth';
import { uploadMedia } from '../lib/media';

interface CreatePostProps {
  onPost: (content: string, mediaUrls: string[]) => Promise<void>;
}

export function CreatePost({ onPost }: CreatePostProps) {
  const [content, setContent] = useState('');
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [mediaUrls, setMediaUrls] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();

  if (!user) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!content.trim() && mediaFiles.length === 0) return;

    try {
      setLoading(true);
      
      // Upload all media files first
      const uploadedUrls = await Promise.all(
        mediaFiles.map(file => uploadMedia(file))
      );

      await onPost(content, [...mediaUrls, ...uploadedUrls]);
      setContent('');
      setMediaFiles([]);
      setMediaUrls([]);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length === 0) return;

    // Validate file types
    const validFiles = files.filter(file => {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/webm'];
      return validTypes.includes(file.type);
    });

    if (validFiles.length !== files.length) {
      alert('Some files were not added. Only images (JPEG, PNG, GIF) and videos (MP4, WebM) are allowed.');
    }

    setMediaFiles(prev => [...prev, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setMediaFiles(prev => prev.filter((_, i) => i !== index));
  };

  const getMediaType = (file: File) => {
    if (file.type.startsWith('video/')) return 'video';
    return 'image';
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Share your thoughts..."
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
          rows={3}
        />

        {mediaFiles.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {mediaFiles.map((file, index) => (
              <div key={index} className="relative group">
                {getMediaType(file) === 'video' ? (
                  <video
                    src={URL.createObjectURL(file)}
                    className="w-full h-32 object-cover rounded-md"
                    controls
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Media ${index + 1}`}
                    className="w-full h-32 object-cover rounded-md"
                  />
                )}
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="flex items-center space-x-4">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/jpeg,image/png,image/gif,video/mp4,video/webm"
            multiple
            className="hidden"
          />
          
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-blue-600 border border-gray-300 rounded-md hover:border-blue-500 transition-colors"
          >
            <Upload className="w-5 h-5" />
            <span>Add Media</span>
          </button>

          <button
            type="submit"
            disabled={loading || (!content.trim() && mediaFiles.length === 0)}
            className="flex-1 bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
          >
            {loading ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                <span>Posting...</span>
              </>
            ) : (
              <>
                <span>Post</span>
                <Send className="w-4 h-4" />
              </>
            )}
          </button>
        </div>

        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
        )}
      </form>
    </div>
  );
}