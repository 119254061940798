import { supabase } from './supabase';

export async function trackEvent(name: string, properties?: Record<string, any>) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    await supabase.from('user_activity_log').insert([{
      user_id: user?.id,
      activity_type: name,
      activity_data: properties
    }]);
  } catch (error) {
    console.error('Error tracking event:', error);
  }
}

export async function trackPageView(page: string) {
  await trackEvent('page_view', { page });
}

export async function trackError(error: Error, context?: Record<string, any>) {
  await trackEvent('error', {
    message: error.message,
    stack: error.stack,
    ...context
  });
}