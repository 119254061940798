import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Globe2, MapPin, Info } from 'lucide-react';
import { getExploreEntry } from '../lib/explore';
import { Loading } from '../components/Loading';
import type { ExploreEntry } from '../lib/explore';

export function ExploreDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [entry, setEntry] = useState<ExploreEntry | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;
    loadEntry();
  }, [id]);

  async function loadEntry() {
    try {
      const data = await getExploreEntry(id!);
      setEntry(data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading fullScreen />;
  }

  if (error || !entry) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
          {error || 'Entry not found'}
        </div>
        <button
          onClick={() => navigate('/explore')}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <ArrowLeft className="w-4 h-4 mr-1" />
          Back to Explore
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <button
        onClick={() => navigate('/explore')}
        className="flex items-center text-gray-600 hover:text-gray-800 mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-1" />
        Back to Explore
      </button>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="relative h-96">
          <img
            src={entry.image_url || `https://source.unsplash.com/1600x900/?${entry.category},${entry.title.split(' ').join(',')}`}
            alt={entry.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            <div className="flex items-center space-x-2 mb-2">
              <Globe2 className="w-5 h-5" />
              <span className="text-sm font-medium uppercase">{entry.category}</span>
            </div>
            <h1 className="text-4xl font-bold mb-2">{entry.title}</h1>
            {entry.location && (
              <div className="flex items-center text-white/80">
                <MapPin className="w-4 h-4 mr-1" />
                {entry.location}
              </div>
            )}
          </div>
        </div>

        <div className="p-6">
          <div className="prose dark:prose-invert max-w-none">
            <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
              {entry.description}
            </p>

            {entry.metadata && Object.keys(entry.metadata).length > 0 && (
              <div className="mt-8">
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4 flex items-center">
                  <Info className="w-6 h-6 mr-2" />
                  Additional Information
                </h2>
                <dl className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {Object.entries(entry.metadata).map(([key, value]) => (
                    <div key={key} className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                      </dt>
                      <dd className="text-gray-900 dark:text-white">
                        {typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            )}
          </div>

          <div className="mt-8 flex items-center justify-between text-sm text-gray-500 dark:text-gray-400">
            <div className="flex items-center">
              <img
                src={entry.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${entry.profiles?.username || 'User'}`}
                alt={entry.profiles?.username || 'User avatar'}
                className="w-8 h-8 rounded-full mr-2"
              />
              <span>
                Posted by {entry.profiles?.username || 'Anonymous'} on{' '}
                {new Date(entry.created_at).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}