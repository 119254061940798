import type { CountryData } from '../types';

export const countries: CountryData[] = [
  {
    name: 'Italy',
    flag: '🇮🇹',
    latitude: 41.8719,
    longitude: 12.5674,
    description: 'A country renowned for its art, architecture, cuisine, and rich cultural heritage spanning thousands of years.',
    imageUrl: 'https://images.unsplash.com/photo-1516483638261-f4dbaf036963',
    details: {
      traditions: [
        'Venetian Carnival',
        'Palio di Siena',
        'Ferragosto',
        'Easter Celebrations',
        'Republic Day',
        'Feast of the Seven Fishes',
        'Midnight Mass',
        'St. Joseph\'s Day',
        'Wine Harvest Festival',
        'Medieval Festivals'
      ],
      cuisines: [
        {
          name: 'Pizza Napoletana',
          description: 'Italy\'s signature dish, featuring a thin-crust base topped with fresh tomato sauce, mozzarella, basil, and a variety of regional toppings.',
          location: 'Naples',
          imageUrl: 'https://www.grandecheese.com/wp-content/uploads/2021/01/Margherita-Pizza-deck-oven.jpg'
        },
        {
          name: 'Pasta',
          description: 'A versatile staple prepared in countless shapes and sauces—from simple aglio e olio to rich Bolognese—reflecting Italy\'s diverse culinary regions.',
          location: 'Nationwide',
          imageUrl: 'https://www.cucchiaio.it/content/cucchiaio/it/ricette/2019/12/spaghetti-al-pomodoro/jcr:content/header-par/image-single.img10.jpg/1576681061599.jpg'
        },
        {
          name: 'Risotto',
          description: 'A creamy rice dish slowly cooked in broth with ingredients such as saffron, mushrooms, or seafood, prized for its rich texture and flavor.',
          location: 'Northern Italy',
          imageUrl: 'https://images.services.kitchenstories.io/2C_zyFcVkyTamAu2_9SzLbMC0YI=/3840x0/filters:quality(80)/images.kitchenstories.io/communityImages/4ef74f5d5bcffb4f8769e9dbe543570a_21814fd1-1893-45dd-9777-f0a0c30b15ef.jpg'
        },
        {
          name: 'Lasagna',
          description: 'Layered pasta baked with meat or vegetable ragù, béchamel sauce, and cheese, offering a comforting taste of Italian home cooking.',
          location: 'Bologna',
          imageUrl: 'https://www.foodnetwork.com/content/dam/images/food/fullset/2021/11/24/0/YK501_rocco-dispirito-ten-layer-lasagna-bolognese_s4x3.jpg'
        },
        {
          name: 'Tiramisu',
          description: 'A popular dessert made with layers of coffee-soaked ladyfingers, mascarpone cream, and cocoa, celebrated for its delicate, indulgent flavor.',
          location: 'Veneto',
          imageUrl: 'https://zhangcatherine.com/wp-content/uploads/2023/05/12001200-2.jpg'
        },
        {
          name: 'Gelato',
          description: 'Italy\'s version of ice cream, known for its dense, silky texture and intense flavors made from fresh, natural ingredients.',
          location: 'Nationwide',
          imageUrl: 'https://italianmart.ca/cdn/shop/files/gelato-gelato-sicilian-ice-cream-vanilla-165lt-905488_1024x1024@2x.png?v=1736060429'
        },
        {
          name: 'Focaccia',
          description: 'A flat, oven-baked bread seasoned with olive oil, herbs, and sometimes toppings like olives or tomatoes, enjoyed as a snack or side dish.',
          location: 'Liguria',
          imageUrl: 'https://img.taste.com.au/QJMyaW8z/taste/2020/10/november20_stuffed-focaccia-with-tomato-and-olive-taste-166164-1.jpg'
        },
        {
          name: 'Arancini',
          description: 'Deep-fried rice balls, typically filled with ragù, peas, and mozzarella, representing a beloved Sicilian street food.',
          location: 'Sicily',
          imageUrl: 'https://ichef.bbci.co.uk/food/ic/food_16x9_1600/recipes/arancine_al_rag_11849_16x9.jpg'
        },
        {
          name: 'Cannoli',
          description: 'Crisp pastry tubes filled with sweetened ricotta cheese and often dotted with chocolate chips or candied fruit, a classic Sicilian dessert.',
          location: 'Sicily',
          imageUrl: 'https://preppykitchen.com/wp-content/uploads/2023/09/Cannoli-Recipe-Card.jpg'
        },
        {
          name: 'Polenta',
          description: 'A comforting dish made from boiled cornmeal that can be served creamy or allowed to set and then grilled or fried, often paired with savory sauces or stews.',
          location: 'Northern Italy',
          imageUrl: 'https://littleferrarokitchen.com/wp-content/uploads/2011/11/Creamy-Polenta-Updated-8-copy.jpg'
        }
      ],
      landmarks: [
        {
          name: 'Colosseum',
          description: 'An iconic ancient amphitheater that once hosted gladiatorial contests and public spectacles, symbolizing the grandeur of the Roman Empire.',
          location: 'Rome',
          imageUrl: 'https://www.walksinsiderome.com/wp-content/uploads/2024/06/20170218_123237-scaled.jpg'
        },
        {
          name: 'Leaning Tower of Pisa',
          description: 'A famous bell tower noted for its unintended tilt, attracting visitors who marvel at its unique architectural flaw and historical charm.',
          location: 'Pisa',
          imageUrl: 'https://rtwin30days.com/wp-content/uploads/2015/02/Leaning-Tower-of-Pisa-Italy-1.jpg'
        },
        {
          name: 'Venice Canals',
          description: 'The winding waterways of Venice that serve as both thoroughfares and tourist attractions, best explored by gondola or vaporetto.',
          location: 'Venice',
          imageUrl: 'https://media.audleytravel.com/-/media/images/home/europe/italy/travel-guides/secret-corners-and-canals-of-venice/gi_1397538502_venice_canal_3000x1000.jpg'
        },
        {
          name: 'Vatican City',
          description: 'An independent city-state enclaved within Rome, home to St. Peter\'s Basilica, the Sistine Chapel, and the spiritual heart of the Roman Catholic Church.',
          location: 'Rome',
          imageUrl: 'https://www.legallanguage.com/wp-content/uploads/2016/12/vatican-city-1233913_960_720.jpg'
        },
        {
          name: 'Roman Forum',
          description: 'The ruins of ancient government buildings and temples that once formed the center of Roman public life, offering a glimpse into antiquity.',
          location: 'Rome',
          imageUrl: 'https://rome.us/wp-content/uploads/Roman-Forum-in-Rome-Italy.jpg'
        },
        {
          name: 'Trevi Fountain',
          description: 'A stunning Baroque fountain where visitors traditionally toss coins to ensure a return to Rome, famed for its intricate sculptures and dramatic water displays.',
          location: 'Rome',
          imageUrl: 'https://www.foodandwine.com/thmb/bfw9_o6f_-UoYXY7h8jITq3vep8=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Tourist-Scales-Romes-Trevi-Fountain-in-Rome-to-Fill-Her-Water-Bottle-Breaking-Rules-of-Centuries-Old-Landmark-FT-BLOG0823-a1757d24dc3e4409b71c213a23cc54fb.jpg'
        },
        {
          name: 'Pantheon',
          description: 'A remarkably preserved ancient temple with a majestic domed ceiling, now serving as a church and a testament to Roman engineering.',
          location: 'Rome',
          imageUrl: 'https://static1.thetravelimages.com/wordpress/wp-content/uploads/2023/05/rome-italy-at-the-pantheon.jpg'
        },
        {
          name: 'Uffizi Gallery',
          description: 'One of the world\'s most important art museums, featuring masterpieces from the Renaissance, including works by Michelangelo, Leonardo da Vinci, and Botticelli.',
          location: 'Florence',
          imageUrl: 'https://cdn-imgix.headout.com/media/images/face1f508b5fed3a811a9bdbdd2eb1d0-533-florence-florence--museums-%26-art-galleries_uffizi-art-gallery-01.jpg'
        },
        {
          name: 'Pompeii Archaeological Site',
          description: 'The preserved remains of the ancient city buried by Mount Vesuvius in AD 79, offering unparalleled insight into Roman life and architecture.',
          location: 'Pompeii',
          imageUrl: 'https://static.scientificamerican.com/sciam/cache/file/01441EC0-AE7C-4F92-94583DF9D6C8EAFF_source.jpg'
        },
        {
          name: 'Mount Vesuvius',
          description: 'An active volcano overlooking the Bay of Naples, infamous for its historic eruption that preserved the city of Pompeii and the surrounding area.',
          location: 'Naples',
          imageUrl: 'https://cdn-imgix.headout.com/media/images/2569c36cdb7ce3b9db139cb26029b802-listingId-4594-fileName-mount-vesuvius.jpg'
        }
      ],
      festivals: [
        {
          name: 'Venice Carnival',
          description: 'A dazzling pre-Lenten carnival in Venice marked by elaborate masks, costumes, and festive parades.',
          location: 'Venice',
          imageUrl: 'https://www.casaalcarmine.it/wp-content/uploads/2020/02/Carnevale-Venezia-2020-Casa-Al-carmine-916x675.jpg'
        },
        {
          name: 'Ferragosto',
          description: 'A lively summer holiday on August 15 that blends religious observance with seasonal vacations, marked by feasts and community celebrations.',
          location: 'Nationwide',
          imageUrl: 'https://www.italymagazine.com/sites/default/files/feature-story/leader/ferragosto-in-italy.jpg'
        },
        {
          name: 'Republic Day',
          description: 'A national holiday on June 2 celebrating the birth of the Italian Republic with parades, ceremonies, and concerts.',
          location: 'Rome',
          imageUrl: 'https://www.laquilablog.it/wp-content/uploads/2022/05/Altare-patria.jpg'
        },
        {
          name: 'Venice Film Festival',
          description: 'One of the world\'s oldest and most prestigious film festivals, held annually in Venice and showcasing international cinema and premieres.',
          location: 'Venice',
          imageUrl: 'https://static01.nyt.com/images/2020/09/05/arts/04notebook-venice1/merlin_176568831_e947c0ec-75ef-4f51-ba28-88329218e94a-superJumbo.jpg'
        },
        {
          name: 'Medieval Festival',
          description: 'A historical reenactment event in Siena that brings medieval traditions to life with costumes, markets, and performances.',
          location: 'Siena',
          imageUrl: 'd3dqioy2sca31t.cloudfront.net/Projects/cms/production/000/022/099/original/429d65fb0615c4745a909841aadcadb0/italy-siena-palio-parade-060117-rs.jpg'
        },
        {
          name: 'Palio di Siena',
          description: 'The spirited horse race in Siena that pits local contrade against each other in a centuries-old tradition of competition and celebration.',
          location: 'Siena',
          imageUrl: 'https://americadomani.com/wp-content/uploads/2022/08/palio.jpg'
        },
        {
          name: 'Easter Celebrations',
          description: 'A day of religious observance and communal dining, featuring special dishes and sweets that mark the resurrection celebration.',
          location: 'Nationwide',
          imageUrl: 'https://www.finedininglovers.com/sites/default/files/article_content_images/Original_16898_StockFood-11442924-HiRes.jpg'
        },
        {
          name: 'Feast of the Seven Fishes',
          description: 'A cherished family gathering marked by a sumptuous seafood feast and a series of symbolic courses on Christmas Eve.',
          location: 'Southern Italy',
          imageUrl: 'https://cdn.britannica.com/74/263574-050-72B13119/Feast-of-the-Seven-Fishes-an-Italian-dinner-tradition-on-Christmas-Eve-Italian-American-Southern-Italy-Also-called-La-Vigilia-related-to-la-Viglia-di-Natale-the-midnight-birth-of-Jesus-Catholic-Catholicism-Christian-Christianity.jpg'
        },
        {
          name: 'St. Joseph\'s Day',
          description: 'Celebrated on March 19, this festival honors St. Joseph with altars, traditional pastries, and community festivities across Italy.',
          location: 'Nationwide',
          imageUrl: 'https://www.thelazyitalian.com/wp-content/uploads/2023/03/St.-Josephs-Table-2-1024x639.jpg'
        },
        {
          name: 'Wine Harvest Festival',
          description: 'Autumn celebrations in Italy\'s wine regions, where communities honor the grape harvest with tastings, parades, and local culinary delights.',
          location: 'Various Wine Regions',
          imageUrl: 'https://www.greatwinecapitals.com/wp-content/uploads/2022/01/Bilbao-Rioja-San-Mateo-Fiesta-Vendimia.jpg'
        }
      ]
    }
  },
  {
    name: 'Canada',
    flag: '🇨🇦',
    latitude: 56.1304,
    longitude: -106.3468,
    description: 'A multicultural nation known for its stunning natural landscapes, diverse cities, and rich indigenous heritage.',
    imageUrl: 'https://images.unsplash.com/photo-1503614472-8c93d56e92ce',
    details: {
      traditions: [
        'Indigenous Pow Wows',
        'Maple Syrup Harvesting',
        'Ice Hockey Culture',
        'Winter Carnival',
        'First Nations Art',
        'Thanksgiving Celebration',
        'Hockey Night in Canada',
        'Canada Day Celebration',
        'Winter Festivals',
        'Polar Bear Swim'
      ],
      cuisines: [
        'Poutine',
        'Maple Syrup Dishes',
        'Butter Tarts',
        'Montreal-Style Bagels',
        'Canadian Bacon',
        'Thanksgiving Turkey'
      ],
      landmarks: [
        'Niagara Falls',
        'CN Tower',
        'Banff National Park',
        'Old Quebec City',
        'Parliament Hill'
      ],
      festivals: [
        'Calgary Stampede',
        'Quebec Winter Carnival',
        'Toronto International Film Festival',
        'Montreal Jazz Festival',
        'Ottawa Tulip Festival',
        'Canada Day',
        'Winter Festivals',
        'Polar Bear Swim',
        'National Indigenous Peoples Day',
        'Vancouver Pride',
        'Royal St. John\'s Regatta'
      ]
    }
  },
  {
    name: 'Nigeria',
    flag: '🇳🇬',
    latitude: 9.0820,
    longitude: 8.6753,
    description: 'A vibrant West African nation known for its diverse ethnic groups, rich cultural traditions, and dynamic arts scene.',
    imageUrl: 'https://images.unsplash.com/photo-1633321088355-d0f81134ca3b',
    details: {
      traditions: [
        'New Yam Festival',
        'Eyo Festival',
        'Traditional Weddings',
        'Cultural Day Celebrations'
      ],
      cuisines: [
        'Jollof Rice',
        'Egusi Soup',
        'Suya',
        'Pounded Yam',
        'Akara'
      ],
      landmarks: [
        'Zuma Rock',
        'Olumo Rock',
        'National Mosque',
        'Lekki Conservation Centre',
        'First Storey Building'
      ],
      festivals: [
        'Lagos Carnival',
        'Osun-Osogbo Festival',
        'Durbar Festival',
        'Eid Celebrations',
        'Christmas Celebrations',
        'Independence Day'
      ]
    }
  },
  {
    name: 'United States',
    flag: '🇺🇸',
    latitude: 37.0902,
    longitude: -95.7129,
    description: 'A diverse nation known for its cultural melting pot, technological innovation, and influential entertainment industry.',
    imageUrl: 'https://images.unsplash.com/photo-1501594907352-04cda38ebc29',
    details: {
      traditions: [
        'Thanksgiving',
        'Memorial Day',
        'Independence Day',
        'Super Bowl Sunday',
        'Halloween',
        'Mardi Gras',
        'Labor Day',
        'Juneteenth',
        'Veterans Day',
        'New Year\'s Eve'
      ],
      cuisines: [
        'BBQ',
        'Apple Pie',
        'Hot Dogs',
        'Hamburgers',
        'Southern Soul Food',
        'Tex-Mex',
        'New England Seafood',
        'Deep Dish Pizza',
        'Cajun/Creole',
        'Buffalo Wings'
      ],
      landmarks: [
        'Statue of Liberty',
        'Grand Canyon',
        'Mount Rushmore',
        'Golden Gate Bridge',
        'Empire State Building',
        'National Mall',
        'Hollywood Sign',
        'Yellowstone',
        'French Quarter',
        'Times Square'
      ],
      festivals: [
        'Thanksgiving',
        'Independence Day',
        'Super Bowl Sunday',
        'Halloween',
        'Mardi Gras',
        'Labor Day',
        'Juneteenth',
        'Veterans Day',
        'New Year\'s Eve',
        'Memorial Day'
      ]
    }
  }
];

export const findCountry = (searchTerm: string) => {
  const normalizedSearch = searchTerm.toLowerCase().trim();
  return countries.find(country => 
    country.name.toLowerCase().includes(normalizedSearch) ||
    country.details.traditions.some(t => t.toLowerCase().includes(normalizedSearch)) ||
    country.details.cuisines.some(c => typeof c === 'string' ? c.toLowerCase().includes(normalizedSearch) : c.name.toLowerCase().includes(normalizedSearch)) ||
    country.details.landmarks.some(l => typeof l === 'string' ? l.toLowerCase().includes(normalizedSearch) : l.name.toLowerCase().includes(normalizedSearch)) ||
    country.details.festivals.some(f => typeof f === 'string' ? f.toLowerCase().includes(normalizedSearch) : f.name.toLowerCase().includes(normalizedSearch))
  );
};