import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  User,
  MapPin,
  Mail,
  Phone,
  Globe2,
  Users,
  Settings,
  Heart,
  MessageSquare,
  Share2,
  Award,
  Star,
  Trophy,
  Medal
} from 'lucide-react';
import { getProfile } from '../lib/profile';
import { getUserPosts, followUser, unfollowUser, getFollowers, getFollowing } from '../lib/social';
import { useAuth } from '../lib/auth';
import { supabase } from '../lib/supabase';

interface Badge {
  id: string;
  name: string;
  description: string;
  icon_url: string;
  points: number;
  awarded_at: string;
}

interface Achievement {
  id: string;
  achievement_type: string;
  achievement_data: Record<string, any>;
  earned_at: string;
}

export function Profile() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [profile, setProfile] = useState<any>(null);
  const [posts, setPosts] = useState<any[]>([]);
  const [followers, setFollowers] = useState<any[]>([]);
  const [following, setFollowing] = useState<any[]>([]);
  const [badges, setBadges] = useState<Badge[]>([]);
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [activeTab, setActiveTab] = useState<'posts' | 'followers' | 'following' | 'badges'>('posts');

  useEffect(() => {
    if (!id) return;
    loadProfile();
  }, [id]);

  async function loadProfile() {
    try {
      const [profileData, postsData, followersData, followingData, badgesData, achievementsData] = await Promise.all([
        getProfile(id!),
        getUserPosts(id!),
        getFollowers(id!),
        getFollowing(id!),
        loadBadges(),
        loadAchievements()
      ]);

      setProfile(profileData);
      setPosts(postsData);
      setFollowers(followersData);
      setFollowing(followingData);
      setBadges(badgesData);
      setAchievements(achievementsData);

      // Check if current user is following this profile
      setIsFollowing(followersData.some((f: any) => f.id === user?.id));
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function loadBadges() {
    try {
      const { data, error } = await supabase
        .from('user_badges')
        .select(`
          *,
          badges (
            id,
            name,
            description,
            icon_url,
            points
          )
        `)
        .eq('user_id', id);

      if (error) throw error;
      return data?.map(ub => ({
        ...ub.badges,
        awarded_at: ub.awarded_at
      })) || [];
    } catch (err) {
      console.error('Error loading badges:', err);
      return [];
    }
  }

  async function loadAchievements() {
    try {
      const { data, error } = await supabase
        .from('user_achievements')
        .select('*')
        .eq('user_id', id)
        .order('earned_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (err) {
      console.error('Error loading achievements:', err);
      return [];
    }
  }

  const handleFollow = async () => {
    if (!user) {
      navigate('/signin');
      return;
    }

    try {
      if (isFollowing) {
        await unfollowUser(id!);
        setIsFollowing(false);
        setProfile(prev => ({
          ...prev,
          followers_count: (prev.followers_count || 0) - 1
        }));
      } else {
        await followUser(id!);
        setIsFollowing(true);
        setProfile(prev => ({
          ...prev,
          followers_count: (prev.followers_count || 0) + 1
        }));
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !profile) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
          {error || 'Profile not found'}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="relative h-48 bg-gradient-to-r from-blue-500 to-purple-500">
          {user?.id === id && (
            <button
              onClick={() => navigate('/settings')}
              className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
            >
              <Settings className="w-5 h-5 text-gray-600" />
            </button>
          )}
        </div>

        <div className="relative px-6 pb-6">
          <div className="flex flex-col items-center -mt-16">
            <img
              src={profile.avatar_url || `https://ui-avatars.com/api/?name=${profile.username || 'User'}&background=random`}
              alt={profile.username || 'User avatar'}
              className="w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover"
            />
            
            <h1 className="mt-4 text-2xl font-bold text-gray-900">
              {profile.full_name || profile.username}
            </h1>
            
            {profile.username && (
              <p className="text-gray-600">@{profile.username}</p>
            )}

            {profile.is_verified && (
              <div className="mt-2 flex items-center text-blue-600">
                <Award className="w-5 h-5 mr-1" />
                <span className="text-sm font-medium">Verified User</span>
              </div>
            )}

            <div className="mt-4 flex items-center space-x-8">
              <div className="text-center">
                <div className="text-xl font-bold text-gray-900">{posts.length}</div>
                <div className="text-sm text-gray-500">Posts</div>
              </div>
              <div className="text-center">
                <div className="text-xl font-bold text-gray-900">{profile.followers_count || 0}</div>
                <div className="text-sm text-gray-500">Followers</div>
              </div>
              <div className="text-center">
                <div className="text-xl font-bold text-gray-900">{profile.following_count || 0}</div>
                <div className="text-sm text-gray-500">Following</div>
              </div>
              <div className="text-center">
                <div className="text-xl font-bold text-gray-900">{profile.points || 0}</div>
                <div className="text-sm text-gray-500">Points</div>
              </div>
            </div>

            {user && user.id !== id && (
              <button
                onClick={handleFollow}
                className={`mt-4 px-6 py-2 rounded-full font-medium ${
                  isFollowing
                    ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                    : 'bg-blue-600 text-white hover:bg-blue-700'
                }`}
              >
                {isFollowing ? 'Following' : 'Follow'}
              </button>
            )}

            {profile.bio && (
              <p className="mt-4 text-gray-600 text-center max-w-lg">{profile.bio}</p>
            )}

            <div className="mt-4 flex flex-wrap justify-center gap-4 text-gray-600">
              {profile.location && (
                <div className="flex items-center">
                  <MapPin className="w-4 h-4 mr-1" />
                  {profile.location}
                </div>
              )}
              {profile.email && (
                <div className="flex items-center">
                  <Mail className="w-4 h-4 mr-1" />
                  {profile.email}
                </div>
              )}
              {profile.phone_number && (
                <div className="flex items-center">
                  <Phone className="w-4 h-4 mr-1" />
                  {profile.phone_number}
                </div>
              )}
              {profile.website && (
                <div className="flex items-center">
                  <Globe2 className="w-4 h-4 mr-1" />
                  <a href={profile.website} className="text-blue-600 hover:underline">
                    {profile.website}
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="mt-8">
            <div className="border-b border-gray-200">
              <nav className="flex justify-center -mb-px">
                <button
                  onClick={() => setActiveTab('posts')}
                  className={`px-6 py-3 border-b-2 font-medium text-sm ${
                    activeTab === 'posts'
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                >
                  Posts
                </button>
                <button
                  onClick={() => setActiveTab('badges')}
                  className={`px-6 py-3 border-b-2 font-medium text-sm ${
                    activeTab === 'badges'
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                >
                  Badges & Achievements
                </button>
                <button
                  onClick={() => setActiveTab('followers')}
                  className={`px-6 py-3 border-b-2 font-medium text-sm ${
                    activeTab === 'followers'
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                >
                  Followers
                </button>
                <button
                  onClick={() => setActiveTab('following')}
                  className={`px-6 py-3 border-b-2 font-medium text-sm ${
                    activeTab === 'following'
                      ? 'border-blue-600 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                >
                  Following
                </button>
              </nav>
            </div>

            <div className="mt-6">
              {activeTab === 'posts' && (
                <div className="space-y-6">
                  {posts.map((post) => (
                    <div key={post.id} className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                      <div className="p-4">
                        <p className="text-gray-800 mb-4">{post.content}</p>

                        {post.media_urls?.length > 0 && (
                          <div className="mb-4">
                            <img
                              src={post.media_urls[0]}
                              alt="Post content"
                              className="w-full rounded-lg"
                            />
                          </div>
                        )}

                        <div className="flex items-center justify-between text-gray-500 text-sm">
                          <div className="flex items-center space-x-4">
                            <div className="flex items-center">
                              <Heart className={`w-5 h-5 mr-1 ${post.has_liked ? 'text-red-600 fill-current' : ''}`} />
                              <span>{post.likes_count || 0}</span>
                            </div>
                            <div className="flex items-center">
                              <MessageSquare className="w-5 h-5 mr-1" />
                              <span>{post.comments_count || 0}</span>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Share2 className="w-5 h-5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {posts.length === 0 && (
                    <div className="text-center py-12">
                      <User className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">No posts yet</h3>
                      <p className="text-gray-600">This user hasn't shared any posts</p>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 'badges' && (
                <div className="space-y-8">
                  {/* Badges Section */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                      <Trophy className="w-5 h-5 text-yellow-500 mr-2" />
                      Badges
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {badges.map((badge) => (
                        <div
                          key={badge.id}
                          className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 flex items-center space-x-4"
                        >
                          <img
                            src={badge.icon_url}
                            alt={badge.name}
                            className="w-12 h-12"
                          />
                          <div>
                            <h4 className="font-medium text-gray-900">{badge.name}</h4>
                            <p className="text-sm text-gray-500">{badge.description}</p>
                            <div className="flex items-center mt-1 text-sm text-gray-400">
                              <Star className="w-4 h-4 mr-1" />
                              {badge.points} points
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Achievements Section */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                      <Medal className="w-5 h-5 text-blue-500 mr-2" />
                      Achievements
                    </h3>
                    <div className="space-y-4">
                      {achievements.map((achievement) => (
                        <div
                          key={achievement.id}
                          className="bg-white rounded-lg shadow-sm border border-gray-200 p-4"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              <Award className="w-6 h-6 text-blue-600" />
                              <div>
                                <h4 className="font-medium text-gray-900">
                                  {achievement.achievement_type.split('_').map(word => 
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                  ).join(' ')}
                                </h4>
                                <p className="text-sm text-gray-500">
                                  Earned {new Date(achievement.earned_at).toLocaleDateString()}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'followers' && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {followers.map((follower) => (
                    <div
                      key={follower.id}
                      onClick={() => navigate(`/profile/${follower.id}`)}
                      className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100"
                    >
                      <img
                        src={follower.avatar_url || `https://ui-avatars.com/api/?name=${follower.username || 'User'}`}
                        alt={follower.username}
                        className="w-12 h-12 rounded-full"
                      />
                      <div>
                        <div className="font-medium text-gray-900">{follower.full_name || follower.username}</div>
                        {follower.username && (
                          <div className="text-sm text-gray-500">@{follower.username}</div>
                        )}
                      </div>
                    </div>
                  ))}

                  {followers.length === 0 && (
                    <div className="col-span-2 text-center py-12">
                      <Users className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">No followers yet</h3>
                      <p className="text-gray-600">This user doesn't have any followers</p>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 'following' && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {following.map((follow) => (
                    <div
                      key={follow.id}
                      onClick={() => navigate(`/profile/${follow.id}`)}
                      className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100"
                    >
                      <img
                        src={follow.avatar_url || `https://ui-avatars.com/api/?name=${follow.username || 'User'}`}
                        alt={follow.username}
                        className="w-12 h-12 rounded-full"
                      />
                      <div>
                        <div className="font-medium text-gray-900">{follow.full_name || follow.username}</div>
                        {follow.username && (
                          <div className="text-sm text-gray-500">@{follow.username}</div>
                        )}
                      </div>
                    </div>
                  ))}

                  {following.length === 0 && (
                    <div className="col-span-2 text-center py-12">
                      <Users className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">Not following anyone</h3>
                      <p className="text-gray-600">This user isn't following anyone yet</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}