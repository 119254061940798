import { useState, useCallback, useRef, useEffect } from 'react';
import { getFeedPosts, createPost, likePost, unlikePost, type UserPost } from '../social';
import { processLargeArray } from '../recursion';

const POSTS_PER_PAGE = 10;
const LOAD_MORE_THRESHOLD = 0.8;

export function useInfinitePosts() {
  const [posts, setPosts] = useState<UserPost[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const loadingRef = useRef(false);
  const pageRef = useRef(0);
  const observerRef = useRef<IntersectionObserver | null>(null);
  
  const lastPostRef = useCallback((node: HTMLDivElement | null) => {
    if (loading || !hasMore) return;
    
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && entries[0].intersectionRatio >= LOAD_MORE_THRESHOLD) {
        loadMorePosts();
      }
    }, {
      threshold: LOAD_MORE_THRESHOLD
    });

    if (node) {
      observerRef.current.observe(node);
    }
  }, [loading, hasMore]);

  const loadPosts = useCallback(async (refresh = false) => {
    if (loadingRef.current && !refresh) return;
    
    try {
      loadingRef.current = true;
      setLoading(true);
      setError(null);

      if (refresh) {
        pageRef.current = 0;
        setHasMore(true);
      }

      const data = await getFeedPosts();
      
      // Process posts in chunks to avoid stack overflow
      const newPosts: UserPost[] = [];
      processLargeArray(data || [], (post) => {
        newPosts.push(post);
      });
      
      setPosts(prev => refresh ? newPosts : [...prev, ...newPosts]);
      setHasMore(newPosts.length >= POSTS_PER_PAGE);
      pageRef.current += 1;
    } catch (err: any) {
      console.error('Error loading posts:', err);
      setError(err.message);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, []);

  const loadMorePosts = useCallback(() => {
    if (!hasMore || loading) return;
    loadPosts();
  }, [hasMore, loading, loadPosts]);

  const handleCreatePost = useCallback(async (content: string, mediaUrls: string[]) => {
    try {
      setError(null);
      const type = mediaUrls.length > 0 ? 'image' : 'text';
      
      const newPost = await createPost({
        content,
        media_urls: mediaUrls,
        type
      });

      setPosts(prev => [newPost, ...prev]);
    } catch (err: any) {
      console.error('Error creating post:', err);
      setError(err.message);
    }
  }, []);

  const handleLike = useCallback(async (postId: string, hasLiked: boolean) => {
    try {
      setError(null);
      
      // Optimistically update UI
      setPosts(prev =>
        prev.map(post =>
          post.id === postId
            ? {
                ...post,
                likes_count: hasLiked ? (post.likes_count || 0) - 1 : (post.likes_count || 0) + 1,
                has_liked: !hasLiked
              }
            : post
        )
      );

      // Perform actual like/unlike
      if (hasLiked) {
        await unlikePost(postId);
      } else {
        await likePost(postId);
      }
    } catch (err: any) {
      console.error('Error toggling like:', err);
      setError(err.message);
      // Revert optimistic update on error
      setPosts(prev =>
        prev.map(post =>
          post.id === postId
            ? {
                ...post,
                likes_count: hasLiked ? (post.likes_count || 0) + 1 : (post.likes_count || 0) - 1,
                has_liked: hasLiked
              }
            : post
        )
      );
    }
  }, []);

  const handleShare = useCallback(async (post: UserPost) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Post by ${post.profiles?.username || 'Anonymous'}`,
          text: post.content,
          url: post.share_url
        });
      } else {
        await navigator.clipboard.writeText(post.share_url || window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  }, []);

  // Cleanup observer on unmount
  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return {
    posts,
    loading,
    error,
    hasMore,
    loadPosts,
    loadMorePosts,
    handleCreatePost,
    handleLike,
    handleShare,
    lastPostRef
  };
}