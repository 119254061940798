import { supabase } from './supabase';

export interface ChatMessage {
  id: string;
  content: string;
  image_url?: string;
  user_id: string;
  created_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
  likes?: number;
  comments?: number;
  has_liked?: boolean;
}

export interface Comment {
  id: string;
  content: string;
  user_id: string;
  created_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
}

// Initialize sample chat messages
export async function initializeSampleMessages() {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const { data: existingMessages } = await supabase
      .from('chat_messages')
      .select('id')
      .limit(1);

    // Only initialize if no messages exist
    if (!existingMessages || existingMessages.length === 0) {
      const sampleMessages = [
        {
          content: 'Welcome to our cultural exchange community! Feel free to share your experiences and ask questions.',
          user_id: user.id,
          image_url: 'https://source.unsplash.com/1600x900/?culture,community'
        },
        {
          content: 'Has anyone tried making traditional Japanese sushi at home? I\'d love to hear your experiences!',
          user_id: user.id
        }
      ];

      const { error } = await supabase
        .from('chat_messages')
        .insert(sampleMessages);

      if (error) throw error;
    }
  } catch (error) {
    console.error('Error initializing sample messages:', error);
  }
}

export async function getMessages() {
  try {
    const { data: { user } } = await supabase.auth.getUser();

    const { data, error } = await supabase
      .from('chat_messages')
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        ),
        likes:message_likes(count),
        comments:message_comments(count)
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;

    if (user) {
      const { data: userLikes } = await supabase
        .from('message_likes')
        .select('message_id')
        .eq('user_id', user.id);

      const likedMessageIds = new Set(userLikes?.map(like => like.message_id));
      
      return (data || []).map(message => ({
        ...message,
        likes: message.likes[0]?.count || 0,
        comments: message.comments[0]?.count || 0,
        has_liked: likedMessageIds.has(message.id)
      }));
    }

    return (data || []).map(message => ({
      ...message,
      likes: message.likes[0]?.count || 0,
      comments: message.comments[0]?.count || 0,
      has_liked: false
    }));
  } catch (error) {
    console.error('Error getting messages:', error);
    return [];
  }
}

export async function createMessage(content: string, image_url?: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('chat_messages')
      .insert([{
        content,
        image_url,
        user_id: user.id
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating message:', error);
    throw error;
  }
}

export async function toggleLike(messageId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data: existing } = await supabase
      .from('message_likes')
      .select()
      .eq('message_id', messageId)
      .eq('user_id', user.id)
      .single();

    if (existing) {
      const { error } = await supabase
        .from('message_likes')
        .delete()
        .eq('message_id', messageId)
        .eq('user_id', user.id);

      if (error) throw error;
      return false;
    } else {
      const { error } = await supabase
        .from('message_likes')
        .insert([{ message_id: messageId, user_id: user.id }]);

      if (error) throw error;
      return true;
    }
  } catch (error) {
    console.error('Error toggling like:', error);
    throw error;
  }
}

export async function getComments(messageId: string) {
  try {
    const { data, error } = await supabase
      .from('message_comments')
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        )
      `)
      .eq('message_id', messageId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error getting comments:', error);
    return [];
  }
}

export async function createComment(messageId: string, content: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('message_comments')
      .insert([{
        message_id: messageId,
        content,
        user_id: user.id
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating comment:', error);
    throw error;
  }
}