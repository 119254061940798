import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Shield, 
  Plus, 
  Edit2, 
  Trash2, 
  Search, 
  Filter, 
  ArrowUp,
  ArrowDown,
  AlertCircle,
  Image as ImageIcon,
  Video,
  Globe2,
  MapPin,
  Utensils,
  Music,
  Package
} from 'lucide-react';
import { useAuth } from '../lib/auth';
import { supabase } from '../lib/supabase';
import { MediaUpload } from '../components/MediaUpload';

interface ContentItem {
  id: string;
  title: string;
  type: 'recipe' | 'cultural_point' | 'post' | 'product';
  status: 'published' | 'draft' | 'archived';
  media_urls?: string[];
  created_at: string;
  updated_at: string;
}

interface CulturalPointForm {
  title: string;
  description: string;
  country: string;
  type: 'landmark' | 'cuisine' | 'tradition' | 'festival';
  location: string;
  latitude?: number;
  longitude?: number;
  media_urls: string[];
}

const initialFormData: CulturalPointForm = {
  title: '',
  description: '',
  country: '',
  type: 'landmark',
  location: '',
  media_urls: []
};

const getTypeIcon = (type: string) => {
  switch (type) {
    case 'recipe':
      return <Utensils className="w-5 h-5 text-green-600" />;
    case 'cultural_point':
      return <MapPin className="w-5 h-5 text-blue-600" />;
    case 'post':
      return <Globe2 className="w-5 h-5 text-purple-600" />;
    case 'product':
      return <Package className="w-5 h-5 text-orange-600" />;
    default:
      return <Globe2 className="w-5 h-5 text-gray-600" />;
  }
};

const getStatusBadgeColor = (status: string) => {
  switch (status) {
    case 'published':
      return 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400';
    case 'draft':
      return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400';
    case 'archived':
      return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';
    default:
      return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';
  }
};

export function Admin() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [content, setContent] = useState<ContentItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<'all' | ContentItem['type']>('all');
  const [selectedStatus, setSelectedStatus] = useState<'all' | 'published' | 'draft' | 'archived'>('all');
  const [sortField, setSortField] = useState<'title' | 'created_at'>('created_at');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [showCulturalForm, setShowCulturalForm] = useState(false);
  const [formData, setFormData] = useState<CulturalPointForm>(initialFormData);
  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    checkAdminAccess();
    loadContent();
    loadCountries();
  }, []);

  async function checkAdminAccess() {
    if (!user) {
      navigate('/signin');
      return;
    }

    const { data: profile } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', user.id)
      .single();

    if (!profile || profile.role !== 'admin') {
      navigate('/');
      return;
    }
  }

  async function loadContent() {
    try {
      const [recipes, culturalPoints, posts, products] = await Promise.all([
        supabase
          .from('recipes')
          .select('id, title, media_urls, created_at, updated_at')
          .order('created_at', { ascending: false }),
        supabase
          .from('cultural_points')
          .select('id, title, media_urls, created_at, updated_at')
          .order('created_at', { ascending: false }),
        supabase
          .from('user_posts')
          .select('id, content, media_urls, created_at, updated_at')
          .order('created_at', { ascending: false }),
        supabase
          .from('products')
          .select('id, title, images, created_at, updated_at')
          .order('created_at', { ascending: false })
      ]);

      const allContent: ContentItem[] = [
        ...(recipes.data || []).map(r => ({ 
          ...r, 
          type: 'recipe' as const, 
          status: 'published',
          media_urls: r.media_urls || []
        })),
        ...(culturalPoints.data || []).map(c => ({ 
          ...c, 
          type: 'cultural_point' as const, 
          status: 'published',
          media_urls: c.media_urls || []
        })),
        ...(posts.data || []).map(p => ({ 
          ...p, 
          title: p.content,
          type: 'post' as const, 
          status: 'published',
          media_urls: p.media_urls || []
        })),
        ...(products.data || []).map(p => ({
          ...p,
          media_urls: p.images,
          type: 'product' as const,
          status: p.status || 'published'
        }))
      ];

      setContent(allContent);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function loadCountries() {
    try {
      const { data, error } = await supabase
        .from('countries')
        .select('name')
        .order('name');

      if (error) throw error;
      setCountries(data.map(c => c.name));
    } catch (err: any) {
      console.error('Error loading countries:', err);
    }
  }

  const handleDelete = async (item: ContentItem) => {
    try {
      const table = item.type === 'recipe' ? 'recipes' 
        : item.type === 'cultural_point' ? 'cultural_points'
        : item.type === 'product' ? 'products'
        : 'user_posts';

      const { error } = await supabase
        .from(table)
        .delete()
        .eq('id', item.id);

      if (error) throw error;

      setContent(prev => prev.filter(i => i.id !== item.id));
      setShowDeleteConfirm(null);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleSubmitCulturalPoint = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError(null);
      const { error } = await supabase
        .from('cultural_points')
        .insert([{
          ...formData,
          user_id: user?.id
        }]);

      if (error) throw error;

      setShowCulturalForm(false);
      setFormData(initialFormData);
      loadContent();
    } catch (err: any) {
      setError(err.message);
    }
  };

  const getMediaIcon = (url: string) => {
    const isVideo = url.match(/\.(mp4|webm|ogg)$/i);
    return isVideo ? <Video className="w-4 h-4" /> : <ImageIcon className="w-4 h-4" />;
  };

  const filteredContent = content.filter(item => {
    const matchesSearch = item.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = selectedType === 'all' || item.type === selectedType;
    const matchesStatus = selectedStatus === 'all' || item.status === selectedStatus;
    return matchesSearch && matchesType && matchesStatus;
  }).sort((a, b) => {
    const compareValue = sortField === 'title'
      ? a.title.localeCompare(b.title)
      : new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    return sortOrder === 'asc' ? compareValue : -compareValue;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <Shield className="w-8 h-8 text-blue-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900">Admin Dashboard</h1>
        </div>

        <div className="flex space-x-4">
          <button
            onClick={() => setShowCulturalForm(true)}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Cultural Point
          </button>
          <button
            onClick={() => navigate('/recipes/new')}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Recipe
          </button>
        </div>
      </div>

      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-md p-4 flex items-start">
          <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 mr-3 flex-shrink-0" />
          <p className="text-red-600">{error}</p>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-4 mb-6">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search content..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Filter className="w-5 h-5 text-gray-400" />
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value as typeof selectedType)}
                className="border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="all">All Types</option>
                <option value="recipe">Recipes</option>
                <option value="cultural_point">Cultural Points</option>
                <option value="post">Posts</option>
                <option value="product">Products</option>
              </select>
            </div>

            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value as typeof selectedStatus)}
              className="border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="all">All Status</option>
              <option value="published">Published</option>
              <option value="draft">Draft</option>
              <option value="archived">Archived</option>
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => {
                      if (sortField === 'title') {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                      } else {
                        setSortField('title');
                        setSortOrder('asc');
                      }
                    }}
                    className="flex items-center space-x-1"
                  >
                    <span>Title</span>
                    {sortField === 'title' && (
                      sortOrder === 'asc' ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />
                    )}
                  </button>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Media
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => {
                      if (sortField === 'created_at') {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                      } else {
                        setSortField('created_at');
                        setSortOrder('desc');
                      }
                    }}
                    className="flex items-center space-x-1"
                  >
                    <span>Created</span>
                    {sortField === 'created_at' && (
                      sortOrder === 'asc' ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />
                    )}
                  </button>
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredContent.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        {item.media_urls && item.media_urls.length > 0 ? (
                          <img
                            src={item.media_urls[0]}
                            alt={item.title}
                            className="h-10 w-10 rounded-md object-cover"
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-md bg-gray-100 flex items-center justify-center">
                            {getTypeIcon(item.type)}
                          </div>
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{item.title}</div>
                        <div className="text-sm text-gray-500">{item.type.replace('_', ' ')}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      {item.type.replace('_', ' ')}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-1">
                      {item.media_urls?.map((url, index) => (
                        <span key={index} title={url} className="text-gray-500">
                          {getMediaIcon(url)}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(item.status)}`}>
                      {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(item.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex items-center justify-end space-x-2">
                      <button
                        onClick={() => navigate(`/${item.type === 'recipe' ? 'recipes' : item.type === 'cultural_point' ? 'cultural-points' : item.type === 'product' ? 'products' : 'posts'}/${item.id}/edit`)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Edit2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => setShowDeleteConfirm(item.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredContent.length === 0 && (
          <div className="text-center py-12">
            <Shield className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">No content found</h3>
            <p className="text-gray-600">
              {searchTerm
                ? "We couldn't find any content matching your search"
                : "There's no content yet"}
            </p>
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Confirm Delete</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this item? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (showDeleteConfirm) {
                    handleDelete(content.find(item => item.id === showDeleteConfirm)!);
                  }
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Cultural Point Form Modal */}
      {showCulturalForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">Add Cultural Point</h2>
            
            <form onSubmit={handleSubmitCulturalPoint} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  required
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  required
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  rows={3}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Country
                  </label>
                  <select
                    required
                    value={formData.country}
                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="">Select a country</option>
                    {countries.map(country => (
                      <option key={country} value={country}>{country}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Type
                  </label>
                  <select
                    required
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value as CulturalPointForm['type'] })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="landmark">Landmark</option>
                    <option value="cuisine">Cuisine</option>
                    <option value="tradition">Tradition</option>
                    <option value="festival">Festival</option>
                  </select>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location
                </label>
                <input
                  type="text"
                  required
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="e.g., Tokyo, Japan"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Latitude (optional)
                  </label>
                  <input
                    type="number"
                    step="any"
                    value={formData.latitude || ''}
                    onChange={(e) => setFormData({ ...formData, latitude: parseFloat(e.target.value) || undefined })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="e.g., 35.6762"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Longitude (optional)
                  </label>
                  <input
                    type="number"
                    step="any"
                    value={formData.longitude || ''}
                    onChange={(e) => setFormData({ ...formData, longitude: parseFloat(e.target.value) || undefined })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="e.g., 139.6503"
                  />
                </div>
              </div>

              <MediaUpload
                mediaUrls={formData.media_urls}
                onMediaChange={(urls) => setFormData({ ...formData, media_urls: urls })}
                maxFiles={5}
                allowedTypes={['image', 'video']}
              />

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowCulturalForm(false);
                    setFormData(initialFormData);
                  }}
                  className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Add Cultural Point
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}