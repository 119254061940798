import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChefHat, Globe2, Clock, User, ArrowLeft, Heart, Share2, Printer } from 'lucide-react';
import { getRecipe, toggleFavorite } from '../lib/recipes';
import { useAuth } from '../lib/auth';

export function RecipeDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [recipe, setRecipe] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isTogglingFavorite, setIsTogglingFavorite] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (!id) return;
    
    async function loadRecipe() {
      try {
        const data = await getRecipe(id);
        setRecipe(data);
        setIsFavorited(data.favorites?.some((f: any) => f.user_id === user?.id) ?? false);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    loadRecipe();
  }, [id, user?.id]);

  const handleToggleFavorite = async () => {
    if (!user) {
      navigate('/signin');
      return;
    }

    if (!id || isTogglingFavorite) return;

    try {
      setIsTogglingFavorite(true);
      const isFavorite = await toggleFavorite(id);
      setIsFavorited(isFavorite);
    } catch (err) {
      console.error('Error toggling favorite:', err);
    } finally {
      setIsTogglingFavorite(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: recipe.title,
          text: recipe.description,
          url: window.location.href,
        });
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert('Link copied to clipboard!');
    }
  };

  const nextImage = () => {
    if (recipe?.media_urls?.length > 0) {
      setCurrentImageIndex((prev) => 
        prev === recipe.media_urls.length - 1 ? 0 : prev + 1
      );
    }
  };

  const previousImage = () => {
    if (recipe?.media_urls?.length > 0) {
      setCurrentImageIndex((prev) => 
        prev === 0 ? recipe.media_urls.length - 1 : prev - 1
      );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !recipe) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
          {error || 'Recipe not found'}
        </div>
        <button
          onClick={() => navigate('/recipes')}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <ArrowLeft className="w-4 h-4 mr-1" />
          Back to Recipes
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <button
        onClick={() => navigate('/recipes')}
        className="flex items-center text-gray-600 hover:text-gray-800 mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-1" />
        Back to Recipes
      </button>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="relative h-96">
          {recipe.media_urls && recipe.media_urls.length > 0 ? (
            <>
              <img
                src={recipe.media_urls[currentImageIndex]}
                alt={recipe.title}
                className="w-full h-full object-cover"
              />
              {recipe.media_urls.length > 1 && (
                <>
                  <button
                    onClick={previousImage}
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full hover:bg-black/70"
                  >
                    <ArrowLeft className="w-6 h-6" />
                  </button>
                  <button
                    onClick={nextImage}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full hover:bg-black/70"
                  >
                    <ArrowLeft className="w-6 h-6 transform rotate-180" />
                  </button>
                </>
              )}
            </>
          ) : (
            <img
              src={`https://source.unsplash.com/1600x900/?${recipe.title.split(' ').join(',')},food`}
              alt={recipe.title}
              className="w-full h-full object-cover"
            />
          )}
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            <div className="flex flex-wrap items-center gap-4 text-sm mb-2">
              <div className="flex items-center">
                <Globe2 className="w-4 h-4 mr-1" />
                {recipe.country}
              </div>
              <div className="flex items-center">
                <User className="w-4 h-4 mr-1" />
                {recipe.profiles?.username || 'Anonymous'}
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-1" />
                {new Date(recipe.created_at).toLocaleDateString()}
              </div>
            </div>
            <h1 className="text-4xl font-bold">{recipe.title}</h1>
          </div>
        </div>

        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <p className="text-gray-600 max-w-2xl">{recipe.description}</p>
            <div className="flex gap-2">
              <button
                onClick={handleToggleFavorite}
                disabled={isTogglingFavorite}
                className={`p-2 transition-colors ${
                  isFavorited ? 'text-red-600' : 'text-gray-600 hover:text-red-600'
                } ${isTogglingFavorite ? 'opacity-50 cursor-not-allowed' : ''}`}
                title={isFavorited ? 'Remove from favorites' : 'Save to favorites'}
              >
                <Heart className={`w-5 h-5 ${isFavorited ? 'fill-current' : ''}`} />
              </button>
              <button
                onClick={handleShare}
                className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                title="Share recipe"
              >
                <Share2 className="w-5 h-5" />
              </button>
              <button
                onClick={handlePrint}
                className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                title="Print recipe"
              >
                <Printer className="w-5 h-5" />
              </button>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                <ChefHat className="w-5 h-5 mr-2 text-blue-600" />
                Ingredients
              </h2>
              <ul className="space-y-2">
                {recipe.ingredients.map((ingredient: string, index: number) => (
                  <li key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2" />
                    {ingredient}
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Instructions</h2>
              <ol className="space-y-4">
                {recipe.instructions.map((instruction: string, index: number) => (
                  <li key={index} className="flex">
                    <span className="flex-none w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium mr-3">
                      {index + 1}
                    </span>
                    <p className="flex-1 pt-1">{instruction}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>

          {recipe.media_urls && recipe.media_urls.length > 1 && (
            <div className="mt-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Gallery</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {recipe.media_urls.map((url: string, index: number) => (
                  <div 
                    key={index} 
                    className="relative aspect-square rounded-lg overflow-hidden cursor-pointer"
                    onClick={() => setCurrentImageIndex(index)}
                  >
                    <img
                      src={url}
                      alt={`${recipe.title} - Image ${index + 1}`}
                      className="w-full h-full object-cover hover:scale-110 transition-transform duration-300"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}