import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Heart, 
  MessageSquare, 
  Share2, 
  Video,
  Send,
  Smile,
  Image as ImageIcon,
  User,
  AlertCircle
} from 'lucide-react';
import { getPost, likePost, unlikePost } from '../lib/social';
import { getComments, createComment } from '../lib/chat';
import { useAuth } from '../lib/auth';
import { Loading } from '../components/Loading';

export function Post() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [post, setPost] = useState<any>(null);
  const [comments, setComments] = useState<any[]>([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [likeLoading, setLikeLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const commentsEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!id) {
      navigate('/');
      return;
    }
    loadPost();
  }, [id, navigate]);

  useEffect(() => {
    if (id) {
      loadComments();
    }
  }, [id]);

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  async function loadPost() {
    try {
      setLoading(true);
      setError(null);
      const data = await getPost(id!);
      setPost(data);
    } catch (err: any) {
      console.error('Error loading post:', err);
      setError(err.message || 'Failed to load post');
    } finally {
      setLoading(false);
    }
  }

  async function loadComments() {
    try {
      const data = await getComments(id!);
      setComments(data);
    } catch (err: any) {
      console.error('Error loading comments:', err);
    }
  }

  const handleLike = async () => {
    if (!user) {
      navigate('/signin');
      return;
    }

    if (likeLoading) return;

    try {
      setLikeLoading(true);
      if (post.has_liked) {
        await unlikePost(post.id);
        setPost(prev => ({
          ...prev,
          likes_count: Math.max((prev.likes_count || 0) - 1, 0),
          has_liked: false
        }));
      } else {
        await likePost(post.id);
        setPost(prev => ({
          ...prev,
          likes_count: (prev.likes_count || 0) + 1,
          has_liked: true
        }));
      }
    } catch (err: any) {
      console.error('Error toggling like:', err);
      // Revert optimistic update on error
      setPost(prev => ({
        ...prev,
        likes_count: post.has_liked ? prev.likes_count + 1 : Math.max(prev.likes_count - 1, 0),
        has_liked: post.has_liked
      }));
    } finally {
      setLikeLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Post by ${post.profiles?.username || 'Anonymous'}`,
          text: post.content,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const handleComment = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      navigate('/signin');
      return;
    }

    if (!newComment.trim() || commentLoading) return;

    try {
      setCommentLoading(true);
      await createComment(id!, newComment);
      setNewComment('');
      loadComments();
    } catch (err: any) {
      console.error('Error creating comment:', err);
      setError(err.message);
    } finally {
      setCommentLoading(false);
    }
  };

  const getMediaType = (url: string) => {
    if (!url) return null;
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    const extension = url.toLowerCase().split('.').pop();
    return extension && videoExtensions.includes('.' + extension) ? 'video' : 'image';
  };

  if (loading) {
    return <Loading fullScreen />;
  }

  if (error || !post) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
          {error || 'Post not found'}
        </div>
        <button
          onClick={() => navigate('/')}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <ArrowLeft className="w-4 h-4 mr-1" />
          Back to Home
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <button
        onClick={() => navigate('/')}
        className="flex items-center text-gray-600 hover:text-gray-800 mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-1" />
        Back to Home
      </button>

      <div className="grid md:grid-cols-2 gap-6">
        {/* Post Content */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            <div className="flex items-center space-x-4 mb-4">
              <img
                src={post.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${post.profiles?.username || 'User'}&background=random`}
                alt={post.profiles?.username || 'User avatar'}
                className="w-12 h-12 rounded-full"
              />
              <div>
                <div className="font-medium text-gray-900">
                  {post.profiles?.username || 'Anonymous'}
                </div>
                <div className="text-sm text-gray-500">
                  {new Date(post.created_at).toLocaleString()}
                </div>
              </div>
            </div>

            <p className="text-gray-800 mb-4 whitespace-pre-wrap">{post.content}</p>

            {post.media_urls?.length > 0 && (
              <div className="space-y-4 mb-4">
                {post.media_urls.map((url: string, index: number) => (
                  <div key={index} className="rounded-lg overflow-hidden">
                    {getMediaType(url) === 'video' ? (
                      <div className="relative">
                        <video
                          src={url}
                          controls
                          className="w-full rounded-lg"
                          preload="metadata"
                        />
                        <Video className="absolute top-2 left-2 w-6 h-6 text-white drop-shadow-lg" />
                      </div>
                    ) : (
                      <img
                        src={url}
                        alt="Post content"
                        className="w-full rounded-lg"
                        loading="lazy"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="flex items-center space-x-4 text-gray-500">
              <button
                onClick={handleLike}
                disabled={likeLoading}
                className={`flex items-center space-x-1 transition-colors ${
                  post.has_liked ? 'text-red-600' : 'hover:text-red-600'
                } ${likeLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <Heart className={`w-5 h-5 ${post.has_liked ? 'fill-current' : ''}`} />
                <span>{post.likes_count || 0}</span>
              </button>
              <button className="flex items-center space-x-1 hover:text-blue-600 transition-colors">
                <MessageSquare className="w-5 h-5" />
                <span>{comments.length}</span>
              </button>
              <button
                onClick={handleShare}
                className="flex items-center space-x-1 hover:text-blue-600 transition-colors"
              >
                <Share2 className="w-5 h-5" />
                <span>Share</span>
              </button>
            </div>
          </div>
        </div>

        {/* Comments Section */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-[600px]">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900 flex items-center">
              <MessageSquare className="w-5 h-5 mr-2" />
              Comments
            </h2>
          </div>

          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {comments.map((comment) => (
              <div key={comment.id} className="flex items-start space-x-3">
                <img
                  src={comment.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${comment.profiles?.username || 'User'}&background=random`}
                  alt={comment.profiles?.username || 'User avatar'}
                  className="w-8 h-8 rounded-full"
                />
                <div className="flex-1">
                  <div className="bg-gray-100 rounded-lg p-3">
                    <div className="font-medium text-gray-900">
                      {comment.profiles?.username || 'Anonymous'}
                    </div>
                    <p className="text-gray-800">{comment.content}</p>
                  </div>
                  <div className="mt-1 text-xs text-gray-500">
                    {new Date(comment.created_at).toLocaleString()}
                  </div>
                </div>
              </div>
            ))}
            <div ref={commentsEndRef} />
          </div>

          <form onSubmit={handleComment} className="p-4 border-t border-gray-200">
            <div className="flex items-center space-x-2">
              <div className="flex-1 relative">
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Write a comment..."
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                  rows={1}
                />
                <div className="absolute right-2 bottom-2 flex space-x-2">
                  <button
                    type="button"
                    className="p-1 text-gray-500 hover:text-blue-600"
                  >
                    <Smile className="w-5 h-5" />
                  </button>
                  <button
                    type="button"
                    className="p-1 text-gray-500 hover:text-blue-600"
                  >
                    <ImageIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <button
                type="submit"
                disabled={!newComment.trim() || commentLoading}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                {commentLoading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <Send className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}