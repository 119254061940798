import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from './supabase';
import { captureError } from './monitoring';
import { trackEvent } from './analytics';
import { jwtDecode } from 'jwt-decode';

interface AuthState {
  user: any | null;
  loading: boolean;
  error: string | null;
  signUp: (data: {
    email: string;
    password: string;
    username: string;
    full_name: string;
  }) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  clearError: () => void;
  refreshSession: () => Promise<void>;
  getDecodedToken: () => Promise<any | null>;
}

export const useAuth = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      loading: false,
      error: null,
      clearError: () => set({ error: null }),
      getDecodedToken: async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          try {
            return jwtDecode(session.access_token);
          } catch (error) {
            console.error('Error decoding token:', error);
            return null;
          }
        }
        return null;
      },
      refreshSession: async () => {
        try {
          set({ loading: true, error: null });
          const { data: { session }, error: sessionError } = await supabase.auth.getSession();
          
          if (sessionError) {
            const { data: { session: refreshedSession }, error: refreshError } = 
              await supabase.auth.refreshSession();
              
            if (refreshError) {
              await supabase.auth.signOut();
              set({ user: null, loading: false, error: null });
              return;
            }
            
            if (refreshedSession?.user) {
              const { data: profile } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', refreshedSession.user.id)
                .single();

              set({ 
                user: { ...refreshedSession.user, profile: profile || null },
                loading: false,
                error: null
              });
              return;
            }
          }
          
          if (session?.user) {
            const { data: profile } = await supabase
              .from('profiles')
              .select('*')
              .eq('id', session.user.id)
              .single();

            set({ 
              user: { ...session.user, profile: profile || null },
              loading: false,
              error: null
            });
          } else {
            set({ user: null, loading: false, error: null });
          }
        } catch (error: any) {
          console.error('Error refreshing session:', error);
          captureError(error);
          set({ user: null, loading: false, error: error.message });
        }
      },
      signIn: async (email: string, password: string) => {
        try {
          set({ loading: true, error: null });
          
          const { data, error } = await supabase.auth.signInWithPassword({
            email: email.trim(),
            password: password,
            options: {
              data: {
                role: 'explorer' // Set default role
              }
            }
          });
          
          if (error) throw error;
          if (!data.user) throw new Error('Invalid login credentials');

          const { data: profile } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', data.user.id)
            .single();

          trackEvent('user_signin');
          set({ 
            user: { ...data.user, profile: profile || null }, 
            loading: false,
            error: null
          });
        } catch (error: any) {
          captureError(error);
          set({ 
            error: error.message === 'Invalid login credentials' 
              ? 'Invalid email or password' 
              : error.message,
            loading: false,
            user: null
          });
          throw error;
        }
      },
      signUp: async (data) => {
        try {
          set({ loading: true, error: null });

          const { email, password, ...profileData } = data;

          const { data: authData, error: signUpError } = await supabase.auth.signUp({
            email,
            password,
            options: {
              data: {
                role: 'explorer',
                ...profileData
              },
              emailRedirectTo: `${window.location.origin}/confirm-email`
            }
          });

          if (signUpError) throw signUpError;
          if (!authData.user) throw new Error('Failed to create user');

          const { error: profileError } = await supabase
            .from('profiles')
            .insert([{
              id: authData.user.id,
              username: profileData.username,
              full_name: profileData.full_name,
              avatar_url: `https://ui-avatars.com/api/?name=${encodeURIComponent(profileData.full_name)}&background=random`,
              role: 'explorer'
            }]);

          if (profileError) {
            await supabase.auth.signOut();
            throw new Error('Failed to create profile');
          }

          trackEvent('user_signup');
          set({ user: authData.user, loading: false, error: null });
        } catch (error: any) {
          captureError(error);
          set({ error: error.message, loading: false });
          throw error;
        }
      },
      signOut: async () => {
        try {
          set({ loading: true, error: null });
          const { error } = await supabase.auth.signOut();
          if (error) throw error;
          
          trackEvent('user_signout');
          set({ user: null, loading: false, error: null });
          
          // Clear any cached data
          localStorage.clear();
          if ('caches' in window) {
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
          }
        } catch (error: any) {
          captureError(error);
          set({ error: error.message, loading: false });
          throw error;
        }
      },
      resetPassword: async (email) => {
        try {
          set({ loading: true, error: null });
          const { error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: `${window.location.origin}/reset-password`,
          });
          if (error) throw error;
          trackEvent('password_reset_requested');
          set({ loading: false, error: null });
        } catch (error: any) {
          captureError(error);
          set({ error: error.message, loading: false });
          throw error;
        }
      }
    }),
    {
      name: 'auth-storage',
      storage: localStorage,
      partialize: (state) => ({
        user: state.user
      })
    }
  )
);

// Initialize auth state
supabase.auth.onAuthStateChange(async (event, session) => {
  useAuth.setState({ loading: true });
  
  if (event === 'SIGNED_IN' && session?.user) {
    try {
      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      useAuth.setState({ 
        user: { ...session.user, profile: profile || null },
        loading: false,
        error: null
      });
      trackEvent('auth_state_change', { event });
    } catch (error) {
      console.error('Error fetching profile:', error);
      captureError(error as Error);
      useAuth.setState({ 
        user: session.user,
        loading: false,
        error: null
      });
    }
  } else if (event === 'SIGNED_OUT') {
    useAuth.setState({ user: null, loading: false, error: null });
    trackEvent('auth_state_change', { event });
  } else if (event === 'TOKEN_REFRESHED') {
    await useAuth.getState().refreshSession();
    trackEvent('auth_state_change', { event });
  } else {
    const { data: { session: existingSession } } = await supabase.auth.getSession();
    if (existingSession?.user) {
      try {
        const { data: profile } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', existingSession.user.id)
          .single();

        useAuth.setState({
          user: { ...existingSession.user, profile: profile || null },
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
        captureError(error as Error);
        useAuth.setState({
          user: existingSession.user,
          loading: false,
          error: null
        });
      }
    } else {
      useAuth.setState({ loading: false, error: null });
    }
  }
});

export default useAuth;