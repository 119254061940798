interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
  showText?: boolean;
  className?: string;
}

export function Logo({ size = 'md', showText = true, className = '' }: LogoProps) {
  const sizeClasses = {
    sm: 'h-8',
    md: 'h-10',
    lg: 'h-12'
  };

  const textSizeClasses = {
    sm: 'text-lg',
    md: 'text-xl',
    lg: 'text-2xl'
  };

  return (
    <div className={`flex items-center ${className}`}>
      <div className={`${showText ? 'mr-3' : ''}`}>
        <svg 
          className={sizeClasses[size]} 
          viewBox="0 0 1000 1000" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Red petals */}
          <circle cx="200" cy="300" r="80" fill="#DC143C" />
          <circle cx="800" cy="300" r="80" fill="#DC143C" />
          <path d="M400,300 Q600,400 500,700" strokeWidth="120" stroke="#DC143C" fill="none" />

          {/* Yellow petals */}
          <circle cx="300" cy="200" r="100" fill="#FFD700" />
          <circle cx="700" cy="200" r="100" fill="#FFD700" />
          <circle cx="500" cy="900" r="80" fill="#FFD700" />

          {/* Green petals */}
          <circle cx="400" cy="150" r="70" fill="#228B22" />
          <circle cx="600" cy="150" r="70" fill="#228B22" />
          <path d="M300,400 Q500,500 400,800" strokeWidth="100" stroke="#228B22" fill="none" />

          {/* Orange petals */}
          <circle cx="300" cy="600" r="70" fill="#FFA500" />
          <circle cx="700" cy="600" r="70" fill="#FFA500" />
          <circle cx="500" cy="500" r="60" fill="#FFA500" />
        </svg>
      </div>
      {showText && (
        <div className={`flex flex-col ${textSizeClasses[size]}`}>
          <span className="font-bold text-gray-900 leading-tight">
            CultureXplorer
          </span>
        </div>
      )}
    </div>
  );
}