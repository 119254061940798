import { supabase } from './supabase';

export interface ExploreEntry {
  id: string;
  user_id: string;
  title: string;
  description: string;
  location: string | null;
  category: 'tradition' | 'cuisine' | 'landmark' | 'festival';
  image_url: string | null;
  metadata: Record<string, any>;
  created_at: string;
  updated_at: string;
  profiles?: {
    username: string | null;
    avatar_url: string | null;
  };
}

// Initialize sample explore entries
export async function initializeSampleEntries() {
  const { data: existingEntries } = await supabase
    .from('explore')
    .select('id')
    .limit(1);

  // Only initialize if no entries exist
  if (!existingEntries || existingEntries.length === 0) {
    const sampleEntries = [
      {
        user_id: (await supabase.auth.getUser()).data.user?.id,
        title: 'Traditional Japanese Tea Ceremony',
        description: 'Experience the ancient art of Japanese tea ceremony, a cultural practice that embodies harmony, respect, purity, and tranquility.',
        location: 'Kyoto, Japan',
        category: 'tradition',
        image_url: 'https://source.unsplash.com/1600x900/?japanese,tea,ceremony',
        metadata: {
          duration: '2 hours',
          participants: '1-4 people',
          includes: ['Tea ceremony demonstration', 'Traditional sweets', 'Guided explanation']
        }
      },
      {
        user_id: (await supabase.auth.getUser()).data.user?.id,
        title: 'Authentic Italian Pizza Making',
        description: 'Learn the secrets of making authentic Neapolitan pizza from a master pizzaiolo in the heart of Naples.',
        location: 'Naples, Italy',
        category: 'cuisine',
        image_url: 'https://source.unsplash.com/1600x900/?pizza,naples,cooking',
        metadata: {
          duration: '3 hours',
          participants: '2-8 people',
          includes: ['Hands-on pizza making', 'Traditional techniques', 'Wood-fired oven cooking']
        }
      }
    ];

    const { error } = await supabase
      .from('explore')
      .insert(sampleEntries);

    if (error) throw error;
  }
}

export async function getExploreEntries() {
  const { data, error } = await supabase
    .from('explore')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      )
    `)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data || [];
}

export async function getExploreEntry(id: string) {
  const { data, error } = await supabase
    .from('explore')
    .select(`
      *,
      profiles:user_id (
        username,
        avatar_url
      )
    `)
    .eq('id', id)
    .single();

  if (error) {
    if (error.code === 'PGRST116') return null;
    throw error;
  }

  return data;
}