import { supabase } from './supabase';

export interface UserProfile {
  id: string;
  username: string;
  full_name: string;
  avatar_url: string;
  role: string;
  points: number;
  star_rating: number;
  phone_number: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  bio: string;
  followers_count: number;
  following_count: number;
  created_at: string;
}

export async function getProfile(userId: string) {
  const { data, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', userId)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      return null; // Profile not found
    }
    throw error;
  }

  return data;
}

export async function updateProfile(userId: string, updates: Partial<UserProfile>) {
  const { data, error } = await supabase
    .from('profiles')
    .update(updates)
    .eq('id', userId)
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function getUserRatings(userId: string) {
  const { data, error } = await supabase
    .from('user_ratings')
    .select(`
      *,
      rater:profiles!user_ratings_rater_id_fkey (
        username,
        avatar_url
      )
    `)
    .eq('rated_user_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function rateUser(userId: string, rating: number, comment: string) {
  const { data, error } = await supabase
    .from('user_ratings')
    .upsert([
      {
        rated_user_id: userId,
        rater_id: (await supabase.auth.getUser()).data.user?.id,
        rating,
        comment
      }
    ])
    .select()
    .single();

  if (error) throw error;
  return data;
}