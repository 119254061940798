import { createClient } from '@supabase/supabase-js';
import { isSafari, isIOS } from './platform';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Configure client with proper auth settings
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: {
      getItem: (key) => {
        try {
          const value = localStorage.getItem(key);
          return value ? JSON.parse(value) : null;
        } catch (error) {
          console.error('Error reading from localStorage:', error);
          return null;
        }
      },
      setItem: (key, value) => {
        try {
          localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
          console.error('Error writing to localStorage:', error);
        }
      },
      removeItem: (key) => {
        try {
          localStorage.removeItem(key);
        } catch (error) {
          console.error('Error removing from localStorage:', error);
        }
      }
    },
    flowType: 'pkce',
    debug: import.meta.env.DEV,
    cookieOptions: {
      name: 'sb-auth-token',
      lifetime: 60 * 60 * 24 * 7, // 1 week
      domain: window.location.hostname,
      path: '/',
      sameSite: 'lax',
      secure: import.meta.env.PROD
    }
  },
  global: {
    headers: {
      'X-Client-Info': 'culture-explorer'
    }
  }
});

// Initialize connection pool
const pool = {
  min: 0,
  max: 10,
  idleTimeoutMillis: 30000,
  connectionTimeoutMillis: 2000
};

// Add connection health check
let healthCheckInterval: number;

const startHealthCheck = () => {
  healthCheckInterval = window.setInterval(async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        const { data: { session: newSession }, error } = await supabase.auth.refreshSession();
        if (error || !newSession) {
          await supabase.auth.signOut();
        }
      }
    } catch (error) {
      console.error('Supabase error:', error);
      // Attempt to refresh session on auth errors
      if ((error as any)?.status === 401) {
        await supabase.auth.refreshSession();
      }
    }
  }, 30000);
};

const stopHealthCheck = () => {
  if (healthCheckInterval) {
    clearInterval(healthCheckInterval);
  }
};

// Initialize auth state
supabase.auth.onAuthStateChange(async (event, session) => {
  if (event === 'SIGNED_IN' && session) {
    console.log('User signed in:', session.user.id);
    startHealthCheck();

    try {
      // Get user profile
      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      // Store user data
      localStorage.setItem('user', JSON.stringify({
        ...session.user,
        profile: profile || null
      }));
    } catch (error) {
      console.error('Error getting user profile:', error);
      await supabase.auth.signOut();
    }
  } else if (event === 'SIGNED_OUT') {
    console.log('User signed out');
    stopHealthCheck();
    
    // Clear user data and cache
    localStorage.clear();
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  } else if (event === 'TOKEN_REFRESHED') {
    console.log('Token refreshed');
  }
});

// Check for existing session on load
supabase.auth.getSession().then(({ data: { session } }) => {
  if (session) {
    startHealthCheck();
  }
});

// Handle visibility change to manage connection
document.addEventListener('visibilitychange', async () => {
  if (document.visibilityState === 'visible') {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      await supabase.auth.refreshSession();
      startHealthCheck();
    }
  } else {
    stopHealthCheck();
  }
});

// Handle before unload to clean up
window.addEventListener('beforeunload', () => {
  stopHealthCheck();
});

export default supabase;