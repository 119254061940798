import React from 'react';
import { Globe2, Users, ChefHat, Award } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function Home() {
  const { t } = useTranslation();

  return (
    <div className="space-y-16">
      {/* Hero Section with World Map Background */}
      <section className="relative min-h-[80vh] flex items-center justify-center overflow-hidden">
        {/* Background Image */}
        <div className="absolute inset-0 w-full h-full">
          <img
            src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80"
            alt="Earth from space"
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
        
        {/* Overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-purple-900/90" />
        
        {/* Content */}
        <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
          <h1 className="text-5xl md:text-7xl font-bold text-white mb-6 leading-tight">
            Discover the World's Cultural Heritage
          </h1>
          <p className="text-xl md:text-2xl text-white/90 mb-8 leading-relaxed">
            Explore traditions, share experiences, and connect with culture enthusiasts from around the globe.
          </p>
          <button
            onClick={() => window.location.href = '/explore'}
            className="px-8 py-4 bg-white text-blue-900 rounded-full font-semibold hover:bg-blue-50 transform hover:scale-105 transition-all duration-200 shadow-lg text-lg"
          >
            Start Your Journey
          </button>
        </div>

        {/* Animated Scroll Indicator */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
          <div className="w-6 h-10 border-2 border-white rounded-full flex items-start justify-center p-1">
            <div className="w-1.5 h-3 bg-white rounded-full animate-scroll" />
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="px-4 py-16 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Globe2,
                title: "Explore Cultures",
                description: "Discover traditions, customs, and heritage from different countries"
              },
              {
                icon: Users,
                title: "Join Communities",
                description: "Connect with people who share your interest in cultural exploration"
              },
              {
                icon: ChefHat,
                title: "Learn & Share",
                description: "Access educational content and share your cultural knowledge"
              },
              {
                icon: Award,
                title: "Earn Rewards",
                description: "Get recognition for your contributions and cultural learning"
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 border border-gray-100"
              >
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Image Gallery */}
      <section className="px-4 py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="relative h-96 rounded-xl overflow-hidden group">
              <img
                src="https://images.unsplash.com/photo-1523906834658-6e24ef2386f9"
                alt="Cultural Landmarks"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent group-hover:from-black/90 transition-all duration-300" />
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h3 className="text-3xl font-bold text-white mb-2">Cultural Landmarks</h3>
                <p className="text-white/80 text-lg">Explore iconic monuments and sacred places</p>
              </div>
            </div>
            <div className="relative h-96 rounded-xl overflow-hidden group">
              <img
                src="https://images.unsplash.com/photo-1516483638261-f4dbaf036963"
                alt="Traditional Festivals"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent group-hover:from-black/90 transition-all duration-300" />
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h3 className="text-3xl font-bold text-white mb-2">Traditional Festivals</h3>
                <p className="text-white/80 text-lg">Experience vibrant cultural celebrations</p>
              </div>
            </div>
            <div className="relative h-96 rounded-xl overflow-hidden group">
              <img
                src="https://images.unsplash.com/photo-1504674900247-0877df9cc836"
                alt="Local Cuisines"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent group-hover:from-black/90 transition-all duration-300" />
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h3 className="text-3xl font-bold text-white mb-2">Local Cuisines</h3>
                <p className="text-white/80 text-lg">Discover authentic flavors and recipes</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-20 px-4">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600" />
        <div className="relative z-10 text-center max-w-4xl mx-auto text-white">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Ready to Start Your Cultural Journey?
          </h2>
          <p className="text-xl mb-8 opacity-90">
            Join our community of cultural explorers and start sharing your experiences today.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => window.location.href = '/signin'}
              className="px-8 py-4 bg-white text-blue-600 rounded-full font-semibold hover:bg-blue-50 transform hover:scale-105 transition-all duration-200 text-lg"
            >
              Sign In
            </button>
            <button
              onClick={() => window.location.href = '/signup'}
              className="px-8 py-4 bg-transparent border-2 border-white text-white rounded-full font-semibold hover:bg-white/10 transform hover:scale-105 transition-all duration-200 text-lg"
            >
              Create Account
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}