// Simple platform detection based on window size and user agent
export const isMobile = typeof window !== 'undefined' && (
  window.innerWidth < 768 || 
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
);

export const isWeb = typeof window !== 'undefined';
export const isNative = false; // Since this is a web app

// Safari detection
export const isSafari = typeof window !== 'undefined' && (
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
);

// iOS detection
export const isIOS = typeof window !== 'undefined' && (
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
);