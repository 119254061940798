import { supabase } from './supabase';

export interface TranslationResult {
  text: string;
  detectedSourceLanguage?: string;
  model?: string;
}

export async function translateText(
  text: string,
  targetLanguage: string,
  sourceLanguage?: string
): Promise<TranslationResult> {
  try {
    if (!text || !targetLanguage) {
      throw new Error('Text and target language are required');
    }

    // First try to get translation from our database
    const { data: existingTranslation } = await supabase
      .from('translations')
      .select('translation')
      .eq('phrase', text)
      .eq('target_language', targetLanguage)
      .maybeSingle();

    if (existingTranslation?.translation) {
      return {
        text: existingTranslation.translation,
        model: 'database'
      };
    }

    // If no existing translation, use Netlify function
    const response = await fetch('/.netlify/functions/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text,
        targetLanguage,
        sourceLanguage: sourceLanguage || 'auto'
      })
    });

    // First check if response is ok
    if (!response.ok) {
      const errorText = await response.text();
      let errorMessage = `Translation request failed with status ${response.status}`;
      
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.error || errorData.details || errorMessage;
      } catch (e) {
        // If JSON parsing fails, use the raw error text
        errorMessage = errorText || errorMessage;
      }
      
      throw new Error(errorMessage);
    }

    // Try to parse the response
    let data;
    try {
      data = await response.json();
    } catch (e) {
      throw new Error('Invalid response format from translation service');
    }

    if (!data.translation) {
      throw new Error('No translation received');
    }

    // Save translation to database if authenticated
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      await supabase.from('translations').insert([{
        source_language: data.detectedLanguage || sourceLanguage || 'en',
        target_language: targetLanguage,
        phrase: text,
        translation: data.translation,
        category: 'user_input',
        difficulty: 'beginner'
      }]);
    }

    return {
      text: data.translation,
      detectedSourceLanguage: data.detectedLanguage,
      model: 'google'
    };
  } catch (error) {
    console.error('Translation error:', error);
    throw error;
  }
}

export async function detectLanguage(text: string): Promise<string> {
  try {
    if (!text) {
      throw new Error('Text is required for language detection');
    }

    const response = await fetch('/.netlify/functions/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text,
        detectOnly: true
      })
    });

    // First check if response is ok
    if (!response.ok) {
      const errorText = await response.text();
      let errorMessage = `Language detection failed with status ${response.status}`;
      
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.error || errorData.details || errorMessage;
      } catch (e) {
        // If JSON parsing fails, use the raw error text
        errorMessage = errorText || errorMessage;
      }
      
      throw new Error(errorMessage);
    }

    // Try to parse the response
    let data;
    try {
      data = await response.json();
    } catch (e) {
      throw new Error('Invalid response format from language detection service');
    }

    if (!data.detectedLanguage) {
      throw new Error('No language detection result received');
    }

    return data.detectedLanguage;
  } catch (error) {
    console.error('Language detection error:', error);
    throw error;
  }
}

export async function getLanguages(): Promise<Array<{ code: string; name: string }>> {
  try {
    const { data, error } = await supabase
      .from('languages')
      .select('code, name')
      .eq('is_active', true)
      .order('name');

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching languages:', error);
    throw error;
  }
}