import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Hash,
  ArrowLeft,
  Users,
  MessageSquare,
  Heart,
  Share2,
  Plus,
  Image as ImageIcon,
  Video,
  AlertCircle
} from 'lucide-react';
import { useAuth } from '../lib/auth';
import { getTopic, createTopicPost, followTopic, unfollowTopic, type Topic, type TopicPost } from '../lib/topics';
import { MediaUpload } from '../components/MediaUpload';

export function TopicDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [topic, setTopic] = useState<Topic | null>(null);
  const [posts, setPosts] = useState<TopicPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showNewPostForm, setShowNewPostForm] = useState(false);
  const [newPost, setNewPost] = useState({
    content: '',
    media_urls: [] as string[]
  });

  useEffect(() => {
    if (!id) return;
    loadTopic();
  }, [id]);

  async function loadTopic() {
    try {
      const data = await getTopic(id!);
      setTopic(data);
      setPosts(data.posts || []);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleCreatePost = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !topic) return;

    try {
      setError(null);
      const post = await createTopicPost(topic.id, newPost);
      setPosts(prev => [post, ...prev]);
      setShowNewPostForm(false);
      setNewPost({
        content: '',
        media_urls: []
      });
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleFollow = async () => {
    if (!user || !topic) return;

    try {
      if (topic.is_following) {
        await unfollowTopic(topic.id);
      } else {
        await followTopic(topic.id);
      }
      await loadTopic();
    } catch (err: any) {
      setError(err.message);
    }
  };

  const getMediaIcon = (url: string) => {
    const isVideo = url.match(/\.(mp4|webm|ogg)$/i);
    return isVideo ? <Video className="w-4 h-4" /> : <ImageIcon className="w-4 h-4" />;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !topic) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
          {error || 'Topic not found'}
        </div>
        <button
          onClick={() => navigate('/topics')}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <ArrowLeft className="w-4 h-4 mr-1" />
          Back to Topics
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <button
        onClick={() => navigate('/topics')}
        className="flex items-center text-gray-600 hover:text-gray-800 mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-1" />
        Back to Topics
      </button>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        {topic.media_url ? (
          <img
            src={topic.media_url}
            alt={topic.title}
            className="w-full h-64 object-cover"
          />
        ) : (
          <div className="w-full h-64 bg-gray-100 flex items-center justify-center">
            <Hash className="w-16 h-16 text-gray-400" />
          </div>
        )}

        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-3xl font-bold text-gray-900">{topic.title}</h1>
            {user && (
              <button
                onClick={handleFollow}
                className={`px-4 py-2 rounded-md ${
                  topic.is_following
                    ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                    : 'bg-blue-600 text-white hover:bg-blue-700'
                }`}
              >
                {topic.is_following ? 'Following' : 'Follow'}
              </button>
            )}
          </div>

          <p className="text-gray-600 mb-6">{topic.description}</p>

          <div className="flex items-center space-x-6 text-sm text-gray-500 mb-6">
            <div className="flex items-center">
              <Users className="w-4 h-4 mr-1" />
              {topic.followers_count} followers
            </div>
            <div className="flex items-center">
              <MessageSquare className="w-4 h-4 mr-1" />
              {topic.posts_count} posts
            </div>
            <div className="text-sm text-gray-500">
              Created {new Date(topic.created_at).toLocaleDateString()}
            </div>
          </div>

          {user && (
            <button
              onClick={() => setShowNewPostForm(true)}
              className="w-full flex items-center justify-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 mb-6"
            >
              <Plus className="w-5 h-5 mr-2" />
              Create Post
            </button>
          )}

          <div className="space-y-6">
            {posts.map((post) => (
              <div key={post.id} className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-start space-x-3 mb-4">
                  <img
                    src={post.profiles?.avatar_url || `https://ui-avatars.com/api/?name=${post.profiles?.username || 'User'}`}
                    alt={post.profiles?.username || 'User avatar'}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <span className="font-medium text-gray-900">
                        {post.profiles?.username || 'Anonymous'}
                      </span>
                      <span className="text-sm text-gray-500">
                        {new Date(post.created_at).toLocaleString()}
                      </span>
                    </div>
                    <p className="mt-1 text-gray-800">{post.content}</p>

                    {post.media_urls && post.media_urls.length > 0 && (
                      <div className="mt-3 grid grid-cols-2 gap-2">
                        {post.media_urls.map((url, index) => (
                          <div key={index} className="relative">
                            {url.match(/\.(mp4|webm|ogg)$/i) ? (
                              <video
                                src={url}
                                controls
                                className="rounded-lg max-h-96 w-full"
                              />
                            ) : (
                              <img
                                src={url}
                                alt={`Post media ${index + 1}`}
                                className="rounded-lg max-h-96 w-full object-cover"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center space-x-4 text-gray-500">
                  <button className="flex items-center space-x-1 hover:text-red-600">
                    <Heart className="w-5 h-5" />
                    <span>Like</span>
                  </button>
                  <button className="flex items-center space-x-1 hover:text-blue-600">
                    <Share2 className="w-5 h-5" />
                    <span>Share</span>
                  </button>
                </div>
              </div>
            ))}
          </div>

          {posts.length === 0 && (
            <div className="text-center py-12">
              <MessageSquare className="w-16 h-16 text-gray-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">No posts yet</h3>
              <p className="text-gray-600">Be the first to share something in this topic!</p>
            </div>
          )}
        </div>
      </div>

      {/* New Post Form Modal */}
      {showNewPostForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
            <h2 className="text-2xl font-bold mb-4">Create Post</h2>
            
            <form onSubmit={handleCreatePost} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Content
                </label>
                <textarea
                  required
                  value={newPost.content}
                  onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Share your thoughts..."
                />
              </div>

              <MediaUpload
                mediaUrls={newPost.media_urls}
                onMediaChange={(urls) => setNewPost({ ...newPost, media_urls: urls })}
                maxFiles={4}
                allowedTypes={['image', 'video']}
              />

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowNewPostForm(false);
                    setNewPost({
                      content: '',
                      media_urls: []
                    });
                  }}
                  className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Post
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}