import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Brain, Trophy, Timer, AlertCircle, CheckCircle2, XCircle, Medal, ArrowRight } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../lib/auth';

interface Quiz {
  id: string;
  title: string;
  description: string;
  category: string;
  difficulty: 'beginner' | 'intermediate' | 'advanced';
  points_available: number;
  time_limit: number | null;
  pass_threshold: number;
  is_active: boolean;
  metadata: {
    recommended_knowledge?: string[];
    prerequisites?: string[];
    topics?: string[];
  };
}

interface Question {
  id: string;
  quiz_id: string;
  question: string;
  options: string[];
  correct_answer: string;
  explanation: string;
  points: number;
  media_url?: string;
}

interface QuizAttempt {
  id: string;
  quiz_id: string;
  user_id: string;
  score: number;
  answers: Record<string, string>;
  time_taken: number;
  completed: boolean;
  passed: boolean;
  points_earned: number;
}

export function CulturalQuiz() {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [showExplanation, setShowExplanation] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    loadQuizzes();
  }, []);

  useEffect(() => {
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [timerInterval]);

  async function loadQuizzes() {
    try {
      const { data, error } = await supabase
        .from('quizzes')
        .select('*')
        .eq('is_active', true)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setQuizzes(data || []);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function startQuiz(quiz: Quiz) {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('quiz_questions')
        .select('*')
        .eq('quiz_id', quiz.id)
        .order('created_at');

      if (error) throw error;
      
      setSelectedQuiz(quiz);
      setQuestions(data || []);
      setQuizStarted(true);
      setCurrentQuestionIndex(0);
      setScore(0);
      setAnswers({});
      
      if (quiz.time_limit) {
        setTimeRemaining(quiz.time_limit);
        const interval = setInterval(() => {
          setTimeRemaining(prev => {
            if (prev === null || prev <= 1) {
              clearInterval(interval);
              if (prev === 1) {
                handleQuizComplete();
              }
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        setTimerInterval(interval);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleAnswer(answer: string) {
    if (!selectedQuiz || !questions[currentQuestionIndex]) return;

    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = answer === currentQuestion.correct_answer;
    
    setSelectedAnswer(answer);
    setShowExplanation(true);
    setAnswers(prev => ({ ...prev, [currentQuestion.id]: answer }));

    if (isCorrect) {
      setScore(prev => prev + currentQuestion.points);
    }

    // Wait for explanation
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
      setSelectedAnswer('');
      setShowExplanation(false);
    } else {
      handleQuizComplete();
    }
  }

  async function handleQuizComplete() {
    if (!selectedQuiz || !user) return;

    try {
      const totalPoints = questions.reduce((sum, q) => sum + q.points, 0);
      const percentageScore = (score / totalPoints) * 100;
      const passed = percentageScore >= (selectedQuiz.pass_threshold || 70);

      const attempt: Partial<QuizAttempt> = {
        quiz_id: selectedQuiz.id,
        user_id: user.id,
        score: percentageScore,
        answers,
        time_taken: selectedQuiz.time_limit ? selectedQuiz.time_limit - (timeRemaining || 0) : null,
        completed: true,
        passed,
        points_earned: passed ? selectedQuiz.points_available : 0
      };

      const { error } = await supabase
        .from('quiz_attempts')
        .insert([attempt]);

      if (error) throw error;

      if (passed) {
        // Update user points
        await supabase
          .from('profiles')
          .update({ 
            points: user.profile?.points + selectedQuiz.points_available 
          })
          .eq('id', user.id);
      }

      setQuizCompleted(true);
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    } catch (err: any) {
      setError(err.message);
    }
  }

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 text-red-700 dark:text-red-400 px-4 py-3 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (quizCompleted) {
    const totalPoints = questions.reduce((sum, q) => sum + q.points, 0);
    const percentageScore = (score / totalPoints) * 100;
    const passed = percentageScore >= (selectedQuiz?.pass_threshold || 70);

    return (
      <div className="container mx-auto px-4 py-8 max-w-2xl">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-8 text-center">
          <div className="mb-6">
            {passed ? (
              <Trophy className="w-16 h-16 text-yellow-500 mx-auto" />
            ) : (
              <Medal className="w-16 h-16 text-gray-400 mx-auto" />
            )}
          </div>
          
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Quiz Completed!
          </h2>
          
          <div className="text-4xl font-bold mb-4">
            <span className={passed ? 'text-green-500' : 'text-red-500'}>
              {Math.round(percentageScore)}%
            </span>
          </div>

          <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">
            You scored {score} out of {totalPoints} points
          </p>

          {passed && (
            <div className="bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800 rounded-md p-4 mb-6">
              <p className="text-green-700 dark:text-green-400">
                Congratulations! You've earned {selectedQuiz?.points_available} points!
              </p>
            </div>
          )}

          <div className="flex justify-center space-x-4">
            <button
              onClick={() => {
                setQuizStarted(false);
                setQuizCompleted(false);
                setSelectedQuiz(null);
              }}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Try Another Quiz
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (quizStarted && selectedQuiz && questions.length > 0) {
    const currentQuestion = questions[currentQuestionIndex];
    
    return (
      <div className="container mx-auto px-4 py-8 max-w-2xl">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              {selectedQuiz.title}
            </h2>
            {timeRemaining !== null && (
              <div className="flex items-center text-gray-600 dark:text-gray-400">
                <Timer className="w-5 h-5 mr-1" />
                {formatTime(timeRemaining)}
              </div>
            )}
          </div>

          <div className="mb-8">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Question {currentQuestionIndex + 1} of {questions.length}
              </span>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {currentQuestion.points} points
              </span>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-1.5">
              <div
                className="bg-blue-600 h-1.5 rounded-full transition-all duration-300"
                style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
              />
            </div>
          </div>

          <div className="space-y-6">
            <div className="text-lg text-gray-900 dark:text-white">
              {currentQuestion.question}
            </div>

            {currentQuestion.media_url && (
              <div className="rounded-lg overflow-hidden mb-6">
                <img
                  src={currentQuestion.media_url}
                  alt="Question illustration"
                  className="w-full h-48 object-cover"
                />
              </div>
            )}

            <div className="space-y-3">
              {currentQuestion.options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => !showExplanation && handleAnswer(option)}
                  disabled={showExplanation}
                  className={`w-full p-4 text-left rounded-lg transition-colors ${
                    showExplanation
                      ? option === currentQuestion.correct_answer
                        ? 'bg-green-100 dark:bg-green-900/20 text-green-800 dark:text-green-400'
                        : option === selectedAnswer
                        ? 'bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-400'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400'
                      : 'bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-900 dark:text-white'
                  }`}
                >
                  <div className="flex items-center">
                    {showExplanation && (
                      option === currentQuestion.correct_answer ? (
                        <CheckCircle2 className="w-5 h-5 mr-2 text-green-600 dark:text-green-400" />
                      ) : option === selectedAnswer ? (
                        <XCircle className="w-5 h-5 mr-2 text-red-600 dark:text-red-400" />
                      ) : null
                    )}
                    {option}
                  </div>
                </button>
              ))}
            </div>

            {showExplanation && (
              <div className={`mt-4 p-4 rounded-lg ${
                selectedAnswer === currentQuestion.correct_answer
                  ? 'bg-green-50 dark:bg-green-900/10 border border-green-200 dark:border-green-800'
                  : 'bg-red-50 dark:bg-red-900/10 border border-red-200 dark:border-red-800'
              }`}>
                <p className={`text-sm ${
                  selectedAnswer === currentQuestion.correct_answer
                    ? 'text-green-800 dark:text-green-400'
                    : 'text-red-800 dark:text-red-400'
                }`}>
                  {currentQuestion.explanation}
                </p>
              </div>
            )}

            {showExplanation && currentQuestionIndex < questions.length - 1 && (
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setCurrentQuestionIndex(prev => prev + 1);
                    setSelectedAnswer('');
                    setShowExplanation(false);
                  }}
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Next Question
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-8">
        <Brain className="w-8 h-8 text-blue-600 dark:text-blue-400 mr-2" />
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Cultural Quizzes</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {quizzes.map((quiz) => (
          <div
            key={quiz.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="p-6">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {quiz.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                {quiz.description}
              </p>
              
              <div className="space-y-3">
                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                  <Trophy className="w-4 h-4 mr-1" />
                  {quiz.points_available} points available
                </div>
                
                {quiz.time_limit && (
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                    <Timer className="w-4 h-4 mr-1" />
                    {Math.floor(quiz.time_limit / 60)} minutes
                  </div>
                )}

                <div className="flex items-center text-sm">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    quiz.difficulty === 'beginner'
                      ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                      : quiz.difficulty === 'intermediate'
                      ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400'
                      : 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400'
                  }`}>
                    {quiz.difficulty.charAt(0).toUpperCase() + quiz.difficulty.slice(1)}
                  </span>
                </div>
              </div>

              <button
                onClick={() => startQuiz(quiz)}
                className="mt-6 w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center justify-center"
              >
                Start Quiz
                <ArrowRight className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {quizzes.length === 0 && (
        <div className="text-center py-12">
          <Brain className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
            No quizzes available
          </h3>
          <p className="text-gray-600 dark:text-gray-400">
            Check back later for new quizzes
          </p>
        </div>
      )}
    </div>
  );
}

export default CulturalQuiz;