import React, { useState, useEffect } from 'react';
import Map, { Marker, Popup, NavigationControl } from 'react-map-gl';
import { Landmark, MapPin, Utensils, Music } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { CulturalPoint } from '../types';
import 'mapbox-gl/dist/mapbox-gl.css';

// Use the provided Mapbox token
const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN;

if (!MAPBOX_TOKEN) {
  throw new Error('Mapbox token is required');
}

const typeIcons = {
  landmark: Landmark,
  cuisine: Utensils,
  tradition: MapPin,
  festival: Music
};

const typeColors = {
  landmark: 'bg-blue-500',
  cuisine: 'bg-red-500',
  tradition: 'bg-green-500',
  festival: 'bg-yellow-500'
};

interface Props {
  selectedType?: 'all' | CulturalPoint['type'];
  onPointClick?: (point: CulturalPoint) => void;
  initialCoordinates?: [number, number] | null;
}

export function InteractiveMap({ selectedType = 'all', onPointClick, initialCoordinates }: Props) {
  const [points, setPoints] = useState<CulturalPoint[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPoint, setSelectedPoint] = useState<CulturalPoint | null>(null);
  const [viewState, setViewState] = useState({
    longitude: -45.0,
    latitude: 30.0,
    zoom: 2,
    pitch: 45,
    bearing: 0
  });

  useEffect(() => {
    if (initialCoordinates) {
      setViewState(prev => ({
        ...prev,
        longitude: initialCoordinates[0],
        latitude: initialCoordinates[1],
        zoom: 8
      }));
    }
  }, [initialCoordinates]);

  useEffect(() => {
    loadCulturalPoints();
  }, [selectedType]);

  async function loadCulturalPoints() {
    try {
      setLoading(true);
      setError(null);

      let query = supabase
        .from('cultural_points')
        .select('*')
        .not('latitude', 'is', null)
        .not('longitude', 'is', null);

      if (selectedType !== 'all') {
        query = query.eq('type', selectedType);
      }

      const { data, error } = await query;

      if (error) throw error;
      setPoints(data || []);
    } catch (err: any) {
      console.error('Error loading cultural points:', err);
      setError(err.message || 'Failed to load cultural points');
    } finally {
      setLoading(false);
    }
  }

  const TypeIcon = ({ type }: { type: CulturalPoint['type'] }) => {
    const Icon = typeIcons[type];
    return <Icon className="w-5 h-5 text-white" />;
  };

  const handleMarkerClick = (point: CulturalPoint) => {
    if (onPointClick) {
      onPointClick(point);
    } else {
      setSelectedPoint(point);
      // Center the map on the selected point
      setViewState(prev => ({
        ...prev,
        latitude: point.latitude!,
        longitude: point.longitude!,
        zoom: Math.max(prev.zoom, 10) // Zoom in if we're zoomed out
      }));
    }
  };

  const handlePopupClose = () => {
    setSelectedPoint(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-md">
        {error}
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="flex items-center space-x-4 mb-4 text-sm">
        <div className="flex items-center">
          <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
          <span>Landmarks</span>
        </div>
        <div className="flex items-center">
          <div className="w-2 h-2 bg-red-500 rounded-full mr-2"></div>
          <span>Cuisines</span>
        </div>
        <div className="flex items-center">
          <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
          <span>Traditions</span>
        </div>
        <div className="flex items-center">
          <div className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></div>
          <span>Festivals</span>
        </div>
      </div>

      <div className="h-[600px] rounded-lg overflow-hidden">
        <Map
          {...viewState}
          onMove={evt => setViewState(evt.viewState)}
          mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
          mapboxAccessToken={MAPBOX_TOKEN}
          attributionControl={true}
          reuseMaps
          maxZoom={20}
          minZoom={1}
          style={{ width: '100%', height: '100%' }}
          projection="globe"
        >
          <NavigationControl />
          
          {points.map((point) => (
            <Marker
              key={point.id}
              longitude={point.longitude!}
              latitude={point.latitude!}
              anchor="bottom"
            >
              <button
                type="button"
                onClick={() => handleMarkerClick(point)}
                className={`p-2 rounded-full ${typeColors[point.type]} text-white cursor-pointer transform transition-transform hover:scale-110 shadow-lg`}
                title={point.title}
              >
                <TypeIcon type={point.type} />
              </button>
            </Marker>
          ))}

          {selectedPoint && (
            <Popup
              longitude={selectedPoint.longitude!}
              latitude={selectedPoint.latitude!}
              anchor="bottom"
              onClose={handlePopupClose}
              closeOnClick={false}
              className="max-w-sm"
            >
              <div className="p-4">
                <div className="flex items-center space-x-2 mb-2">
                  <TypeIcon type={selectedPoint.type} />
                  <h3 className="font-semibold text-lg">{selectedPoint.title}</h3>
                </div>
                
                <p className="text-gray-600 mb-2">{selectedPoint.description}</p>
                
                {selectedPoint.media_urls?.[0] && (
                  <img
                    src={selectedPoint.media_urls[0]}
                    alt={selectedPoint.title}
                    className="w-full h-32 object-cover rounded-md mb-2"
                  />
                )}
                
                <div className="flex items-center text-sm text-gray-500">
                  <MapPin className="w-4 h-4 mr-1" />
                  {selectedPoint.location}
                </div>
              </div>
            </Popup>
          )}
        </Map>
      </div>
    </div>
  );
}