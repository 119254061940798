import { supabase } from './supabase';

export interface UserPost {
  id: string;
  content: string;
  media_urls?: string[];
  type: 'text' | 'image' | 'video' | 'recipe' | 'cultural_point';
  reference_id?: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
  likes_count?: number;
  comments_count?: number;
  has_liked?: boolean;
  share_url?: string;
}

export interface Comment {
  id: string;
  post_id: string;
  user_id: string;
  content: string;
  created_at: string;
  profiles?: {
    username: string;
    avatar_url: string;
  };
}

export async function getUserPosts(userId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();

    const { data: posts, error } = await supabase
      .from('user_posts')
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        )
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;

    // Get counts and likes in parallel using Promise.all
    const [likeCounts, commentCounts, userLikes] = await Promise.all([
      supabase.rpc('get_post_likes_count', {
        post_ids: (posts || []).map(p => p.id)
      }),
      supabase.rpc('get_post_comments_count', {
        post_ids: (posts || []).map(p => p.id)
      }),
      user ? supabase
        .from('post_likes')
        .select('post_id')
        .eq('user_id', user.id)
        .in('post_id', (posts || []).map(p => p.id)) : Promise.resolve({ data: null })
    ]);

    // Create efficient lookup maps
    const likesMap = new Map(likeCounts.data?.map(item => [item.post_id, parseInt(item.count)]));
    const commentsMap = new Map(commentCounts.data?.map(item => [item.post_id, parseInt(item.count)]));
    const likedPostIds = new Set(userLikes.data?.map(like => like.post_id));

    // Process posts with counts and likes
    return (posts || []).map(post => ({
      ...post,
      likes_count: likesMap.get(post.id) || 0,
      comments_count: commentsMap.get(post.id) || 0,
      has_liked: likedPostIds.has(post.id),
      share_url: `${window.location.origin}/post/${post.id}`
    }));
  } catch (error) {
    console.error('Error fetching user posts:', error);
    return [];
  }
}

export async function getPost(postId: string): Promise<UserPost> {
  try {
    const { data: { user } } = await supabase.auth.getUser();

    // Get post data and counts in parallel
    const [postResult, likesCount, commentsCount, hasLiked] = await Promise.all([
      supabase
        .from('user_posts')
        .select(`
          *,
          profiles:user_id (
            username,
            avatar_url
          )
        `)
        .eq('id', postId)
        .single(),
      supabase
        .from('post_likes')
        .select('*', { count: 'exact', head: true })
        .eq('post_id', postId),
      supabase
        .from('post_comments')
        .select('*', { count: 'exact', head: true })
        .eq('post_id', postId),
      user ? supabase
        .from('post_likes')
        .select()
        .match({ post_id: postId, user_id: user.id })
        .maybeSingle()
        .then(result => !!result.data)
        .catch(() => false) : Promise.resolve(false)
    ]);

    if (postResult.error) throw postResult.error;
    if (!postResult.data) throw new Error('Post not found');

    return {
      ...postResult.data,
      likes_count: likesCount.count || 0,
      comments_count: commentsCount.count || 0,
      has_liked: hasLiked,
      share_url: `${window.location.origin}/post/${postId}`
    };
  } catch (error) {
    console.error('Error fetching post:', error);
    throw error;
  }
}

export async function getFeedPosts() {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    // Get posts with basic info
    const { data: posts, error: postsError } = await supabase
      .from('user_posts')
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        )
      `)
      .order('created_at', { ascending: false })
      .limit(50);

    if (postsError) throw postsError;
    if (!posts?.length) return [];

    // Get counts and likes in parallel
    const [likesResult, commentsResult, userLikesResult] = await Promise.all([
      supabase.rpc('get_post_likes_count', {
        post_ids: posts.map(p => p.id)
      }),
      supabase.rpc('get_post_comments_count', {
        post_ids: posts.map(p => p.id)
      }),
      supabase
        .from('post_likes')
        .select('post_id')
        .eq('user_id', user.id)
        .in('post_id', posts.map(p => p.id))
    ]);

    if (likesResult.error) throw likesResult.error;
    if (commentsResult.error) throw commentsResult.error;
    if (userLikesResult.error) throw userLikesResult.error;

    // Create efficient lookup maps
    const likesMap = new Map(likesResult.data?.map(item => [item.post_id, item.count]) || []);
    const commentsMap = new Map(commentsResult.data?.map(item => [item.post_id, item.count]) || []);
    const likedPostIds = new Set(userLikesResult.data?.map(like => like.post_id) || []);

    // Process posts with counts and likes
    return posts.map(post => ({
      ...post,
      likes_count: likesMap.get(post.id) || 0,
      comments_count: commentsMap.get(post.id) || 0,
      has_liked: likedPostIds.has(post.id),
      share_url: `${window.location.origin}/post/${post.id}`
    }));
  } catch (error: any) {
    console.error('Error fetching feed posts:', error);
    throw new Error(error.message || 'Failed to load posts');
  }
}

export async function createPost(post: {
  content: string;
  media_urls?: string[];
  type: UserPost['type'];
  reference_id?: string;
}) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('user_posts')
      .insert([{
        ...post,
        user_id: user.id,
        media_urls: post.media_urls || []
      }])
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        )
      `)
      .single();

    if (error) throw error;

    return {
      ...data,
      likes_count: 0,
      comments_count: 0,
      has_liked: false,
      share_url: `${window.location.origin}/post/${data.id}`
    };
  } catch (error) {
    console.error('Error creating post:', error);
    throw error;
  }
}

export async function likePost(postId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('post_likes')
      .insert([{
        post_id: postId,
        user_id: user.id
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error liking post:', error);
    throw error;
  }
}

export async function unlikePost(postId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { error } = await supabase
      .from('post_likes')
      .delete()
      .match({
        post_id: postId,
        user_id: user.id
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error unliking post:', error);
    throw error;
  }
}

export async function getComments(postId: string) {
  try {
    const { data, error } = await supabase
      .from('post_comments')
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        )
      `)
      .eq('post_id', postId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error getting comments:', error);
    throw error;
  }
}

export async function createComment(postId: string, content: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('post_comments')
      .insert([{
        post_id: postId,
        user_id: user.id,
        content
      }])
      .select(`
        *,
        profiles:user_id (
          username,
          avatar_url
        )
      `)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating comment:', error);
    throw error;
  }
}

export async function followUser(userId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');
    if (user.id === userId) throw new Error('Cannot follow yourself');

    const { data, error } = await supabase
      .from('user_follows')
      .insert([{
        follower_id: user.id,
        following_id: userId
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error following user:', error);
    throw error;
  }
}

export async function unfollowUser(userId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { error } = await supabase
      .from('user_follows')
      .delete()
      .match({
        follower_id: user.id,
        following_id: userId
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error unfollowing user:', error);
    throw error;
  }
}

export async function getFollowers(userId: string) {
  try {
    const { data, error } = await supabase
      .from('user_follows')
      .select(`
        follower:profiles!user_follows_follower_id_fkey (
          id,
          username,
          full_name,
          avatar_url
        )
      `)
      .eq('following_id', userId);

    if (error) throw error;
    return data.map(item => item.follower);
  } catch (error) {
    console.error('Error getting followers:', error);
    return [];
  }
}

export async function getFollowing(userId: string) {
  try {
    const { data, error } = await supabase
      .from('user_follows')
      .select(`
        following:profiles!user_follows_following_id_fkey (
          id,
          username,
          full_name,
          avatar_url
        )
      `)
      .eq('follower_id', userId);

    if (error) throw error;
    return data.map(item => item.following);
  } catch (error) {
    console.error('Error getting following:', error);
    return [];
  }
}

export async function isFollowing(userId: string) {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return false;

    const { data, error } = await supabase
      .from('user_follows')
      .select()
      .match({
        follower_id: user.id,
        following_id: userId
      })
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return !!data;
  } catch (error) {
    console.error('Error checking following status:', error);
    return false;
  }
}