import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings as SettingsIcon, Moon, Sun, Bell, Lock, Globe, AlertCircle, User } from 'lucide-react';
import { useAuth } from '../lib/auth';
import { supabase } from '../lib/supabase';
import { useTheme } from '../lib/theme';
import { useTranslation } from 'react-i18next';
import { ProfilePictureUpload } from '../components/ProfilePictureUpload';

interface UserSettings {
  id: string;
  user_id: string;
  theme: 'light' | 'dark';
  language: string;
  email_notifications: {
    marketing: boolean;
    security: boolean;
    social: boolean;
  };
  privacy_settings: {
    profile_visibility: 'public' | 'private';
    show_email: boolean;
    show_location: boolean;
  };
  created_at: string;
  updated_at: string;
}

export function Settings() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [settings, setSettings] = useState<UserSettings | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      navigate('/signin');
      return;
    }
    loadSettings();
  }, [user, navigate]);

  async function loadSettings() {
    try {
      const { data, error } = await supabase
        .from('profile_settings')
        .select('*')
        .eq('user_id', user?.id)
        .single();

      if (error && error.code !== 'PGRST116') throw error;

      if (!data) {
        // Create default settings if none exist
        const defaultSettings = {
          user_id: user?.id,
          theme: theme,
          language: 'en',
          email_notifications: {
            marketing: false,
            security: true,
            social: true
          },
          privacy_settings: {
            profile_visibility: 'public',
            show_email: false,
            show_location: true
          }
        };

        const { data: newSettings, error: createError } = await supabase
          .from('profile_settings')
          .insert([defaultSettings])
          .select()
          .single();

        if (createError) throw createError;
        setSettings(newSettings);
      } else {
        setSettings(data);
      }

      // Load avatar URL
      const { data: profile } = await supabase
        .from('profiles')
        .select('avatar_url')
        .eq('id', user?.id)
        .single();

      setAvatarUrl(profile?.avatar_url || null);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleSave = async () => {
    if (!settings || !user) return;

    try {
      setSaveLoading(true);
      setError(null);

      const { error: settingsError } = await supabase
        .from('profile_settings')
        .upsert({
          ...settings,
          user_id: user.id
        });

      if (settingsError) throw settingsError;

      // Show success message
      setSaved(true);
      setTimeout(() => setSaved(false), 3000);

      // Apply theme if changed
      if (settings.theme !== theme) {
        toggleTheme();
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleAvatarUpload = async (url: string) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ avatar_url: url })
        .eq('id', user?.id);

      if (error) throw error;
      setAvatarUrl(url);
    } catch (err: any) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <div className="flex items-center mb-6">
          <SettingsIcon className="w-8 h-8 text-blue-600 dark:text-blue-400 mr-2" />
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t('profile.settings.title')}
          </h1>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 text-red-600 dark:text-red-400 px-4 py-3 rounded-md flex items-center">
            <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
            {error}
          </div>
        )}

        {saved && (
          <div className="mb-6 bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800 text-green-600 dark:text-green-400 px-4 py-3 rounded-md">
            Settings saved successfully!
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Profile Information */}
          <section className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
              <User className="w-5 h-5 mr-2" />
              Profile Information
            </h3>

            <div className="space-y-4">
              <ProfilePictureUpload
                currentUrl={avatarUrl || undefined}
                onUpload={handleAvatarUpload}
              />

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={user?.email || ''}
                  disabled
                  className="w-full px-4 py-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-gray-500 dark:text-gray-400"
                />
              </div>
            </div>
          </section>

          {/* Appearance */}
          <section className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
              <Sun className="w-5 h-5 mr-2" />
              {t('profile.settings.appearance')}
            </h3>

            <div className="flex space-x-4">
              <button
                onClick={() => setSettings(prev => prev ? { ...prev, theme: 'light' } : null)}
                className={`px-4 py-2 rounded-md flex items-center space-x-2 ${
                  settings?.theme === 'light'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                <Sun className="w-5 h-5" />
                <span>{t('profile.settings.lightTheme')}</span>
              </button>
              <button
                onClick={() => setSettings(prev => prev ? { ...prev, theme: 'dark' } : null)}
                className={`px-4 py-2 rounded-md flex items-center space-x-2 ${
                  settings?.theme === 'dark'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                <Moon className="w-5 h-5" />
                <span>{t('profile.settings.darkTheme')}</span>
              </button>
            </div>
          </section>

          {/* Language */}
          <section className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
              <Globe className="w-5 h-5 mr-2" />
              {t('profile.settings.language')}
            </h3>

            <select
              value={settings?.language || 'en'}
              onChange={(e) => setSettings(prev => prev ? { ...prev, language: e.target.value } : null)}
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fr">Français</option>
              <option value="ja">日本語</option>
              <option value="zh">中文</option>
              <option value="ar">العربية</option>
              <option value="ko">한국어</option>
              <option value="ru">Русский</option>
              <option value="pt">Português</option>
              <option value="hi">हिन्दी</option>
            </select>
          </section>

          {/* Notifications */}
          <section className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
              <Bell className="w-5 h-5 mr-2" />
              {t('profile.settings.notifications')}
            </h3>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings?.email_notifications.marketing}
                    onChange={(e) => setSettings(prev => prev ? {
                      ...prev,
                      email_notifications: {
                        ...prev.email_notifications,
                        marketing: e.target.checked
                      }
                    } : null)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
                  />
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {t('profile.settings.marketingEmails')}
                  </span>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings?.email_notifications.security}
                    onChange={(e) => setSettings(prev => prev ? {
                      ...prev,
                      email_notifications: {
                        ...prev.email_notifications,
                        security: e.target.checked
                      }
                    } : null)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
                  />
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {t('profile.settings.securityAlerts')}
                  </span>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings?.email_notifications.social}
                    onChange={(e) => setSettings(prev => prev ? {
                      ...prev,
                      email_notifications: {
                        ...prev.email_notifications,
                        social: e.target.checked
                      }
                    } : null)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
                  />
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {t('profile.settings.socialNotifications')}
                  </span>
                </label>
              </div>
            </div>
          </section>

          {/* Privacy */}
          <section className="space-y-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
              <Lock className="w-5 h-5 mr-2" />
              {t('profile.settings.privacy')}
            </h3>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('profile.settings.profileVisibility')}
                </label>
                <select
                  value={settings?.privacy_settings.profile_visibility}
                  onChange={(e) => setSettings(prev => prev ? {
                    ...prev,
                    privacy_settings: {
                      ...prev.privacy_settings,
                      profile_visibility: e.target.value as 'public' | 'private'
                    }
                  } : null)}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                >
                  <option value="public">{t('profile.settings.public')}</option>
                  <option value="private">{t('profile.settings.private')}</option>
                </select>
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings?.privacy_settings.show_email}
                    onChange={(e) => setSettings(prev => prev ? {
                      ...prev,
                      privacy_settings: {
                        ...prev.privacy_settings,
                        show_email: e.target.checked
                      }
                    } : null)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
                  />
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {t('profile.settings.showEmail')}
                  </span>
                </label>
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings?.privacy_settings.show_location}
                    onChange={(e) => setSettings(prev => prev ? {
                      ...prev,
                      privacy_settings: {
                        ...prev.privacy_settings,
                        show_location: e.target.checked
                      }
                    } : null)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
                  />
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {t('profile.settings.showLocation')}
                  </span>
                </label>
              </div>
            </div>
          </section>
        </div>

        <div className="flex justify-end pt-6">
          <button
            onClick={handleSave}
            disabled={saveLoading}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
          >
            {saveLoading ? (
              <>
                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                {t('common.saving')}
              </>
            ) : (
              t('common.save')
            )}
          </button>
        </div>
      </div>
    </div>
  );
}